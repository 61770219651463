import { LoadingButton } from "@mui/lab"
import {
  type IconButtonProps,
  IconButton as MuiIconButton,
  styled,
} from "@mui/material"
import { type MouseEventHandler, useState } from "react"
import { MoreVertical } from "react-feather"
import { Menu, type MenuProps } from "../Menu"

export interface ActionButtonProps {
  items: MenuProps["items"]
  loading?: boolean
  IconProps?: Omit<IconButtonProps, "color">
}

export const ActionButton = ({ items, loading, IconProps }: ActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }
  const handleClose: MouseEventHandler = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  const menuProps = {
    anchorEl: anchorEl,
    open: !!anchorEl,
    onClose: handleClose,
    onClick: handleClose,
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
  }

  return (
    <>
      <IconButton
        {...IconProps}
        component={LoadingButton}
        loading={loading}
        onMouseDown={stopPropagation}
        onClick={handleClick}
        data-testid="actions-button"
      >
        <MoreVertical style={{ opacity: loading ? 0 : 1 }} />
      </IconButton>

      <Menu {...menuProps} items={items} />
    </>
  )
}

const stopPropagation: MouseEventHandler = (e) => e.stopPropagation()

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.grey.light,
  },
})) as typeof MuiIconButton

import { Stack, Typography, styled } from "@mui/material"
import {
  LatestNewsItem,
  type LatestNewsItemModel,
} from "../LatestNews/LatestNewsItem"
import type { ApiDisplayNewsReferential, PostRubric } from "../model"

export function getRubricLabel(rubric: PostRubric) {
  switch (rubric) {
    case "UPP_GROUP":
      return "Pharm-UPP vous informe"
    case "LAB_EXTERN":
      return "Labos & Prestataires"
    case "HEALTH_PHARMA":
      return "Santé & Pharma"
  }
}

export interface SearchNewsItemProps {
  item: LatestNewsItemModel
  referential: ApiDisplayNewsReferential
}

export const NewsCardResult = ({ item, referential }: SearchNewsItemProps) => {
  const category = getRubricLabel(item.rubric)
  return (
    <Stack gap={1}>
      <HeaderBox>{category}</HeaderBox>
      <LatestNewsItem item={item} referential={referential} variant="search" />
    </Stack>
  )
}

const HeaderBox = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(14),
  fontWeight: 600,
  color: palette.grey[400],
}))

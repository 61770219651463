import type { SVGProps } from "react"

export const BlisterMedicineIcon = ({
  width = "32px",
  height = "32px",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Tableau-de-bord"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mon-Officine-Dashboard-Plus-de-categories---Desktop-Copy-2"
        transform="translate(-130.000000, -1527.000000)"
        fill="#C3CBD2"
        fillRule="nonzero"
      >
        <g id="Group-9" transform="translate(111.000000, 308.000000)">
          <g id="Group-8" transform="translate(0.000000, 430.000000)">
            <g id="Group-5-Copy-5" transform="translate(0.000000, 742.000000)">
              <g id="noun-pills-2557089" transform="translate(19.000000, 47.000000)">
                <path
                  d="M24.9947912,12.3763461 C25.2269959,12.376267 25.4568619,12.3299673 25.6709881,12.2401462 L28.211504,11.1779822 C29.1012953,10.8006863 29.5177337,9.77437887 29.1423939,8.88381479 C28.7670542,7.99325071 27.7416014,7.57455679 26.8501369,7.94788282 L24.309636,9.01004681 C23.8810738,9.18899221 23.5414327,9.53124149 23.3657896,9.96114137 C23.1901464,10.3910412 23.192966,10.8731881 23.373625,11.3010046 L23.373625,11.3010196 C23.6486253,11.9527994 24.2873274,12.3764533 24.9947912,12.3763461 Z M24.2916622,10.339133 C24.3664273,10.1550716 24.5118604,10.0086595 24.6954243,9.93265343 L27.2359226,8.87047445 C27.5170013,8.75211284 27.8416896,8.81441313 28.0589883,9.02840199 C28.276287,9.24239085 28.3435438,9.56606569 28.2294803,9.84889851 C28.1547174,10.032966 28.0092844,10.179385 27.8257183,10.2553981 L25.2852024,11.3175546 C25.193278,11.3562878 25.0945438,11.3762761 24.9947912,11.3763485 C24.6894329,11.3769479 24.4135854,11.194118 24.2952,10.9126625 C24.2173139,10.7296622 24.2160396,10.5230801 24.2916622,10.339133 Z"
                  id="Shape"
                />
                <path
                  d="M19.8487396,10.7893726 L20.4880839,12.8782531 C20.6583961,12.4521772 20.6536584,11.976107 20.4749004,11.5535044 L20.4749004,11.5534894 C20.3441143,11.2438102 20.1266861,10.9784781 19.8487396,10.7893726 L19.8487396,10.7893726 Z"
                  id="Path"
                />
                <path
                  d="M22.820132,17.699081 C23.0523303,17.6989998 23.2821899,17.6527054 23.496314,17.5628962 L26.0368299,16.5007172 C26.4653956,16.3217729 26.8050394,15.9795221 26.9806829,15.5496194 C27.1563264,15.1197168 27.1535045,14.6375669 26.9728409,14.2097494 L26.9728259,14.2097494 C26.5970832,13.3167665 25.5692197,12.8965895 24.6754628,13.2706178 L22.1349469,14.3327817 C21.7216087,14.505242 21.3907207,14.8304711 21.2111743,15.2407559 L21.8796807,17.4248963 C22.1610013,17.6032147 22.4870513,17.6982732 22.820132,17.699081 L22.820132,17.699081 Z M22.1169856,15.661883 C22.1917472,15.4778198 22.3371737,15.3314019 22.5207326,15.2553834 L25.0612635,14.1932194 C25.1532236,14.1544749 25.251995,14.1344816 25.3517848,14.1344107 C25.6570981,14.1338619 25.9328842,14.3166831 26.0512535,14.5980965 C26.128721,14.7810666 26.130041,14.9873689 26.0549212,15.1713152 C25.9798013,15.3552615 25.8344408,15.5016714 25.6510317,15.5781206 L23.1105283,16.6402895 C23.0186086,16.6790213 22.9198796,16.6990095 22.820132,16.6990835 C22.5147717,16.6996921 22.2389175,16.5168675 22.1205259,16.2354124 C22.0426307,16.0524133 22.0413555,15.8458272 22.1169856,15.6618805 L22.1169856,15.661883 Z"
                  id="Shape"
                />
                <path
                  d="M23.4763526,20.900858 L23.004013,21.0983378 L23.298132,22.0592744 L23.8621533,21.8234646 C24.7528871,21.4467496 25.1701412,20.4198082 24.7945528,19.5286531 C24.4189643,18.637498 23.3924882,18.2189445 22.5007812,18.5933502 L22.2671966,18.6910076 L22.5613156,19.6519442 L22.8865644,19.5159568 C22.9785258,19.4772183 23.077297,19.4572301 23.1770857,19.4571644 C23.4824049,19.4565923 23.7582041,19.6394124 23.8765693,19.9208339 C23.9540369,20.103804 23.9553569,20.3101064 23.880237,20.4940526 C23.8051171,20.6779989 23.6597567,20.8244088 23.4763476,20.900858 L23.4763526,20.900858 Z"
                  id="Path"
                />
                <path
                  d="M22.6495721,6.23075946 C22.2738293,5.33777657 21.2459659,4.9175996 20.352209,5.29162786 L18.4140471,6.10195958 L18.7081662,7.06288117 L20.7379772,6.21423448 C20.8299392,6.17549792 20.9287099,6.15550979 21.0284984,6.15544203 C21.3338177,6.15486994 21.6096169,6.33769007 21.7279821,6.61911159 C21.8054497,6.80208168 21.8067697,7.00838403 21.7316498,7.19233029 C21.6565299,7.37627655 21.5111694,7.52268647 21.3277603,7.59913566 L19.1508511,8.50928979 L19.4449551,9.47021137 L21.7135386,8.52174228 C22.142108,8.3427976 22.4817558,8.00054559 22.6574033,7.57064066 C22.8330509,7.14073572 22.8302328,6.65858217 22.6495721,6.23075946 L22.6495721,6.23075946 Z"
                  id="Path"
                />
                <path
                  d="M22.0381698,30.379304 C21.9369807,30.6251801 21.74221,30.8207608 21.4967452,30.9229801 C21.2512805,31.0251995 20.9752499,31.0256755 20.7294341,30.9243034 L19.0995998,30.2580541 L17.5731706,30.7144186 L20.3509914,31.8499451 C20.8423572,32.0516828 21.3937561,32.0499103 21.8838146,31.8450176 C22.373873,31.640125 22.7624214,31.2489071 22.9639326,30.7574786 L24.2456113,27.6204416 C24.2149541,27.6897915 24.1840068,27.7590839 24.1478267,27.8266489 C23.7962372,28.483871 23.2107253,28.9851752 22.5071591,29.2313575 L22.0381698,30.379304 Z"
                  id="Path"
                />
                <path
                  d="M31.8517679,9.00368432 C32.2686417,7.97909549 31.7761565,6.8105615 30.7516349,6.39336681 L19.1481559,1.65005382 C18.4760057,1.37309658 17.7062976,1.48479069 17.140609,1.94137355 L17.4861342,3.07027747 C17.7141932,2.5901221 18.2784095,2.37271713 18.7697282,2.57568044 L30.3731897,7.31900842 C30.8860856,7.52771802 31.1333409,8.11209623 30.9259802,8.62550718 L24.2143691,25.0528315 L24.3695479,25.5598035 C24.5630201,26.1884269 24.5399195,26.8637878 24.3039456,27.4777242 L31.8517679,9.00368432 Z"
                  id="Path"
                />
                <path
                  d="M7.56591837,30.5903188 C7.82721644,31.4306597 8.60599448,32.0023916 9.48607887,31.9999925 C9.68313914,31.9999925 9.87913479,31.9710935 10.0678939,31.914505 L22.0735224,28.3252134 C22.5805147,28.1770635 23.0072808,27.8324761 23.2588984,27.3680954 C23.510516,26.9037147 23.56612,26.3580487 23.4133429,25.8524658 L15.9320705,1.40968155 C15.6707933,0.569325718 14.8920024,-0.00241567131 14.01191,7.6739334e-06 C13.8148492,7.6739334e-06 13.6188525,0.0288993577 13.430095,0.0854953153 L1.424464,3.6747619 C0.917466135,3.82291189 0.490694898,4.167502 0.239074336,4.63188722 C-0.0125462259,5.09627243 -0.0681507745,5.64194415 0.084628461,6.14753204 L7.56591837,30.5903188 Z M1.11338874,5.11753302 C1.23947855,4.88236215 1.45479156,4.70772291 1.71093246,4.63286848 L13.7165609,1.0435769 C13.8124071,1.01479629 13.911933,1.00001308 14.0120075,1.00001308 C14.4519338,0.999294486 14.8417391,1.28335254 14.9757781,1.70233127 L22.4570755,26.145118 C22.5328363,26.3906667 22.5067291,26.6563584 22.3846227,26.8824673 C22.2585183,27.1176341 22.0432013,27.2922707 21.787059,27.3671318 L9.78143049,30.9564084 C9.68561751,30.9851936 9.58612298,30.9999873 9.48607887,30.9999873 C9.0461207,31.000716 8.65627731,30.7166551 8.52220335,30.297654 L1.04092093,5.85486732 C0.96516492,5.60932163 0.991277583,5.34363451 1.11338874,5.11753302 Z"
                  id="Shape"
                />
                <path
                  d="M5.55367114,10.4779059 C5.82463104,10.4777652 6.09412715,10.4381704 6.35367217,10.360358 C7.05079031,10.1566537 7.63759781,9.68284577 7.98357482,9.04432031 C8.32955184,8.40579486 8.40600866,7.6555008 8.19593947,6.96032127 C7.83668111,5.80483216 6.76584374,5.01868773 5.55571629,5.02201751 C5.2847563,5.02215724 5.01526004,5.06175205 4.75571525,5.1395655 C4.05859654,5.3432698 3.47178858,5.81707811 3.12581151,6.45560415 C2.77983443,7.09413019 2.703378,7.84442489 2.91344795,8.53960476 C3.27272458,9.69508049 4.34355098,10.4812159 5.55367114,10.4779059 Z M7.239647,7.25296849 C7.37333306,7.68607504 7.32734225,8.15473306 7.11201266,8.55359475 C6.89170544,8.9651885 6.51522173,9.27098791 6.06718621,9.40225644 C5.90059494,9.45225747 5.72761032,9.47773723 5.55367614,9.47789387 C5.3128706,9.47611559 5.07499516,9.42491237 4.8547875,9.32745651 L7.02494629,6.81047641 C7.11775989,6.94668659 7.19009631,7.09576991 7.239647,7.25296849 L7.239647,7.25296849 Z M3.99738976,6.94632878 C4.21768968,6.53472586 4.59417549,6.22891934 5.04221621,6.09765209 C5.20885095,6.04765963 5.38187544,6.02218513 5.5558488,6.02202966 C5.79661246,6.02379869 6.03444759,6.07499115 6.25461493,6.17243452 L4.08443363,8.68942962 C3.73011542,8.1709258 3.69649034,7.49754603 3.99739226,6.94632878 L3.99738976,6.94632878 Z"
                  id="Shape"
                />
                <path
                  d="M11.7966054,8.6114826 C12.0675654,8.61134307 12.3370617,8.57174826 12.5966065,8.4939348 C13.2937246,8.29023048 13.8805321,7.81642255 14.2265091,7.17789709 C14.5724861,6.53937163 14.648943,5.78907758 14.4388738,5.09389804 C14.0796187,3.93840696 13.0087789,3.15226181 11.7986506,3.15559675 C11.5276901,3.15572994 11.2581929,3.19532488 10.9986496,3.27314478 C10.3015314,3.47684911 9.71472392,3.95065703 9.3687469,4.58918249 C9.02276989,5.22770795 8.94631307,5.978002 9.15638225,6.67318154 C9.51566791,7.82865083 10.5864887,8.61478209 11.7966054,8.6114826 L11.7966054,8.6114826 Z M13.4825813,5.38654526 C13.616268,5.81964676 13.5702771,6.28830042 13.354947,6.68715653 C13.1346453,7.09875798 12.7581602,7.40456393 12.3101205,7.53583322 C12.1435292,7.58583425 11.9705446,7.61131401 11.7966104,7.61147064 C11.5558159,7.60969669 11.3179506,7.5584994 11.0977518,7.46105079 L13.2678556,4.94401069 C13.3606776,5.08023488 13.4330223,5.22933222 13.4825813,5.38654526 L13.4825813,5.38654526 Z M10.2403091,5.07990556 C10.4606154,4.66830794 10.8370987,4.36250349 11.2851355,4.23122887 C11.4517786,4.18122786 11.6248143,4.15575321 11.7987981,4.15560644 C12.0395338,4.15738166 12.2773412,4.20856344 12.4974892,4.30598129 L10.3273904,6.8230214 C9.97304426,6.30452546 9.93940053,5.63113385 10.2403016,5.07990556 L10.2403091,5.07990556 Z"
                  id="Shape"
                />
                <path
                  d="M8.24214535,16.5303796 C8.93926582,16.3266788 9.52607594,15.8528715 9.87205355,15.2143449 C10.2180312,14.5758184 10.2944861,13.8255225 10.0844127,13.1303429 C9.72515995,11.9748458 8.65431284,11.1886982 7.44417947,11.1920415 C7.17322448,11.1921824 6.90373332,11.2317772 6.64419343,11.3095896 C5.9470776,11.5132966 5.36027308,11.9871056 5.01429858,12.6256308 C4.66832408,13.2641559 4.59186898,14.0144485 4.80193863,14.7096264 C5.16120946,15.8650998 6.23203061,16.6512357 7.44214682,16.6479275 C7.71310604,16.6477888 7.98260161,16.6081939 8.24214535,16.5303796 L8.24214535,16.5303796 Z M5.7582311,14.4169792 C5.62453761,13.9838734 5.67052871,13.5152124 5.88586544,13.1163529 C6.10616912,12.7047531 6.48265344,12.3989478 6.93069189,12.2676762 C7.09733175,12.2176837 7.27036119,12.1922092 7.44433948,12.1920538 C7.68509731,12.1938319 7.92292572,12.245024 8.14309061,12.3424586 L5.97295182,14.8595012 C5.88012674,14.723284 5.80778333,14.5741901 5.7582311,14.4169792 L5.7582311,14.4169792 Z M8.91342947,12.9804805 C9.26775915,13.4989901 9.301391,14.1723817 9.00048834,14.7236114 C8.78018489,15.1352082 8.40369968,15.4410088 7.95566189,15.5722731 C7.78907086,15.6222752 7.61608609,15.647755 7.44215182,15.6479105 C7.20137704,15.64614 6.96353076,15.5949477 6.74335069,15.4975056 L8.91342947,12.9804805 Z"
                  id="Shape"
                />
                <path
                  d="M12.8871402,9.4431664 C12.1900186,9.64686544 11.6032072,10.1206725 11.2572293,10.7591996 C10.9112514,11.3977267 10.8347974,12.1480236 11.0448729,12.8432032 C11.4041483,13.9986809 12.4749745,14.7848191 13.6850961,14.7815118 C13.9560511,14.7813711 14.2255423,14.7417763 14.4850822,14.6639639 C15.1822003,14.4602596 15.7690078,13.9864517 16.1149848,13.3479262 C16.4609618,12.7094008 16.5374186,11.9591067 16.3273495,11.2639272 C15.9680981,10.1084366 14.897263,9.32228884 13.6871363,9.32561841 C13.4161774,9.32575069 13.1466817,9.36534566 12.8871402,9.4431664 L12.8871402,9.4431664 Z M15.371057,11.5565669 C15.5047514,11.9896676 15.4587602,12.4583243 15.2434226,12.8571781 C15.0231236,13.2687818 14.6466374,13.5745886 14.1985962,13.7058548 C14.0320049,13.7558559 13.8590203,13.7813356 13.6850861,13.7814923 C13.4442818,13.7797199 13.2064063,13.7285226 12.9861975,13.6310724 L15.1563188,11.1140173 C15.2491469,11.2502457 15.3214952,11.3993492 15.3710545,11.5565694 L15.371057,11.5565669 Z M14.3859524,10.4760054 L12.2158561,12.9930455 C11.8615529,12.4745326 11.8279217,11.8011603 12.1287972,11.2499297 C12.3490998,10.838329 12.7255846,10.5325233 13.1736237,10.401253 C13.3402614,10.3512509 13.5132923,10.3257762 13.6872713,10.3256306 C13.928004,10.3274035 14.1658086,10.3785854 14.3859524,10.4760054 L14.3859524,10.4760054 Z"
                  id="Shape"
                />
                <path
                  d="M10.130626,22.7004038 C10.8277442,22.4966994 11.4145517,22.0228915 11.7605287,21.3843661 C12.1065057,20.7458406 12.1829625,19.9955465 11.9728933,19.300367 C11.6136361,18.1448814 10.5428039,17.3587379 9.33268015,17.3620633 C9.06172017,17.362203 8.7922239,17.4017978 8.53267911,17.4796112 C7.83556098,17.6833156 7.24875348,18.1571235 6.90277646,18.795649 C6.55679945,19.4341744 6.48034263,20.1844685 6.69041181,20.879648 C7.04968844,22.0351237 8.12051485,22.8212591 9.330635,22.8179491 C9.60159143,22.8178083 9.8710841,22.7782144 10.130626,22.7004038 Z M7.64669429,20.5869958 C7.51300822,20.1538892 7.55899903,19.6852312 7.77432862,19.2863695 C7.99463496,18.8747719 8.37111823,18.5689675 8.81915508,18.4376928 C8.98578982,18.3877004 9.1588143,18.3622259 9.33278766,18.3620704 C9.57353965,18.3638433 9.8113632,18.4150304 10.0315238,18.5124603 L7.8614275,21.0295204 C7.76859664,20.8933038 7.6962489,20.7442088 7.64669429,20.5869958 L7.64669429,20.5869958 Z M10.8018777,19.1504972 C11.1562222,19.6690057 11.1898658,20.3424054 10.8889665,20.8936455 C10.6686593,21.3052392 10.2921756,21.6110387 9.84414007,21.7423072 C9.6775488,21.7923082 9.50456419,21.817788 9.33063,21.8179446 C9.08984116,21.8161774 8.85198035,21.7649851 8.63178637,21.6675398 L10.8018777,19.1504972 Z"
                  id="Shape"
                />
                <path
                  d="M14.7756134,15.6131905 C14.0784953,15.8168949 13.4916878,16.2907028 13.1457108,16.9292282 C12.7997338,17.5677537 12.7232769,18.3180477 12.9333461,19.0132273 C13.2926318,20.1686966 14.3634526,20.9548278 15.5735693,20.9515283 C15.8445293,20.9513888 16.1140256,20.911794 16.3735703,20.8339805 C17.0706885,20.6302762 17.657496,20.1564683 18.003473,19.5179428 C18.34945,18.8794174 18.4259068,18.1291233 18.2158376,17.4339438 C17.8565793,16.2784547 16.7857419,15.4923103 15.5756145,15.49564 C15.3046539,15.495774 15.0351566,15.5353698 14.7756134,15.6131905 L14.7756134,15.6131905 Z M17.2595452,17.726591 C17.3932319,18.1596925 17.347241,18.6283462 17.1319108,19.0272023 C16.9116091,19.4388037 16.535124,19.7446097 16.0870844,19.875879 C15.9204931,19.92588 15.7475085,19.9513598 15.5735743,19.9515164 C15.3327679,19.9497456 15.094891,19.898542 14.8746857,19.801079 L17.0448445,17.2840989 C17.1376581,17.4203091 17.2099945,17.5693924 17.2595452,17.726591 Z M16.2745031,16.646057 L14.1043443,19.1630546 C13.7500061,18.6445603 13.7163682,17.9711748 14.0172704,17.4199538 C14.2375768,17.0083562 14.61406,16.7025517 15.0620969,16.5712771 C15.2287349,16.5212763 15.4017656,16.4958016 15.5757445,16.4956547 C15.8165055,16.4974235 16.054338,16.5486151 16.2745031,16.646057 L16.2745031,16.646057 Z"
                  id="Shape"
                />
                <path
                  d="M11.2211533,23.5320873 C10.9501983,23.5322282 10.6807072,23.571823 10.4211673,23.6496354 C9.72404916,23.8533397 9.13724166,24.3271476 8.79126465,24.9656731 C8.44528763,25.6041985 8.36883081,26.3544926 8.5789,27.0496721 C8.93816596,28.205149 10.0089901,28.9912872 11.2191082,28.9879733 C11.4900681,28.9878326 11.7595642,28.9482378 12.0191092,28.8704254 C12.7162297,28.6667246 13.3030398,28.1929172 13.6490174,27.5543907 C13.994995,26.9158641 14.0714499,26.1655682 13.8613765,25.4703886 C13.5021249,24.3148951 12.431283,23.5287483 11.2211533,23.5320873 Z M9.53519247,26.7570249 C9.40149898,26.3239191 9.44749008,25.8552581 9.66282681,25.4563986 C9.88313049,25.0447988 10.2596148,24.7389935 10.7076533,24.607722 C10.8742931,24.5577294 11.0473226,24.5322549 11.2213008,24.5320995 C11.4620767,24.5338747 11.699923,24.5850721 11.920102,24.6825194 L9.74991568,27.1995295 C9.65709229,27.0633176 9.58474894,26.9142297 9.53519497,26.7570249 L9.53519247,26.7570249 Z M12.7774497,27.0636646 C12.55715,27.4752646 12.1806633,27.7810664 11.7326233,27.9123263 C11.566032,27.9623273 11.3930474,27.9878071 11.2191132,27.9879637 C10.9783235,27.9861898 10.7404631,27.9349924 10.5202695,27.8375439 L12.6904108,25.3205638 C13.0447047,25.8390758 13.0783291,26.5124416 12.7774522,27.0636646 L12.7774497,27.0636646 Z"
                  id="Shape"
                />
                <path
                  d="M16.6641041,21.7832121 C15.9669842,21.9869113 15.3801741,22.4607172 15.0341964,23.0992425 C14.6882187,23.7377679 14.6117636,24.4880628 14.8218368,25.1832414 C15.1811148,26.3387161 16.2519404,27.1248509 17.46206,27.1215425 C17.733015,27.1214019 18.0025061,27.0818071 18.262046,27.0039947 C18.9591642,26.8002903 19.5459717,26.3264824 19.8919487,25.687957 C20.2379257,25.0494315 20.3143825,24.2991374 20.1043133,23.6039579 C19.7450553,22.4484689 18.6742149,21.6623271 17.4640876,21.6656641 C17.1931329,21.6657965 16.9236413,21.7053915 16.6641041,21.7832121 L16.6641041,21.7832121 Z M19.1480208,23.8966126 C19.2817153,24.3297133 19.2357241,24.79837 19.0203865,25.1972239 C18.8000875,25.6088275 18.4236013,25.9146343 17.9755601,26.0459006 C17.8089688,26.0959016 17.6359842,26.1213814 17.46205,26.121538 C17.2212678,26.1197614 16.9834146,26.0685694 16.7632239,25.9711332 L18.9333051,23.4541056 C19.0261263,23.5903194 19.0984672,23.739409 19.1480183,23.8966151 L19.1480208,23.8966126 Z M18.1629788,22.8160637 L15.9928525,25.3331363 C15.6384871,24.8146292 15.6048431,24.1412134 15.9057636,23.5899729 C16.1260662,23.1783722 16.5025509,22.8725665 16.9505901,22.7412962 C17.1172278,22.6912941 17.2902586,22.6658194 17.4642376,22.6656738 C17.7049907,22.6674448 17.9428154,22.718632 18.1629788,22.8160637 L18.1629788,22.8160637 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

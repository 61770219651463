import { Yup, type YupTypes } from "@pharmupp/p3-front-commons"

export const schema = Yup.object({
  adherents: Yup.array()
    .of(
      Yup.object({
        label: Yup.string().required(),
        code: Yup.number().required(),
      }),
    )
    .min(1)
    .required(),
})

export type FormValues = YupTypes.InferType<typeof schema>

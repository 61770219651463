import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  inputBaseClasses,
  outlinedInputClasses,
  styled,
} from "@mui/material"
import { type SyntheticEvent, useState } from "react"
import { Search, X } from "react-feather"

interface SearchBarProps {
  value?: string
  onSubmit: (value: string) => void
  placeholder?: string
}

export const SearchBar = ({ value = "", onSubmit, placeholder }: SearchBarProps) => {
  const [currValue, setCurrValue] = useState<string>(value)

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    onSubmit(currValue)
  }
  const handleReset = () => {
    setCurrValue("")
    onSubmit("")
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" gap={1}>
        <SearchInput
          name="search"
          value={currValue}
          onChange={(e) => setCurrValue(e.target.value)}
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: "tertiary.main" }}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: !!currValue && (
              <IconButton
                onClick={handleReset}
                color="primary"
                size="small"
                sx={{ marginRight: "6px" }}
              >
                <X size="20px" />
              </IconButton>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ px: 3, minWidth: "auto" }}
        >
          Rechercher
        </Button>
      </Box>
    </form>
  )
}

const SearchInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  [`.${inputBaseClasses.root}`]: {
    borderRadius: "100px",
    paddingLeft: theme.spacing(2.5),
  },
  [`& .${inputBaseClasses.input}`]: {
    paddingRight: theme.spacing(2.5),
    fontSize: theme.typography.pxToRem(21),
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      boxShadow: "0 0 0 1000px white inset",
      WebkitTextFillColor: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#dee2e6",
  },
}))

import {
  Badge,
  Box,
  Button,
  type CardActionArea,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
  badgeClasses,
  styled,
} from "@mui/material"
import {
  FullWidthContent,
  FullWidthHeader,
  MainContainer,
  TinyChip,
} from "@pharmupp/p3-front-commons"
import { ChevronRight, FileText } from "react-feather"
import { LoadingCard } from "./commons"
import { useMyDocSigningLink } from "./useMyDocSigningLink"
import {
  type ApiUnsignedDocument,
  useMyUnsignedDocsApi,
} from "./useMyUnsignedDocsApi"

export const MyUnsignedDocsList = () => {
  const { unsignedDocs, isLoading } = useMyUnsignedDocsApi()

  return (
    <MainContainer>
      <FullWidthContent>
        {/* HEADER */}
        <FullWidthHeader
          title="Documents à signer"
          level={2}
          linkLabel="Accueil"
          linkTo="../"
          chip={
            <Badge
              badgeContent={unsignedDocs?.length}
              sx={{
                pl: 1,
                [`.${badgeClasses.badge}`]: {
                  mt: 0.2,
                  bgcolor: "common.electricBlue",
                },
                color: "white",
              }}
            />
          }
        />

        {/* CONTENT */}
        <Paper component={Box} width="100%" mb={19} pl={7} pr={5} py={4}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            columnGap={5}
            rowGap={3.5}
          >
            {isLoading
              ? Array.from({ length: 2 }).map((_, i) => (
                  <Skeleton key={i} variant="rounded" height={CARD_HEIGHT} />
                ))
              : unsignedDocs?.map((doc) => (
                  <DocumentCard key={doc.templateId} document={doc} />
                ))}
          </Box>
        </Paper>
      </FullWidthContent>
    </MainContainer>
  )
}

const CARD_HEIGHT = 132

const DocumentCard = ({ document }: { document: ApiUnsignedDocument }) => {
  const signingLink = useMyDocSigningLink(document.templateId)

  return (
    <LoadingCard loading={signingLink.isLoading} onClick={() => signingLink.open()}>
      <InnerContainer>
        <TinyChip label="E-signature" variation="blue" />

        <Stack direction="row" alignItems="center" gap={1}>
          <FileText color="#5496e3" size="27px" />
          <Typography variant="16px" fontWeight={600}>
            {document.templateName}
          </Typography>
        </Stack>

        <Divider flexItem sx={{ mt: 1 }} />

        <Button
          endIcon={<ChevronRight size={15} />}
          variant="text"
          size="small"
          sx={{
            alignSelf: "flex-end",
            textTransform: "uppercase",
            pointerEvents: "none",
          }}
        >
          Signer
        </Button>
      </InnerContainer>
    </LoadingCard>
  )
}

const InnerContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderLeft: "8px solid #1859f7",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
})) as typeof CardActionArea

import { Stack, type StackProps } from "@mui/material"
import type * as React from "react"
import { fieldsSpacing } from "../formLayout.const"

export type FieldsRowProps = Omit<StackProps, "direction">

/** Ensure a centralized & consistent spacing between fields */
export const FieldsRow: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<FieldsRowProps>>
> = ({ children, spacing = fieldsSpacing, ...props }) => (
  <Stack direction="row" spacing={spacing} {...props}>
    {children}
  </Stack>
)

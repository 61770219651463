import { NumberFormat } from "@pharmupp/p3-front-commons"
import type { ApiLoyaltyCategoryEnum } from "../types"

export const getStatusProp = ({
  quantity,
  category,
}: {
  quantity: number
  category: ApiLoyaltyCategoryEnum
}) => {
  switch (category) {
    case "CATEGORICAL":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${quantity > 1 ? "catégories" : "catégorie"}`}
          />
        ),
        regularPart: quantity > 1 ? "validées" : "validée",
      }
    case "SDAV":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${quantity > 1 ? "niveaux" : "niveau"}`}
          />
        ),
        regularPart: quantity > 1 ? "validés" : "validé",
      }
    case "QUALITATIVE":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${quantity > 1 ? "challenges" : "challenge"}`}
          />
        ),
        regularPart: quantity > 1 ? "réalisés" : "réalisé",
      }
  }
}

import { MenuItem, TextField, type TextFieldProps, styled } from "@mui/material"
import { useMemo } from "react"
import { ChevronDown } from "react-feather"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import type { ReferentialCode } from "../../../../types"
import { useFieldRoleConf } from "../../../context"
import { FieldText } from "../../layout"

export type FormSelectFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  options: ReferentialCode[]
  defaultValue?: string | number
} & TextFieldProps

// still in use in p3-adherent, p3-bonus, p3-labo, p3-news, p3-offers, p3-solutions, p3-user
/** @deprecated use FormSearchField instead*/
export const FormSelectField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  options,
  children,
  ...otherProps
}: FormSelectFieldProps<TFieldValues>) => {
  const { displayOnlyValue, emptyDisplayValue, ...fieldConf } =
    useFieldRoleConf(name)
  const sortedOpt = useMemo<ReferentialCode[]>(
    () => options.sort((a, b) => a.label.localeCompare(b.label)),
    [options],
  )

  return (
    <Controller<TFieldValues>
      name={name}
      defaultValue={defaultValue as TFieldValues[typeof name]}
      render={({ field, fieldState: { error } }) =>
        displayOnlyValue ? (
          <FieldText>
            {sortedOpt?.find((o) => o.code === field.value)?.label ||
              (emptyDisplayValue as React.ReactNode)}
          </FieldText>
        ) : (
          <StyledSelectTextField
            // Link label to input
            id={name}
            {...field}
            {...otherProps}
            {...fieldConf}
            select
            SelectProps={{ IconComponent: ChevronDown }}
            // Important to have default to "" to avoid a MUI error
            value={field.value ?? ""}
            error={!!error}
            helperText={error?.message}
          >
            {children ||
              sortedOpt?.map(({ code, label }) => (
                <MenuItem key={code} value={code}>
                  {label}
                </MenuItem>
              ))}
          </StyledSelectTextField>
        )
      }
    />
  )
}

export const StyledSelectTextField = styled(TextField)({
  "& .MuiSelect-icon": {
    marginTop: "-3px",
  },
})

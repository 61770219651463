import {
  Box,
  type CardProps,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  type StackProps,
  Typography,
  type TypographyProps,
  alpha,
  styled,
  useTheme,
} from "@mui/material"
import { type ReactNode, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Check } from "react-feather"

export const PAPER_CONTAINER_PX = 4

export type PaperContainerProps = CardProps<typeof Box> & {
  /** @deprecated Use Suspense instead */
  loading?: boolean
}
export const PaperContainer = ({
  children,
  loading = false,
  ...props
}: PaperContainerProps) => {
  const loadingCmp = (
    <Skeleton
      component={Box}
      p={PAPER_CONTAINER_PX}
      {...props}
      variant="rectangular"
      width="100%"
      height="100%"
    />
  )

  if (loading) {
    return loadingCmp
  }

  return (
    <ErrorBoundary
      fallback={
        <Paper
          component={Box}
          p={PAPER_CONTAINER_PX}
          {...props}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center" lineHeight="100%">
            ⚠️ Une erreur est survenue
          </Typography>
        </Paper>
      }
    >
      <Suspense fallback={loadingCmp}>
        <Paper component={Box} p={PAPER_CONTAINER_PX} {...props}>
          {children}
        </Paper>
      </Suspense>
    </ErrorBoundary>
  )
}

export const PaperContainerContent = styled(Box)({})
PaperContainerContent.defaultProps = { p: PAPER_CONTAINER_PX }

/** A title with a small green br */
export const PaperContainerTitle = ({
  children,
  ...props
}: { children: ReactNode } & StackProps) => {
  const { palette, typography } = useTheme()
  return (
    <Stack mb={3} color={palette.primary.main} {...props}>
      <Typography
        fontSize={typography.pxToRem(21)}
        lineHeight={typography.pxToRem(24)}
        fontWeight={500}
        mb={1}
      >
        {children}
      </Typography>
      <Divider
        color="secondary.main"
        sx={{
          width: "111px",
          height: "4px",
          borderRadius: "3px",
          backgroundColor: "secondary.main",
        }}
      />
    </Stack>
  )
}

export const PaperContainerHeader = ({
  title,
  label,
  value,
  ...props
}: {
  title: ReactNode
  label: ReactNode
  value: ReactNode
} & StackProps) => (
  <Stack spacing={2} mb={7} {...props}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <PaperContainerHeaderLabel>{title}</PaperContainerHeaderLabel>
      <Stack direction="row" spacing={2}>
        <PaperContainerHeaderLabel>{label}</PaperContainerHeaderLabel>
        <PaperContainerHeaderValue>{value}</PaperContainerHeaderValue>
      </Stack>
    </Stack>

    <Divider />
  </Stack>
)
export const PaperContainerHeaderLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
}))
export const PaperContainerHeaderValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  fontWeight: 500,
}))

export const PaperContainerSeparatorTitle = ({
  children,
  ...props
}: { children: string } & StackProps) => {
  const theme = useTheme()
  return (
    <Stack spacing={2} mb={4} {...props}>
      <Typography
        fontSize={theme.typography.pxToRem(14)}
        fontWeight={500}
        textTransform="uppercase"
        textAlign="center"
      >
        {children}
      </Typography>
      <Divider />
    </Stack>
  )
}

export const PaperContainerSmallTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  textTransform: "uppercase",
  color: theme.palette.grey.dark,
}))

export const PaperContainerDescription = ({
  children,
  ...props
}: TypographyProps) => (
  <Typography {...props} color="grey.dark">
    {children}
  </Typography>
)

export const PrimarySection = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.05),
}))
PrimarySection.defaultProps = {
  px: PAPER_CONTAINER_PX,
  pt: 5,
  pb: 4,
}

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  color: theme.palette.primary.dark1,
  textTransform: "uppercase",
}))

const CustomChip = styled(Chip)(({ theme }) => ({
  height: "auto",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: "100px",
  backgroundColor: "#e4e9f5",
  fontSize: theme.typography.pxToRem(21),
  fontWeight: 600,
}))

export const PrimarySectionChip = ({
  label,
  children,
}: {
  label: string
  children: string
}) => {
  const theme = useTheme()
  return (
    <Box>
      <Typography fontSize={theme.typography.pxToRem(14)} ml={3} mb={0.5}>
        {label}
      </Typography>
      <CustomChip
        label={children}
        icon={<Check color={theme.palette.success.main} />}
      />
    </Box>
  )
}

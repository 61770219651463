import type { FieldPath, FieldValues } from "react-hook-form"
import { ACCEPTED_FORMATS, FormDropZone } from "../FormDropZone"
import { UploadedPictures } from "./UploadedPictures"

export interface FormPictureDropZoneProps<
  TFieldValues extends FieldValues = FieldValues,
> {
  name: FieldPath<TFieldValues>
  label: string
  maxFilesCount: number
  /** In Mo */
  maxFileSize?: number
}

export const FormPictureDropZone = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  maxFilesCount,
  maxFileSize = Number.POSITIVE_INFINITY,
}: FormPictureDropZoneProps<TFieldValues>) => (
  <FormDropZone<TFieldValues, File & { preview: string }>
    name={name}
    label={label}
    maxFilesCount={maxFilesCount}
    maxFileSize={maxFileSize}
    formats={[
      ACCEPTED_FORMATS.JPG,
      ACCEPTED_FORMATS.PNG,
      ACCEPTED_FORMATS.GIF,
      ACCEPTED_FORMATS.WEBP,
    ]}
    renderUploadedFiles={({ files, onRemove }) => (
      <UploadedPictures count={maxFilesCount} files={files} onRemove={onRemove} />
    )}
    addedFileMapper={(file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    }
  />
)

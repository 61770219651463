import type { UploadedFile } from "../types"

export const triggerFileDownload = (url: string, fileName?: string) => {
  const a = document.createElement("a")
  a.href = url
  if (fileName) a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const getPublicFileUrl = (file: UploadedFile) =>
  `${window.CONTEXT.tempFileEndpoint}/${file.tempFileId}`

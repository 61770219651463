import {
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  Card,
  CardActionArea,
  Divider,
  Typography,
  type TypographyProps,
  styled,
} from "@mui/material"
import type { PropsWithChildren, ReactNode } from "react"
import { Link } from "react-router-dom"

export type ItemCardProps = {
  to: string
} & BoxProps
export const ItemCard = ({
  to,
  children,
  ...props
}: PropsWithChildren<ItemCardProps>) => (
  <Card component={Box}>
    <CardActionArea component={Link} to={to} sx={{ height: "100%" }}>
      <Box
        p={1}
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        textAlign="center"
        boxSizing="border-box"
        {...props}
      >
        {children}
      </Box>
    </CardActionArea>
  </Card>
)

export const ItemCardLogo = styled("img")({
  maxWidth: "80%",
  height: "85px",
  objectFit: "contain",
  margin: "auto",
})

export const ItemCardImage = styled("img")({
  width: "100%",
  aspectRatio: "16/9",
  objectFit: "cover",
})

export const ItemCardTitle = ({
  children,
  ...props
}: { children: string } & TypographyProps) => (
  <Typography
    fontSize={(theme) => theme.typography.pxToRem(12)}
    fontWeight="500"
    {...props}
  >
    {children}
  </Typography>
)
export const ItemCardDescription = ({
  children,
  maxLines = 3,
  sx = {},
  ...otherProps
}: {
  children: ReactNode
  maxLines?: number
} & TypographyProps<any>) => (
  <Typography
    fontSize={(theme) => theme.typography.pxToRem(12)}
    sx={[
      ...(Array.isArray(sx) ? sx : [sx]),
      {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        /* number of lines to show */
        WebkitLineClamp: maxLines,
        lineClamp: maxLines,
      },
    ]}
    {...otherProps}
  >
    {children}
  </Typography>
)

export const ItemCardDivider = styled(Divider)(({ theme }) => ({
  margin: `auto ${theme.spacing(4)}`,
  width: "50%",
}))
ItemCardDivider.defaultProps = {
  variant: "middle",
}

export const ItemCardButton = ({
  children,
  sx,
  ...props
}: { children: string } & ButtonProps<typeof Box>) => (
  <Button
    variant="contained"
    component={Box}
    alignSelf="center"
    sx={(theme) => ({
      ...(sx as any),
      pointerEvents: "none",
      marginTop: `${theme.spacing(2)}!important`,
    })}
    {...props}
  >
    {children}
  </Button>
)

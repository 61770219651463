import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  type RadioGroupProps,
} from "@mui/material"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"

export type FormRadioGroupProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  radios: { label: string; value: string | number | boolean }[]
  label?: string
  required?: boolean
} & RadioGroupProps

export const FormRadioGroup = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  radios,
  required = false,
  ...props
}: FormRadioGroupProps<TFieldValues>) => {
  const conf = useFieldRoleConf(name)

  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => {
        // Handles boolean values as native radio handles only strings
        const parsedCurrentValue =
          typeof radios[0].value === "boolean"
            ? typeof field.value === "boolean"
              ? field.value
              : field.value === "true"
            : field.value
        return (
          <FormControl error={!!error} variant="standard" required={required}>
            {!!label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}
            <RadioGroup
              {...(label && { "aria-labelledby": `${name}-label` })}
              {...props}
              {...field}
              {...conf}
              onChange={(event, value) => {
                props.onChange?.(event, value)
                field.onChange(event, value)
              }}
              value={field.value || ""} // Prevent MUI error on uncontrolled field
            >
              {radios.map(({ label: radioLabel, value }, index) => (
                <FormControlLabel
                  key={index}
                  label={radioLabel}
                  value={value}
                  checked={value === parsedCurrentValue}
                  control={
                    <Radio
                      color="success"
                      required={required}
                      disabled={!!conf.disabled}
                    />
                  }
                />
              ))}
            </RadioGroup>
            <FormHelperText error>{error?.message}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

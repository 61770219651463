import {
  Box,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  type StackProps,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import { type PropsWithChildren, Suspense, useState } from "react"
import { FilePlus } from "react-feather"
import { useTemplateSummaryApi } from "../../Template/useTemplateSummaryApi"
import { InfoCount } from "../../commons/components"
import { AddSignedDocumentModal } from "./AddSignedDocumentModal/AddSignedDocumentModal"

export const SummaryBanner = ({ templateId }: { templateId: string }) => (
  <Suspense fallback={<Skeleton variant="rectangular" height={HEIGHT} />}>
    <SummaryBannerInner templateId={templateId} />
  </Suspense>
)

const SummaryBannerInner = ({ templateId }: { templateId: string }) => {
  const { summary } = useTemplateSummaryApi({ templateId })

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Paper component={Box} px={2.5} py={1.5} height={HEIGHT}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <Stack
            direction="row"
            gap={2.5}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Section title="Suivi envoi">
              <ChipsRow>
                <InfoCount type="receivers" value={summary.receiversCount} />
                <InfoCount type="error" value={summary.errorCount} />
              </ChipsRow>
            </Section>
            <Section title="Suivi signatures">
              <ChipsRow
                gap={2}
                divider={
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ height: "30px", alignSelf: "flex-end" }}
                  />
                }
              >
                <ChipsRow>
                  <InfoCount type="unsigned" value={summary.unsignedCount} />
                  <InfoCount type="refused" value={summary.refusedCount} />
                </ChipsRow>
                <InfoCount type="signed" value={summary.signedCount} />
              </ChipsRow>
            </Section>
          </Stack>

          <Tooltip title="Ajouter manuellement un document signé">
            <IconButton
              onClick={() => setIsModalOpen(true)}
              size="large"
              sx={{
                bgcolor: "primary.light1",
                color: "white",
                "&:hover": { bgcolor: "primary.light2" },
              }}
            >
              <FilePlus />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>

      {/* MODALS */}
      {isModalOpen && (
        <AddSignedDocumentModal
          onClose={() => setIsModalOpen(false)}
          templateId={templateId}
          templateName={summary.name}
        />
      )}
    </>
  )
}
const HEIGHT = "100px"

const SectionTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography["10px"],
  textTransform: "uppercase",
  fontWeight: 600,
  color: theme.palette.primary.dark1,
}))

const Section = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <Stack gap={1}>
    <SectionTitle>{title}</SectionTitle>
    <Stack direction="row" gap={1}>
      {children}
    </Stack>
  </Stack>
)
const ChipsRow = ({ children, ...props }: StackProps) => (
  <Stack direction="row" gap={1} {...props}>
    {children}
  </Stack>
)

import { styled } from "@mui/material"
import {
  DashboardInfoBanner,
  DashboardInfoBannerCaption,
  DashboardInfoBannerTitle,
} from "@pharmupp/p3-front-commons"
import { Minus } from "react-feather"
import { MyNegativeSegmentationBanner } from "../Adherent"
import { useAdherentSummaryApi } from "../Adherent/hooks/useAdherentSummaryApi"

export const InfoBanner = ({
  adherentId,
}: {
  adherentId?: string
}) => {
  const isViewingItsOwnPharmacy = !adherentId
  const { summary } = useAdherentSummaryApi({ adherentId })

  // LOADING / ERROR
  if (!summary) return null

  // OWN PHARMACY + NEGATIVE SEGMENTATION
  if (isViewingItsOwnPharmacy && summary.negativeSegmentation) {
    return <MyNegativeSegmentationBanner />
  }

  // OTHER
  return (
    <DashboardInfoBanner
      variant={summary.negativeSegmentation ? "negativeSegmentation" : undefined}
    >
      {summary.negativeSegmentation && <Icon />}
      <DashboardInfoBannerTitle>{summary.name}</DashboardInfoBannerTitle>
      {!!summary.centralBusinessId && (
        <DashboardInfoBannerCaption>{`ID : ${summary.centralBusinessId}`}</DashboardInfoBannerCaption>
      )}
      {summary.negativeSegmentation && (
        <>
          <b>{" - "}</b>
          <DashboardInfoBannerTitle color="negativeSegmentation.main">
            Pharmacie en segmentation negative&nbsp;
          </DashboardInfoBannerTitle>
          <DashboardInfoBannerCaption>
            :&nbsp;le versement des primes Vie des UPP et des points Challenges est
            donc suspendu.&nbsp;
          </DashboardInfoBannerCaption>
        </>
      )}
    </DashboardInfoBanner>
  )
}

const Icon = styled(Minus)(({ theme }) => ({
  color: `${theme.palette.common.white}!important`,
  backgroundColor: theme.palette.negativeSegmentation.main,
  borderRadius: "100px",
}))

import {
  ROLES,
  type Role,
  api,
  useQuery,
  useSsoService,
} from "@pharmupp/p3-front-commons"
import { type ReactNode, useEffect } from "react"
import TagManager from "react-gtm-module"

const EXPOSED_ROLES: Role[] = [
  ROLES.ADMIN,
  ROLES.UPP_PRESIDENT,
  ROLES.ADHERENT_HOLDER,
  ROLES.ADHERENT_TEAM,
  ROLES.EXTERN_CONTACT_LABORATORY,
]
export const TrackingInit = ({ children }: { children: ReactNode }) => {
  const sso = useSsoService()
  const roles = sso.getRoles()
  const ids = sso.getIds()
  const userFullName = sso.getFullName()

  const { data: adherentSummary } = useQuery({
    queryKey: ["adherents", "my-adherent", "summary"],
    queryFn: () => api.get<AdherentSummary>("/api/adherents/my-adherent/summary"),
    enabled: !!roles?.some(
      (role) => role === ROLES.ADHERENT_HOLDER || role === ROLES.ADHERENT_TEAM,
    ),
  })

  // Sends role stat to GTM
  useEffect(() => {
    const [user_role] = (roles || [])
      .filter((role) => EXPOSED_ROLES.includes(role))
      .sort((a, b) => EXPOSED_ROLES.indexOf(a) - EXPOSED_ROLES.indexOf(b))

    // ⚠️ each dataLayer variable must be defined in GA tag in GTM and inside GA custom user metric
    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        user_role,
        user_id: ids?.keycloakId,
      },
    })
  }, [ids?.keycloakId, roles])

  // ChayAll init
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "init-chayall",
        ...(ids?.chayallId && { ChayAllSessionId: ids?.chayallId }),
      },
    })
  }, [ids?.chayallId])

  return (
    <>
      {children}
      <input
        type="hidden"
        name="third-party-exposition-user-fullname"
        value={userFullName}
      />
      {!!adherentSummary && (
        <input
          type="hidden"
          name="third-party-exposition-adherent-name"
          value={adherentSummary.name}
        />
      )}
    </>
  )
}

interface AdherentSummary {
  id: number
  name: string
  centralBusinessId: string
  cerpCode: string
  cipCode: string
}

import {
  Button,
  type ButtonProps,
  Stack,
  Typography,
  alpha,
  chipClasses,
  styled,
  useTheme,
} from "@mui/material"
import { Image, Info } from "react-feather"

export const REFUSE_COLOR = "#eb5735"
export const VALIDATED_COLOR = "#2854a1"
export const ACCEPT_COLOR = "#1db778"

export const TopChip = styled(Button)(({ theme }) => ({
  pointerEvents: "none",
  color: theme.palette.success.main,
  borderColor: theme.palette.success.main,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(16),
  fontWeight: 600,
  [`.${chipClasses.icon}`]: {
    color: theme.palette.success.main,
  },
}))
TopChip.defaultProps = { variant: "outlined" }

const ProofDocumentCountBase = ({
  label,
  caption,
  size = "medium",
  ...props
}: ButtonProps & { label: string; caption?: string }) => {
  const { palette, spacing, typography } = useTheme()
  return (
    <Button
      {...props}
      variant="outlined"
      size={size}
      sx={{
        padding: size === "small" ? spacing(1) : spacing(2),
        borderRadius: "5px",
        pointerEvents: "none",
        ...props.sx,
      }}
    >
      <Stack spacing={0.5}>
        <Typography
          fontSize={typography.pxToRem(size === "small" ? 13 : 16)}
          color={palette.common.darkBlue}
        >
          {label}
        </Typography>
        {!!caption && <Typography color={palette.grey.dark}>{caption}</Typography>}
      </Stack>
    </Button>
  )
}

export const ProofDocumentCount = ({
  count,
  endDate,
  size = "medium",
  ...props
}: ButtonProps & {
  count: number
  endDate?: Date
}) => {
  const { palette } = useTheme()
  return (
    <ProofDocumentCountBase
      startIcon={<Image />}
      label={`${count} ${count > 1 ? "justificatifs recommandés" : "justificatif recommandé"}`}
      caption={endDate ? `avant le ${endDate.toLocaleDateString()}` : undefined}
      size={size}
      sx={{
        ...props.sx,
        backgroundColor: "white",
        border: `2px solid ${alpha(palette.tertiary.main, 0.3)}`,
        "& svg": {
          color: palette.tertiary.main,
        },
      }}
    />
  )
}

export const AmmProofDocumentCount = ({
  count,
  size = "medium",
}: ButtonProps & { count: number }) => {
  const { palette } = useTheme()
  return (
    <ProofDocumentCountBase
      label={`${count} ${count > 1 ? "produits" : "produit"} AMM`}
      startIcon={<Info />}
      size={size}
      sx={{
        backgroundColor: palette.common.lightBlue,
        border: `1px solid ${palette.common.lightBlue}`,
        "& svg": {
          color: palette.common.electricBlue,
        },
      }}
    />
  )
}

import { Box, CircularProgress, Paper, type PaperProps, styled } from "@mui/material"
import React, { Suspense, type ReactNode } from "react"

type PaperContentProps = {
  loading?: boolean
  children: ReactNode
  topBanner?: ReactNode
} & PaperProps

export const PaperContent = React.forwardRef<HTMLDivElement, PaperContentProps>(
  ({ children, loading = false, topBanner, ...props }, ref) => (
    <Box
      gridArea="main"
      position="relative"
      mt={topBanner ? "-35px" : 0}
      minHeight="600px"
    >
      {topBanner}
      <StyledPaper {...props} ref={ref} sx={{ mt: topBanner ? -1.8 : 0 }}>
        <Suspense fallback={<Spinner />}>
          {loading ? <Spinner /> : children}
        </Suspense>
      </StyledPaper>
    </Box>
  ),
)

PaperContent.displayName = "PaperContent"

const StyledPaper = styled(Paper)({
  position: "relative",
  minHeight: "600px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "& .MuiBox": {
    overflowY: "auto",
  },
})

const Spinner = styled(CircularProgress)({
  margin: "auto",
  alignSelf: "center",
  justifySelf: "center",
})

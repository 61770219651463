import { ROLES, type RoleStrategy } from "@pharmupp/p3-front-commons"
import { columns } from "./columns"

export type ListRoleConf = RoleStrategy<Record<string, boolean>>

export const listRoleConf: ListRoleConf = {
  [ROLES.ADMIN]: {
    [columns.identifier.columnName]: false,
    [columns.lastName.columnName]: true,
    [columns.firstName.columnName]: true,
    [columns.email.columnName]: true,
    [columns.type.columnName]: true,
    [columns.status.columnName]: true,
    [columns.actions.columnName]: true,
  },
  [ROLES.ADHERENT_HOLDER]: {
    [columns.identifier.columnName]: true,
    [columns.lastName.columnName]: true,
    [columns.firstName.columnName]: true,
    [columns.email.columnName]: true,
    [columns.type.columnName]: true,
    [columns.status.columnName]: true,
    [columns.actions.columnName]: true,
  },
}

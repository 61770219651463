import {
  ROLES,
  type RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import type { ApiDisplayNewsReferential } from "../model"
import type { LatestNewsModel } from "./LatestNews.model"

export const useLatestNewsApi = () => {
  const apiEndpoint = useRoleConf(apiRoleConf)
  const { data: entity, isLoading: entityLoading } = useQuery<LatestNewsModel>({
    queryKey: ["news", "latest-news"],
    queryFn: () => api.get(apiEndpoint),
  })
  const { referential, loading: referentialLoading } = useLatestNewsReferentialApi()
  return {
    entity,
    loading: entityLoading || referentialLoading,
    referential,
  }
}
export const useLatestNewsReferentialApi = () => {
  const { data: referential, isLoading: referentialLoading } =
    useQuery<ApiDisplayNewsReferential>({
      queryKey: ["news-referential"],
      queryFn: () => api.get("/api/news/referential/adherent"),
    })
  return {
    referential,
    loading: referentialLoading,
  }
}

const apiRoleConf: RoleStrategy<string> = {
  [ROLES.ADHERENT_ADVISOR]: "/api/news/my-sector/latest-news",
  [ROLES.ADHERENT_HOLDER]: "/api/news/by-category",
  [ROLES.ADHERENT_TEAM]: "/api/news/by-category",
}

import { Error as ErrorIcon } from "@mui/icons-material"
import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  PaperContainer,
  type PaperContainerProps,
  formatNumber,
  formatPrice,
} from "@pharmupp/p3-front-commons"
import { Info } from "react-feather"
import { useLoyaltyApi } from "../../../api/useLoyaltyApi"
import { PeriodSelector, type PeriodSelectorOption } from "../../../commons"
import { Flag } from "./icons/Flag"
import { Money } from "./icons/Money"

type LoyaltyBlockProps = { labId: string } & PaperContainerProps

export const LoyaltyBlock = ({ labId, ...props }: LoyaltyBlockProps) => (
  <PaperContainer {...props} position="relative">
    <LoyaltyBlockInner labId={labId} />
  </PaperContainer>
)

const LoyaltyBlockInner = ({ labId }: { labId: string }) => {
  const { palette, spacing } = useTheme()

  // API
  const { loyalty, year, setYear } = useLoyaltyApi({ labId })

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr auto 1fr"
      gap={3}
      height="100%"
      pt={1}
    >
      {/* SELECT */}
      <PeriodSelector<number>
        value={year}
        onChange={setYear}
        options={yearOptions}
        sx={{
          position: "absolute",
          top: spacing(1.5),
          right: spacing(1.25),
        }}
      />

      <Stack gap={1}>
        <Circle bgcolor={palette.loyalty.main}>
          <Flag />
        </Circle>

        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <Title>CHALLENGES</Title>
          <Tooltip title="Points des challenges pour lesquels des commandes ont été passées pour le laboratoire courant">
            <Info color={palette.primary.light3} size="23px" />
          </Tooltip>
        </Stack>

        <KpisWrapper>
          <KpiWrapper>
            {loyalty.categoricalChallenge ? (
              <KPI>
                {loyalty.categoricalChallenge.points !== null
                  ? `${formatNumber(loyalty.categoricalChallenge.points)} pts`
                  : "--"}
              </KPI>
            ) : (
              <Ineligible />
            )}
            <KpiLabel>Catégoriels</KpiLabel>
          </KpiWrapper>

          <KpiWrapper>
            {loyalty.sdavChallenge ? (
              <>
                <KPI>
                  {loyalty.sdavChallenge.points !== null
                    ? `${formatNumber(loyalty.sdavChallenge.points)} pts`
                    : "--"}
                </KPI>
                <Stack direction="row" alignItems="center" gap="0.2em">
                  <KpiOptionalLabel
                    color={
                      loyalty.sdavChallenge.countOrders > 0
                        ? "primary.main"
                        : "common.orangeDark3"
                    }
                  >
                    dont {loyalty.sdavChallenge.countOrders}{" "}
                    {loyalty.sdavChallenge.countOrders > 1
                      ? "commandes"
                      : "commande"}
                  </KpiOptionalLabel>
                  {loyalty.sdavChallenge.countOrders === 0 && (
                    <ErrorIcon
                      sx={{ width: "18px", color: palette.common.orangeDark3 }}
                    />
                  )}
                </Stack>
              </>
            ) : (
              <Ineligible />
            )}
            <KpiLabel>SDAV</KpiLabel>
          </KpiWrapper>
        </KpisWrapper>
      </Stack>

      <Divider orientation="vertical" flexItem />

      <Stack gap={1}>
        <Circle bgcolor="#f67848">
          <Money />
        </Circle>

        <Title mb={2}>COMPENSATION</Title>

        <KpisWrapper>
          <KpiWrapper>
            <KPI>
              {loyalty.pharmeDisoundCompensation !== null
                ? formatPrice(loyalty.pharmeDisoundCompensation)
                : "-"}
            </KPI>
            <KpiLabel>Pharmedisound</KpiLabel>
          </KpiWrapper>

          <KpiWrapper>
            <KPI>
              {loyalty.opeazCompensation !== null
                ? formatPrice(loyalty.opeazCompensation)
                : "-"}
            </KPI>
            <KpiLabel>Opeaz</KpiLabel>
          </KpiWrapper>
        </KpisWrapper>
      </Stack>
    </Box>
  )
}

const currentYear = new Date().getFullYear()
const yearOptions: PeriodSelectorOption<number>[] = [
  { value: currentYear, label: currentYear },
  { value: currentYear - 1, label: currentYear - 1 },
]

const Circle = styled(Box)(({ theme: { palette, spacing } }) => ({
  marginBottom: spacing(1.5),
  width: "47px",
  height: "47px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const Title = styled(Typography)(({ theme: { palette, spacing, typography } }) => ({
  fontSize: typography.pxToRem(19),
  fontWeight: 500,
  lineHeight: typography.pxToRem(21),
  color: palette.common.darkBlue,
  textTransform: "uppercase",
}))

const KpisWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(1.5),
}))

const KpiWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(0.5),
}))

const KPI = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(22),
  lineHeight: typography.pxToRem(24),
  fontWeight: 600,
  color: palette.primary.dark1,
}))

const Ineligible = () => (
  <Typography variant="18px" fontWeight={600} color="primary.light1">
    Non éligible
  </Typography>
)

const KpiOptionalLabel = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(14),
  }),
)

const KpiLabel = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  color: palette.grey.light,
}))

import { Button, Stack, Typography, styled, useTheme } from "@mui/material"
import { Modal, ModalContent, ModalDialogActions } from "@pharmupp/p3-front-commons"
import { isBefore, isToday } from "date-fns"
import { useEffect, useState } from "react"
import { ChevronRight, FileText } from "react-feather"
import { LoadingCard } from "./commons"
import { useMyDocSigningLink } from "./useMyDocSigningLink"
import {
  type ApiUnsignedDocument,
  useMyUnsignedDocsApi,
} from "./useMyUnsignedDocsApi"

export const MyUnsignedDocsModal = () => {
  const { unsignedDocs } = useMyUnsignedDocsApi()
  const [open, setOpen] = useState(false)

  const firstDoc = unsignedDocs?.[0]

  useEffect(
    function decideIfOpen() {
      if (!firstDoc?.critical) return

      const dismissDateString = window.localStorage.getItem(STORAGE_KEY)
      const dismissDate = dismissDateString ? new Date(dismissDateString) : null
      if (
        !dismissDate ||
        (!isToday(dismissDate) && isBefore(dismissDate, new Date()))
      ) {
        setOpen(true)
        window.localStorage.removeItem(STORAGE_KEY)
      }
    },
    [firstDoc],
  )

  const close = () => {
    setOpen(false)
    window.localStorage.setItem(STORAGE_KEY, new Date().toISOString())
  }

  return (
    <Modal open={open} onClose={close} sx={{ width: "720px" }}>
      <Stack alignItems="center" gap={7} mb={5}>
        <img src="/illustration/sign-doc.svg" alt="Sign document" />
        <Stack alignItems="center" gap={2} maxWidth="80%">
          <Typography
            role="heading"
            variant="34px"
            fontWeight={500}
            color="primary.main"
          >
            Documents à signer
          </Typography>
        </Stack>
      </Stack>

      <ModalContent sx={{ p: 0 }}>
        <Stack gap={2.5} alignItems="center">
          {unsignedDocs?.map((doc) => (
            <DocumentCard
              key={doc.templateId}
              templateId={doc.templateId}
              templateName={doc.templateName}
            />
          ))}
        </Stack>
      </ModalContent>

      <ModalDialogActions sx={{ justifyContent: "center", mt: 5 }}>
        <Button variant="outlined" onClick={close}>
          Plus tard
        </Button>
      </ModalDialogActions>
    </Modal>
  )
}

const STORAGE_KEY = "hideUnsignedDocs"

const DocumentCard = ({
  templateId,
  templateName,
}: Pick<ApiUnsignedDocument, "templateId" | "templateName">) => {
  const signingLink = useMyDocSigningLink(templateId)
  const { palette } = useTheme()
  return (
    <LoadingCard
      loading={signingLink.isLoading}
      onClick={() => signingLink.open()}
      sx={{ width: "436px" }}
    >
      <Stack direction="row" justifyContent="space-between" gap={2} p={2.5}>
        <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
          <FileText color={palette.common.electricBlue} size={20} />
          <Typography variant="14px" fontWeight={600} noWrap>
            {templateName}
          </Typography>
        </Stack>

        <SigningBtn
          variant="contained"
          component="a"
          endIcon={<ChevronRight size={12} />}
          size="small"
          sx={{ pointerEvents: "none" }}
        >
          Signer
        </SigningBtn>
      </Stack>
    </LoadingCard>
  )
}

const SigningBtn = styled(Button)(({ theme: { spacing, typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(12),
  padding: spacing(1, 2),
  flexShrink: 0,
})) as typeof Button

import { Box, CircularProgress, Stack } from "@mui/material"
import type { ReactElement, ReactNode } from "react"
import {
  BottomPagination,
  SearchField,
  TableFiltersSection,
  TableToolbar,
  useResourceListApi,
} from "."
import { CardContainer, EmptyContent } from "../.."
import type { Config } from "./useResourceListApi/types"
import { useTableResize } from "./useTableResize"

const LoadingSpinner = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <CircularProgress />
  </Box>
)

const EmptyList = ({ label }: { label: string }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <EmptyContent imageSize="10vw">{label}</EmptyContent>
  </Box>
)

interface TableCard<QueryResource, Resource> {
  cardPerRow?: number
  emptyLabel: string
  entityMapper: (row: { data: Resource }, index: number) => ReactElement
  queryConfig: Config<QueryResource, Resource>
  // Filters
  filterDefaultValues?: Record<string, unknown>
  FilterSectionElement?: ReactNode
  // Search
  searchEnabled?: boolean
  searchDefaultOpen?: boolean
}

export function TableCard<QueryResource = unknown, Resource = QueryResource>({
  cardPerRow,
  emptyLabel,
  entityMapper,
  queryConfig,
  filterDefaultValues,
  FilterSectionElement,
  searchEnabled = true,
  searchDefaultOpen,
}: TableCard<QueryResource, Resource>) {
  // API
  const {
    list,
    initialLoading,
    contentLoading,
    paginationProps,
    isFiltered,
    ...api
  } = useResourceListApi({ ...queryConfig, routeStateEnabled: true })

  const { filledTableHeight, elementRef } = useTableResize({
    contentLoading,
    initialLoading,
    entities: list,
  })

  const calculateHeight =
    contentLoading || initialLoading ? filledTableHeight : "100%"

  return (
    <Stack spacing={0} height={calculateHeight} ref={elementRef}>
      {/* TOOLBAR */}
      <TableToolbar paginationProps={paginationProps}>
        {!!searchEnabled && (
          <SearchField
            onSubmit={api.search}
            value={api.currentSearch}
            defaultOpen={searchDefaultOpen}
          />
        )}
      </TableToolbar>

      {/* FILTERS SECTION */}
      {!!FilterSectionElement && (
        <Box mb={2}>
          <TableFiltersSection
            onChange={api.filter}
            values={api.currentFilters}
            defaultValues={filterDefaultValues}
          >
            {FilterSectionElement}
          </TableFiltersSection>
        </Box>
      )}

      {initialLoading || contentLoading ? (
        <LoadingSpinner />
      ) : list?.length ? (
        <CardContainer
          px={4}
          pt={1}
          pb={5}
          sx={{ overflowY: "auto" }}
          cardPerRow={cardPerRow}
        >
          {list.map((data, index) => entityMapper({ data }, index))}
        </CardContainer>
      ) : (
        <EmptyList label={isFiltered ? "Aucun résultat" : emptyLabel} />
      )}

      <BottomPagination
        paginationProps={paginationProps}
        listLength={list?.length || 0}
      />
    </Stack>
  )
}

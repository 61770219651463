import { Typography, styled } from "@mui/material"
import type * as React from "react"
import { EmptyContentIcon } from "./EmptyContentIcon"

export type EmptyContentProps = {
  children: string
  imageSize: string
} & React.HTMLAttributes<HTMLDivElement>

export const EmptyContent: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<EmptyContentProps>>
> = ({ children, imageSize, ...props }) => (
  <EmptyContainer {...props}>
    <EmptyContentIcon size={imageSize} style={{ margin: "0 auto" }} />
    <Label>{children}</Label>
  </EmptyContainer>
)

const EmptyContainer = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(3),
}))

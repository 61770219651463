import type { DefaultBodyType, StrictRequest } from "msw"

type TestJWT = { email: string }

const decodeJwt = (token: string) => {
  const jwtBase64 = token.replace("Bearer ", "")
  const [, payload] = jwtBase64.split(".")
  return JSON.parse(atob(payload)) as TestJWT
}

export const getRequestJWT = (request: StrictRequest<DefaultBodyType>) => {
  const jwt = request.headers.get("x-p3-authorization")!
  return decodeJwt(jwt)
}

export const createJwt = (payload: Record<string, unknown>) => {
  const header = { alg: "HS256", typ: "JWT" }
  const jwt = `${btoa(JSON.stringify(header))}.${btoa(JSON.stringify(payload))}`
  return `Bearer ${jwt}.test`
}

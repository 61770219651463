import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"
import type { ApiSignatoriesSummary } from "../Signatory/SignatoriesList/types"

export const useTemplateSummaryApi = ({ templateId }: { templateId: string }) => {
  // Summary
  const { data: summary } = useSuspenseQuery({
    queryKey: ["documents", "templates", templateId, "signatories", "summary"],
    queryFn: () =>
      api.get<ApiSignatoriesSummary>(
        `/api/documents/templates/${templateId}/signatories/summary`,
      ),
  })

  return { summary }
}

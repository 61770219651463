import type { ReferentialCode } from "@pharmupp/p3-front-commons"

const LAST_YEAR = 2023

export const referentialYear = getReferentialYear(LAST_YEAR)

function getReferentialYear(lastYear: number) {
  const arr: ReferentialCode[] = []
  let year = new Date().getFullYear() + 1
  while (year >= lastYear) {
    arr.push({ label: year.toString(), code: year })
    year = year - 1
  }
  arr.push({ label: "Sans année", code: "NO_YEAR" })
  return arr
}

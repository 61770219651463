import { CircularProgress, Fade } from "@mui/material"
import {
  AppContainer,
  AppErrorElement,
  AppRouter,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { type ReactNode, useLayoutEffect, useMemo } from "react"
import {
  Navigate,
  Outlet,
  type RouteObject,
  useLocation,
  useNavigation,
} from "react-router-dom"
import { AdhGlobalModals } from "./components/AdhGlobalModals"
import { Header } from "./components/Header"
import { menuRoleConf } from "./components/menu.roleConf"
import { AdBlockerWarning, TrackingInit } from "./components/third-party"
import { NotFound } from "./routes/Mixed/NotFound"

export function Router() {
  const { tabs } = useRoleConf(menuRoleConf)

  const routes = useMemo<RouteObject[]>(
    () => [
      {
        element: <AppLayout />,
        children: [
          {
            errorElement: <AppErrorElement />,
            children: [
              // HOME
              { path: "/", element: <Navigate replace to={tabs[0].to} /> },

              // ADMIN ONLY
              {
                path: "upp-et-utilisateurs/*",
                lazy: () => import("./routes/Admin/UppAndUsers/UppAndUsers"),
              },
              {
                path: "communication/*",
                lazy: () => import("./routes/Admin/Communication"),
              },
              {
                path: "solutions-et-services/*",
                lazy: () => import("./routes/Admin/OpeAndServices"),
              },
              {
                path: "gestion/*",
                lazy: () => import("./routes/Admin/Management"),
              },

              // MIXED ROLES
              {
                path: "dashboard/*",
                lazy: () => import("./routes/Mixed/Dashboard"),
              },
              { path: "offres/*", lazy: () => import("./routes/Mixed/Offers") },
              {
                path: "mon-compte/*",
                lazy: () => import("./routes/Mixed/MyAccount"),
              },
              {
                path: "conditions-commerciales/*",
                lazy: () => import("./routes/Mixed/SalesConditions"),
              },
              {
                path: "mon-laboratoire/*",
                lazy: () => import("./routes/Mixed/MyLaboratory"),
              },

              // CDO ONLY
              {
                path: "mes-officines/*",
                lazy: () => import("./routes/CDO/MyAdherentsList"),
              },

              // ADHERENT ONLY
              {
                path: "accueil/*",
                lazy: () => import("./routes/Adherent/AdherentHome"),
              },
              {
                path: "mon-upp-et-moi/*",
                lazy: () => import("./routes/Adherent/MyUppAndMe"),
              },
              {
                path: "actualites/*",
                lazy: () => import("./routes/Adherent/News"),
              },
              {
                path: "operations/*",
                lazy: () => import("./routes/Adherent/Operations"),
              },
              {
                path: "solutions/*",
                lazy: () => import("./routes/Adherent/Solutions"),
              },
              {
                path: "annuaire/*",
                lazy: () => import("./routes/Adherent/PhoneBook"),
              },
              {
                path: "evenement-a-venir",
                lazy: () => import("./routes/Adherent/FutureEvent"),
              },
              {
                path: "espace-president/*",
                lazy: () => import("./routes/Adherent/PresidentArea"),
              },

              // EXTERN CONTACT LABORATORY ONLY
              {
                path: "pharm-upp/*",
                lazy: () => import("./routes/ExternContactLaboratory/PharmUpp"),
              },

              // 404
              { path: "*", element: <NotFound /> },
            ],
          },
        ],
      },
    ],
    [tabs],
  )

  return <AppRouter routes={routes} />
}

/**
 * App layout + non domain providers
 */
function AppLayout() {
  const navigation = useNavigation()
  return (
    <ScrollTopOnRouteChange>
      <AdBlockerWarning />
      <TrackingInit>
        <AppContainer>
          <Header />

          {navigation.state === "loading" ? (
            <Fade
              in={navigation.state === "loading"}
              style={{
                transitionDelay: navigation.state === "loading" ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress sx={{ alignSelf: "center", margin: "auto" }} />
            </Fade>
          ) : (
            <Outlet />
          )}

          {/* GLOBAL MODALS */}
          <AdhGlobalModals />
        </AppContainer>
      </TrackingInit>
    </ScrollTopOnRouteChange>
  )
}

const ScrollTopOnRouteChange = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return <>{children}</>
}

import { CircularProgress, TextField, type TextFieldProps } from "@mui/material"
import { FieldText, FormRoleConfContext } from "@pharmupp/p3-front-commons"
import { useContext } from "react"
import { Search } from "react-feather"

type CurrentValueFieldProps = {
  name: string
  label: string
  loading: boolean
  setSearchQuery: (search: string) => void
} & Omit<TextFieldProps, "inputProps" | "InputProps"> &
  Required<Pick<TextFieldProps, "inputProps" | "InputProps">>

export const CurrentValueField = ({
  name,
  label,
  loading,
  setSearchQuery,
  ...props
}: CurrentValueFieldProps) => {
  const conf = useContext(FormRoleConfContext)
  const { displayOnlyValue } = conf[name] || {}
  const {
    inputProps: { value },
  } = props

  return displayOnlyValue ? (
    <FieldText>{value}</FieldText>
  ) : (
    <TextField
      {...props}
      name={name}
      label={label}
      onChange={(event) => setSearchQuery(event.target.value)}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {props.InputProps.endAdornment}
            <Search />
          </>
        ),
      }}
    />
  )
}

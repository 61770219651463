import { red as muiRed } from "@mui/material/colors"

export interface YannColor {
  main: string
  contrastText: string
  // Dark
  dark1: string
  dark2: string
  dark3: string
  dark4: string
  // Light
  light1: string
  light2: string
  light3: string
  light4: string
}

export const primary: YannColor = {
  main: "#2854a1",
  contrastText: "#fff",
  // Dark
  dark1: "#1d3e76",
  dark2: "#466cae",
  dark3: "#6c8abf",
  dark4: "#89a1cb",
  // Light
  light1: "#a1b4d6",
  light2: "#b6c5df",
  light3: "#c8d4e7",
  light4: "#dae1ef",
}

export const secondary: YannColor = {
  main: "#1db778",
  contrastText: "#fff",
  // Dark
  dark1: "#0c492f",
  dark2: "#147b50",
  dark3: "#199c66",
  dark4: "#1db676",
  // Light
  light1: "#51c796",
  light2: "#7bd5b0",
  light3: "#9fe0c5",
  light4: "#beead8",
}

export const tertiary: YannColor = {
  main: "#951B81",
  contrastText: "#fff",
  // Dark
  dark1: "#721564",
  dark2: "#a9459a",
  dark3: "#bc6eb0",
  dark4: "#c98cc0",
  // Light
  light1: "#d5a4cd",
  light2: "#deb8d9",
  light3: "#e7cbe3",
  light4: "#eedbeb",
}

export const purple = {
  main: "#9c42bd",
  contrastText: "#fff",
  // Dark
  dark1: "#8c20b1",
  dark2: "#7e1fab",
  dark3: "#691ba3",
  dark4: "#32128d",
  // Light
  light1: "#ad63c9",
  light2: "#c58fd9",
  light3: "#dbbce7",
  light4: "#f1e4f5",
}

export const grey = {
  dark: "#666c75",
  light: "#a9afb8",
  disabled: "#c0c3c6",
  // Not in the Abstract palette
  extraLight: "#e5e7e9",
}

export const red = {
  main: muiRed["400"],
  contrastText: "#666c75",
  // Dark
  dark1: muiRed["700"],
  dark2: muiRed["800"],
  dark3: muiRed["900"],
  dark4: muiRed.A700,
  // Light
  light1: muiRed["50"],
  light2: muiRed["100"],
  light3: muiRed["200"],
  light4: muiRed["300"],
}

export type OtherColors = typeof other
export const other = {
  blueGreenDark: "#64999a",
  blueGreen: "#6cabad",
  blueGreen1: "#7abcbc",
  blueGreen2: "#addcdc",
  aqua: "#9ce3e5",
  darkBlue: "#0c233d",
  electricBlue: "#1085ff",
  blue: "#5a8ce4",
  lightBlue: "#e6f1fd",

  red: "#f03308",
  greenLight1: "#b5d293",
  green: "#c0cb00",
  brightGreen: "#00d56d",
  orange: "#f9b000",
  orangeDark1: "#f38c11",
  orangeDark2: "#ff6c00",
  orangeDark3: "#fe4921",
  pink: "#e44881",

  pinky: "#ff94bc",
  violet: "#bfb5fe",
  darkViolet: "#9c42bd",
  turquoise: "#8fd9ff",
  salmon: "#fec7b5",

  white: "#fff",
  black: "#000",
}

import { Chip, type TableChipProps } from "@pharmupp/p3-front-commons"
import type { FC } from "react"

export const CatalogTypeChip: FC<React.PropsWithChildren<TableChipProps>> = (
  props,
) => (
  <Chip
    {...props}
    variant="outlined"
    fontColor="primary.main"
    borderColor="#c9d0d6"
  />
)

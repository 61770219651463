import { useEffect, useMemo } from "react"
import { doNothing } from "remeda"
import throttle from "throttleit"

export const useThrottledOnScroll = (
  callback: (() => void) | null,
  delay: number,
) => {
  const throttledCallback = useMemo<(() => void) | typeof doNothing>(
    () => (callback ? throttle(callback, delay) : doNothing),
    [callback, delay],
  )

  useEffect(() => {
    if (throttledCallback === doNothing) {
      return undefined
    }

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
    }
  }, [throttledCallback])
}

import { Chip, type ChipProps, chipClasses, styled } from "@mui/material"
import type { FC, ReactNode } from "react"
import { Award, UserPlus } from "react-feather"
import { type PartnershipType, partnershipTypeLabel } from "./types"

const partnershipTypeStyle = {
  DEFAULT: null,
  PREMIUM: {
    fontColor: "#f9b000",
    borderColor: "#ffecbf",
    icon: <Award />,
  },
  PRIVILEGED: {
    fontColor: "#951b81",
    borderColor: "#951b81",
    icon: <UserPlus />,
  },
} satisfies Record<
  PartnershipType,
  { fontColor: string; borderColor: string; icon: ReactNode } | null
>

export const PartnerChip: FC<
  { type?: PartnershipType; size?: "large" | "small" } & Omit<ChipProps, "size">
> = ({ type, size = "small", ...props }) =>
  !type || type === "DEFAULT" ? null : (
    <RoundedChip
      {...props}
      {...partnershipTypeStyle[type]}
      finalSize={size}
      label={
        size !== "large"
          ? partnershipTypeLabel[type]
          : `partenaire ${partnershipTypeLabel[type]}`
      }
    />
  )

export const RoundedChip = styled(Chip, {
  shouldForwardProp: (propName) =>
    !["fontColor", "borderColor", "finalSize"].includes(String(propName)),
})<{ fontColor: string; borderColor: string; finalSize?: "large" | "small" }>(
  ({ theme, fontColor: color, borderColor, finalSize = "large" }) => ({
    color,
    borderColor,
    borderRadius: "37px",
    ...(finalSize === "small"
      ? {
          padding: theme.spacing(0.5),
          height: "24px",
        }
      : {
          height: "62px",
          fontSize: theme.typography.pxToRem(14),
          padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
        }),
    [`.${chipClasses.icon}`]: {
      color,
      paddingRight: finalSize === "small" ? theme.spacing(0.5) : theme.spacing(1.5),
      height:
        finalSize === "small"
          ? theme.typography.pxToRem(15)
          : theme.typography.pxToRem(23),
    },
    [`.${chipClasses.label}`]: {
      fontWeight: 600,
      textTransform: "uppercase",
    },
  }),
)
RoundedChip.defaultProps = {
  variant: "outlined",
}

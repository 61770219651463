import { ROLES, type RoleStrategy } from "@pharmupp/p3-front-commons"

interface BonusOpDisplayApiRoleCOnf {
  getDetailsEndpoint: (operationId: string) => string
  getSummaryEndpoint: (operationId: string) => string
  getParticipantListEndpoint: (operationId: string) => string
}

export const apiRoleConf: RoleStrategy<BonusOpDisplayApiRoleCOnf> = {
  [ROLES.ADMIN]: {
    getDetailsEndpoint: () => "",
    getSummaryEndpoint: () => "",
    getParticipantListEndpoint: (operationId) =>
      `/api/operation/bonus/${operationId}/participations`,
  },
  [ROLES.UPP_PRESIDENT]: {
    getDetailsEndpoint: (operationId) => `/api/operation/bonus/upp/${operationId}`,
    getSummaryEndpoint: (operationId) =>
      `/api/operation/bonus/upp/${operationId}/summary`,
    getParticipantListEndpoint: (operationId) =>
      `/api/operation/bonus/upp/${operationId}/participations`,
  },
  [ROLES.ADHERENT_HOLDER]: {
    getDetailsEndpoint: (operationId) =>
      `/api/operation/bonus/adherent/${operationId}`,
    getSummaryEndpoint: (operationId) =>
      `/api/operation/bonus/upp/${operationId}/summary`,
    getParticipantListEndpoint: () => "",
  },
}

import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"

export const useSalesSummaryApi = ({ labId }: { labId: string }) => {
  const { data: salesSummary, isLoading } = useSuspenseQuery({
    queryKey: ["laboratories", labId, "adherent", "sales", "summary"],
    queryFn: () =>
      api.get<ApiSalesSummary>(`/api/laboratories/adherent/${labId}/sales/summary`),
  })

  return { salesSummary, isLoading }
}

export type ApiSalesSummary = {
  turnover: number
  turnoverEvolution: number
  unitsSold: number
  unitsSoldEvolution: number
  referencesCount: number
}

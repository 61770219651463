import { Box, Stack, styled } from "@mui/material"
import {
  ChallengeTrackingRow,
  PaperContainerSeparatorTitle,
} from "@pharmupp/p3-front-commons"
import { getStatusProp } from "../../commons/trackingHelpers"
import type { ApiLoyaltyCategoryEnum } from "../../types"

export interface TrackingRowProps {
  category: ApiLoyaltyCategoryEnum
  totalEarnPoints: number
  totalExpectedPoints: number
  totalRealized: number
  lastYearValue?: number | null
}
export const TrackingRow = ({
  category,
  totalEarnPoints,
  totalExpectedPoints,
  totalRealized,
  lastYearValue,
}: TrackingRowProps) => (
  <Stack mt={7} gap={3}>
    <PaperContainerSeparatorTitle>Suivi points acquis</PaperContainerSeparatorTitle>

    {/* GOAL TRACKING */}
    <Box display="flex" justifyContent="center">
      <ChallengeTrackingRow
        status={getStatusProp({
          quantity: totalRealized,
          category,
        })}
        currentValue={totalEarnPoints}
        goal={totalExpectedPoints}
        lastYearValue={lastYearValue || 0}
        showTargetChip={false}
      />
    </Box>
  </Stack>
)

export const SmallText = styled("span")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
}))

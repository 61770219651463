import type { MergeExclusive } from "type-fest"
import type { FileDownloadOptions } from "../../../../../hooks"
import type { MultipleExportOptions } from "./ExportModal"
import { MultipleExportsButton } from "./MultipleExportsButton"
import { SingleExportButton } from "./SingleExportButton"

export type ExportButtonProps = MergeExclusive<
  FileDownloadOptions,
  { exports: MultipleExportOptions[] }
>

export const ExportButton = (props: ExportButtonProps) => {
  if (props.exports) {
    if (props.exports.length > 1) {
      return <MultipleExportsButton exports={props.exports} />
    }
    return <SingleExportButton export={props.exports[0]} />
  }
  return <SingleExportButton export={props} />
}

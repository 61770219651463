import type { ActionDialogProps } from "./ActionDialog"
import type { ConfirmDialogProps } from "./ConfirmDialog"
import type { InfoDialogProps } from "./InfoDialog"

const commonConf = {
  open: false,
  onClickClose: () => null,
}

// Info dialogs

export const defaultInfoDialogConf: InfoDialogProps = {
  ...commonConf,
  title: "",
  message: "",
}

// Confirm dialogs

export const confirmDialogConf: ConfirmDialogProps = {
  ...commonConf,
  title: "",
  message: "",
  validateAction: () => undefined,
}

// Action dialogs

export const actionDialogConf: ActionDialogProps = {
  ...commonConf,
  validateButtonText: "",
  successMessage: "",
  endpoint: "",
  method: "",
  title: "",
  message: "",
}

const commonActionConf = {
  ...commonConf,
  endpoint: "",
  message: "Voulez-vous vraiment continuer ?",
}

export const activateDialogConf: ActionDialogProps<unknown> = {
  ...commonActionConf,
  method: "PUT",
  title: "Activation",
  validateButtonText: "Activer",
  successMessage: "Activation réussie",
}
export const deactivateDialogConf: ActionDialogProps = {
  ...commonActionConf,
  method: "PUT",
  title: "Désactivation",
  validateButtonText: "Désactiver",
  successMessage: "Désactivation réussie",
}

export const deleteDialogConf: ActionDialogProps = {
  ...commonActionConf,
  method: "DELETE",
  title: "Suppression",
  validateButtonText: "Supprimer",
  successMessage: "Suppression réussie",
}

import { Box, CircularProgress, styled } from "@mui/material"
import { api, useQuery, useResourceListApi } from "@pharmupp/p3-front-commons"
import { LatestNewsItems } from "../../LatestNews"
import type { LatestNewsItemModel } from "../../LatestNews/LatestNewsItem/LatestNewsItem.model"
import type { ApiDisplayNewsReferential } from "../../model"

interface MyUPPNewsProps {
  newsLink: (id: number) => string
  count?: number
}
export const MyUPPNews = ({ newsLink, count = 3 }: MyUPPNewsProps) => {
  const { list, initialLoading } = useResourceListApi<LatestNewsItemModel>({
    queryKey: ["news", "upcoming", "all", "onlyForMyUpp"],
    resourceEndpoint: "/api/news/upcoming/all",
    queryParams: {
      onlyForMyUpp: true,
    },
    rowsPerPage: 4,
  })

  const { data: referential, isLoading: referentialLoading } =
    useQuery<ApiDisplayNewsReferential>({
      queryKey: ["news", "referential", "adherent"],
      queryFn: () => api.get("/api/news/referential/adherent/"),
    })

  return (
    <Box minHeight="200px">
      {initialLoading || referentialLoading || !list || !referential ? (
        <Spinner />
      ) : (
        <LatestNewsItems
          items={list}
          referential={referential}
          columnNbr={count}
          link={newsLink}
        />
      )}
    </Box>
  )
}

const Spinner = styled(CircularProgress)({
  margin: "100px auto",
  display: "block",
})

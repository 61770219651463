export const get2YearsTabsWithPadding: (currentDate: Date) => number[] = (
  currentDate,
) => {
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  if (currentMonth === 11) {
    return [currentYear + 1, currentYear]
  }
  return [currentYear, currentYear - 1]
}

export function get3YearsTabsWithPadding() {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  if (currentMonth >= 10) {
    return [currentYear + 1, currentYear, currentYear - 1]
  }
  return [currentYear, currentYear - 1, currentYear - 2]
}

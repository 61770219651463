import {
  Badge,
  Box,
  Link as MuiLink,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material"
import { type ReactNode, useContext, useEffect, useMemo } from "react"
import { Link, useLocation, useResolvedPath } from "react-router-dom"
import { NumberFormat } from "../../dataDisplay"
import { SideNavCtx } from "./SideNavContext"

export type SideNavLinkProps = {
  label: ReactNode
  counter?: number
  counterVariant?: "new" | "count"
  counterTotal?: number
  counterMax?: number
  disabled?: boolean
} & (
  | { to: string; href?: never; target?: never }
  | { to?: never; href: string; target?: string }
)

export const SideNavLink = ({
  label,
  to,
  href,
  counter,
  counterTotal,
  counterVariant = "new",
  counterMax,
  disabled = false,
  target,
  ...props
}: SideNavLinkProps) => {
  const { registerLink, setActiveLink } = useContext(SideNavCtx)

  useEffect(() => {
    if (to || href) {
      registerLink(to !== undefined ? to : href)
    }
  }, [registerLink, to, href])

  const location = useLocation()
  const path = useResolvedPath(to || "")
  const isActive = useMemo(() => {
    const locationPathname = location.pathname
    const toPathname = path.pathname
    return (
      locationPathname === toPathname ||
      (locationPathname.startsWith(toPathname) &&
        locationPathname.charAt(toPathname.length) === "/")
    )
  }, [location.pathname, path.pathname])
  useEffect(() => {
    if (to && isActive) {
      setActiveLink(to)
    }
  }, [isActive, setActiveLink, to])

  return (
    <Tab
      {...props}
      label={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pr={2}
        >
          <Box component="span" width="100%">
            {label}
          </Box>
          {typeof counter === "number" && (
            <Counter
              badgeContent={
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontSize="inherit"
                    color={counterTotal ? "common.electricBlue" : "inherit"}
                  >
                    <NumberFormat value={counter || 0} />
                  </Typography>
                  {!!counterTotal && (
                    <>
                      <Typography fontSize="inherit">/</Typography>
                      <Typography fontSize="inherit">{counterTotal}</Typography>
                    </>
                  )}
                </Stack>
              }
              counterVariant={counterVariant}
              max={counterMax}
              active={isActive}
              sx={{ ml: 1 }}
            />
          )}
        </Stack>
      }
      {...(to !== undefined
        ? { to, component: Link }
        : { href, component: MuiLink, target: target ?? "_blank" })}
      disabled={(!to && !href) || disabled}
    />
  )
}

interface CounterProps {
  counterVariant: "new" | "count"
  active: boolean
}
export const Counter = styled(Badge, {
  shouldForwardProp: (prop) => !["counterVariant", "active"].includes(String(prop)),
})<CounterProps>(({ active, counterVariant, theme }) => {
  const badgeStyle = {
    whiteSpace: "nowrap",
    position: "initial",
    fontSize: theme.typography.pxToRem(12),
    transform: "none",
  } as const
  const color = active ? theme.palette.primary.main : theme.palette.grey.dark
  switch (counterVariant) {
    case "count":
      return {
        ".MuiBadge-badge": {
          ...badgeStyle,
          color,
          backgroundColor: "white",
          border: `1px solid ${color}`,
        },
      }
    default:
      return { ".MuiBadge-badge": badgeStyle }
  }
})
Counter.defaultProps = {
  color: "primary",
}

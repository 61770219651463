import { Box, Stack, type StackProps, Typography } from "@mui/material"
import { formatPrice } from "@pharmupp/p3-front-commons"
import type { Icon } from "react-feather"
import { InfoTooltip } from "../../layouts"

export const TurnoverBox = ({
  title,
  label,
  turnover,
  tooltip,
  ...props
}: {
  title: Icon | string
  label: string
  turnover: number
  tooltip: string
} & Omit<StackProps, "title">) => (
  <Stack {...props}>
    <Stack direction="row" alignItems="center" spacing={1} mb={2}>
      <Box
        borderRadius="2px"
        px={2}
        py={0.5}
        height="30px"
        alignSelf="flex-start"
        color="white"
        fontWeight={500}
        bgcolor={typeof title === "string" ? "primary.dark1" : "common.orange"}
        sx={{ textTransform: "uppercase" }}
      >
        {typeof title === "string" ? (
          title
        ) : (
          <Box component={title} size="1.2rem" display="flex" alignItems="center" />
        )}
      </Box>
      <InfoTooltip title={tooltip} />
    </Stack>

    <Typography
      fontSize={(theme) => theme.typography.pxToRem(14)}
      color="grey.light"
      sx={{ textTransform: "uppercase" }}
    >
      {label}
    </Typography>
    <Typography fontWeight={600} color="common.darkBlue">
      {formatPrice(turnover)}
    </Typography>
  </Stack>
)

import {
  type AppBarProps,
  Container,
  AppBar as MuiAppBar,
  styled,
} from "@mui/material"
import { forwardRef } from "react"

export const LocalAppBar = styled(MuiAppBar)(({ theme }) => ({
  height: "125px",
  borderColor: theme.palette.common.white,
  borderStyle: "solid",
  borderWidth: "0 0 1px",
  borderRadius: 0,
  background: theme.palette.header.gradient,
}))

export const AppBar = forwardRef<HTMLDivElement, AppBarProps>(
  ({ children, ...props }, ref) => (
    <LocalAppBar {...props} ref={ref}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {children}
      </Container>
    </LocalAppBar>
  ),
)
AppBar.displayName = "AppBar"

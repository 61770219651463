import { useCallback, useReducer } from "react"
import { fetchInterceptor } from "../../../../hooks/http"
import type { UploadedFile } from "../../../../types/UploadedFile"

interface UseUploadFileArgs {
  url: string
  options?: RequestInit
}
export function useUploadFile({ url, options }: UseUploadFileArgs) {
  const [state, dispatch] = useReducer(uploadReducer, initialState)

  const upload = ({ data }: { data: File }): Promise<{ result: UploadedFile }> =>
    new Promise((resolve, reject) => {
      dispatch({ type: "init" })

      const xhr = new XMLHttpRequest()
      xhr.open(options?.method || "POST", url)

      fetchInterceptor
        .run({
          ...options,
        })
        .then((optionsToSend) => {
          if (optionsToSend?.headers) {
            for (const [header, value] of Object.entries(optionsToSend.headers)) {
              xhr.setRequestHeader(header, value)
            }
          }

          const formData = new FormData()
          formData.append("file", data, encodeURI(data.name))

          xhr.upload.addEventListener("progress", (e) => {
            dispatch({
              type: "progress",
              payload: {
                progress: Math.round((e.loaded / e.total) * 100),
              },
            })
          })

          xhr.addEventListener("load", () => {
            if (xhr.status >= 300) {
              dispatch({
                type: "failed",
                payload: {
                  error: `Error while uploading file: ${data.name}`,
                },
              })
              return reject(state)
            }
            const payload = { result: JSON.parse(xhr.response) }
            dispatch({ type: "done", payload })
            resolve(payload)
          })

          xhr.addEventListener("error", () => {
            if (import.meta.env.VITE_MOCKED) {
              const fakeResult = {
                result: { originalFileName: data.name } as UploadedFile,
              }
              dispatch({ type: "done", payload: fakeResult })
              return resolve(fakeResult)
            }

            dispatch({ type: "failed", payload: { error: "Unknown error" } })
            return reject(state)
          })

          const abortAction = () => {
            xhr.abort()
            dispatch({ type: "canceled" })
          }

          xhr.send(formData)

          dispatch({ type: "started", payload: { abort: abortAction } })
        })
    })

  const reset = useCallback(() => {
    dispatch({ type: "reset" })
  }, [])

  return { ...state, upload, reset }
}

interface UploadState {
  started: boolean
  done: boolean
  result: null | UploadedFile
  error: string | null
  progress: number
  doAbort?: () => void
}

const initialState: UploadState = {
  started: false,
  done: false,
  result: null,
  error: null,
  progress: 0,
  doAbort: undefined,
}

type UploadAction =
  | { type: "init" | "reset" | "canceled" }
  | { type: "started"; payload: { abort: () => void } }
  | { type: "progress"; payload: { progress: number } }
  | { type: "done"; payload: { result: UploadedFile } }
  | { type: "failed"; payload: { error: string } }

const uploadReducer = (state: UploadState, action: UploadAction): UploadState => {
  switch (action.type) {
    case "started":
      return { ...state, started: true, doAbort: action.payload.abort }
    case "init":
    case "reset":
    case "canceled":
      return initialState
    case "progress":
      return { ...state, progress: action.payload.progress }
    case "done":
      return { ...state, progress: 100, result: action.payload.result, done: true }
    case "failed":
      return {
        ...state,
        progress: 0,
        error: action.payload.error,
      }
    default:
      return state
  }
}

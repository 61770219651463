import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Stack,
  dialogClasses,
  styled,
} from "@mui/material"
import {
  ModalContent,
  PaperContainerTitle,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { Suspense } from "react"
import { X } from "react-feather"
import { useAdherentLabDetailsV2 } from "../useAdherentLabDetails"
import { ContactLaboCard } from "./sections/commons"

interface InfoLabModalProps {
  open: boolean
  onClose: () => void
  labId: string
}
export function InfoLabModal({ open, onClose, labId }: InfoLabModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "528px", minHeight: "528px" } }}
    >
      <Suspense
        fallback={
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        <InfoLabModalInner open={open} onClose={onClose} labId={labId} />
      </Suspense>
    </Modal>
  )
}

function InfoLabModalInner({ open, onClose, labId }: InfoLabModalProps) {
  const { laboratory: labo } = useAdherentLabDetailsV2({ labId })
  return (
    <ModalContent sx={{ textAlign: "left", p: 0 }}>
      <Box display="flex" justifyContent="space-between" padding={0}>
        {/* HEADER */}
        <PaperContainerTitle>Contact Labo</PaperContainerTitle>
        <CloseButton onClick={onClose}>
          <X />
        </CloseButton>
      </Box>

      {/* LOGO */}
      {!!labo?.logo && (
        <Avatar
          variant="rounded"
          src={getPublicFileUrl(labo.logo)}
          alt={labo.name}
          sx={{
            width: "210px",
            height: "auto",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          {labo.name.charAt(0)}
        </Avatar>
      )}

      {/* COORDONNEES */}
      {!!labo?.name ||
      labo?.address.line1 ||
      labo?.address.postalCode ||
      labo?.address.city ||
      labo?.address.phone ||
      labo?.address.webSite ? (
        <ContactLaboCard
          paperElement="true"
          header="Coordonnées"
          name={labo?.name}
          phone={labo?.address.phone}
          line1={labo?.address.line1}
          line2={labo?.address.line2}
          postalCode={labo?.address.postalCode}
          city={labo?.address.city}
          webSite={labo?.address.webSite}
        />
      ) : null}

      <Stack gap={2} divider={<Divider />} p={1}>
        {/* CONTACT CONSEILLER */}
        {!!labo?.fieldTeamLink && (
          <ContactLaboCard
            header="Contact Conseiller"
            name="Representant de votre UPP"
            wording="Secteur et coordonnées"
            wedia={labo?.fieldTeamLink}
          />
        )}

        {/* CONTACT SAV */}
        {!!labo?.customerServiceContacts?.length && (
          <Box>
            {labo?.customerServiceContacts?.map((contact, index) => (
              <Box key={index}>
                <ContactLaboCard
                  header="Contact SAV"
                  name="Service client"
                  phone={contact.phone}
                  email={contact.email}
                />
              </Box>
            ))}
          </Box>
        )}

        {/* CONTACT TEMPS DE CRISE */}
        {!!labo?.crisisContacts?.length && (
          <Box>
            {labo?.crisisContacts?.map((contact, index) => (
              <Box key={index}>
                <ContactLaboCard
                  header='Contact "Temps de crise"'
                  name={contact.name}
                  title={contact.function}
                  phone={contact.phone}
                  email={contact.email}
                />
              </Box>
            ))}
          </Box>
        )}
      </Stack>
    </ModalContent>
  )
}

const Modal = styled(Dialog)(({ theme: { spacing } }) => ({
  [`.${dialogClasses.paper}`]: {
    padding: spacing(5),
  },
}))

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  top: "6px",
  right: "6px",
  width: "36px",
  height: "36px",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#f2f2f3",
  },
}))

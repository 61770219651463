import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useBonusOpSummaryApi = ({
  year,
  adherentId,
}: { year: number; adherentId?: string }) => {
  const { data: summary, isLoading } = useQuery({
    queryKey: ["bonus-operation", "adherent", "loyalty", year],
    queryFn: () =>
      api.get<ApiBonusOpSummary>(
        `/api/operation/bonus/adherent/loyalty?year=${year}`,
      ),
    enabled: !adherentId,
  })

  return { summary, isLoading }
}

interface ApiBonusOpSummary {
  validated: number
  total: number
}

import { Box, Divider, Link, Stack, Typography, styled } from "@mui/material"
import { MarkdownDisplay } from "@pharmupp/p3-front-commons"
import * as React from "react"
import { ExternalLink, Link2, Phone } from "react-feather"
import { useAdherentLabDetailsV2 } from "../../../useAdherentLabDetails"
import { InfoLabModal } from "../../InfoLabModal"
import { SmallLinkCard } from "../commons"

export const Infos = ({ labId }: { labId: string }) => {
  const { laboratory } = useAdherentLabDetailsV2({ labId })
  // Modals
  const [infoModalOpened, setInfoModalOpened] = React.useState(false)

  return (
    <>
      <Box display="grid" gridTemplateColumns="2fr 1fr" gap={9.5}>
        <Stack gap={5}>
          <Advantages advantages={laboratory.advantages} />

          <Box>
            {laboratory.description ? (
              <MarkdownDisplay>{laboratory.description}</MarkdownDisplay>
            ) : (
              <Typography fontStyle="italic">Aucune description</Typography>
            )}
          </Box>

          {laboratory.documents.mediathequeLink && (
            <AllDocsLink link={laboratory.documents.mediathequeLink} />
          )}
        </Stack>
        <div>
          <SmallLinkCard
            icon={<Phone size="25px" />}
            label="Contact Labo"
            onClick={() => setInfoModalOpened(true)}
          />
        </div>
      </Box>
      <InfoLabModal
        open={infoModalOpened}
        onClose={() => setInfoModalOpened(false)}
        labId={labId}
      />
    </>
  )
}

const Advantages = ({ advantages = [] }: { advantages?: string[] }) => (
  <>
    {!!advantages.length && (
      <Stack spacing={1}>
        <Typography textTransform="uppercase" color="common.electricBlue">
          Les + du labo
        </Typography>
        <Stack direction="row" spacing={1}>
          {advantages.map((advantage, i) => (
            <Box
              key={i + advantage}
              border="1px solid"
              borderColor="common.electricBlue"
              borderRadius="2px"
              px={3}
              py={1}
            >
              <AdvantageText>{advantage}</AdvantageText>
            </Box>
          ))}
        </Stack>
      </Stack>
    )}
  </>
)

const AdvantageText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  textTransform: "uppercase",
  color: theme.palette.primary.dark1,
}))

const AllDocsLink = ({ link }: { link: string }) => (
  <Stack gap={2}>
    <Divider />

    <Link
      href={link}
      target="_blank"
      color="primary.main"
      sx={{ display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" gap={2}>
          <Link2 />
          <Typography color="grey.dark" sx={{ textDecoration: "underline" }}>
            Tous les documents du laboratoire
          </Typography>
        </Box>

        <ExternalLink size="18px" color="#7d9fd2" />
      </Box>
    </Link>

    <Divider />
  </Stack>
)

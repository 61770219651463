import { Divider, Stack, Typography, styled } from "@mui/material"
import { ButtonLink } from "@pharmupp/p3-front-commons"
import { openOffer } from "../../common"
import type { Offer } from "../../types"
import { OffersSlider } from "./OffersSlider"
import { UpcomingOffers } from "./UpcomingOffers"
import { useOpePromoOfferApi } from "./useOpePromoOfferApi"

interface OpePromoWidgetProps {
  catalogLink: string
  coaliaOffersLink: string
  sdavOffersLink: string
}

export const OpePromoWidget = ({
  catalogLink = "",
  sdavOffersLink = "",
  coaliaOffersLink = "",
}: OpePromoWidgetProps) => {
  const { sdav, coalia, upcomingOffers, loading } = useOpePromoOfferApi()
  const onOfferClick = (offer: Offer) => openOffer(offer)

  return (
    <Stack spacing={2}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Stack>
          <Typography
            color="primary.dark"
            textTransform="uppercase"
            sx={(theme) => ({ fontSize: theme.typography.pxToRem(21) })}
          >
            Offres <b>en cours</b>
          </Typography>
          <UnderHeaderDivider />
        </Stack>
        <ButtonLink variant="contained" to={catalogLink}>
          Voir le catalogue
        </ButtonLink>
      </Stack>
      {!loading && (
        <>
          <OffersSlider
            offers={sdav}
            source="SDAV"
            allOffersLink={sdavOffersLink}
            onOfferClick={onOfferClick}
          />

          <OffersSlider
            offers={coalia}
            source="COALIA"
            allOffersLink={coaliaOffersLink}
            onOfferClick={onOfferClick}
          />

          {upcomingOffers.length > 0 && (
            <UpcomingOffers offers={upcomingOffers} onOfferClick={onOfferClick} />
          )}
        </>
      )}
    </Stack>
  )
}

const UnderHeaderDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: "0.2rem",
  borderRadius: "0.2rem",
  borderColor: theme.palette.secondary.main,
  width: "6.8rem",
}))

import { ROLES, type Role } from "@pharmupp/p3-front-commons"
import type { ReactElement } from "react"
import { Home, Inbox } from "react-feather"
import type { RequireAtLeastOne } from "type-fest"

interface DividerTab {
  divider: true
}
type BaseTab = RequireAtLeastOne<
  { label: string; icon: ReactElement },
  "label" | "icon"
> & { accent?: boolean }
export type InternalTab = BaseTab & {
  to: string
  href?: never
}
export type ExternalTab = BaseTab & {
  href: string
  to?: never
}
type MenuTab = InternalTab | ExternalTab | DividerTab

export const menuRoleConf: Partial<
  Record<Role, { tabs: [InternalTab, ...MenuTab[]] }>
> = {
  [ROLES.ADMIN]: {
    tabs: [
      { label: "UPP & utilisateurs", to: "/upp-et-utilisateurs" },
      { label: "Communication", to: "/communication" },
      { label: "Achats", to: "/offres" },
      { label: "Solutions & Services", to: "/solutions-et-services" },
      { label: "Gestion", to: "/gestion" },
      { label: "Forum & PA", href: window.CONTEXT.forumUrl },
      { label: "Pushmail", href: window.CONTEXT.pushmailUrl },
    ],
  },
  [ROLES.ADHERENT_ADVISOR]: {
    tabs: [
      { icon: <Home width={16} height={16} />, to: "/accueil" },
      { label: "Mes officines", to: "/mes-officines" },
      { label: "Achats", to: "/offres" },
      { label: "Médiathèque", href: "https://medias.pharm-upp.fr/" },
      { label: "Forum & PA", href: "https://forum.pharm-upp.fr/" },
      { label: "Ospharm", href: "https://upp.ospharm.org/" },
    ],
  },
  [ROLES.UPP_PRESIDENT]: {
    tabs: [
      { icon: <Home width={16} height={16} />, to: "/accueil" },
      { label: "Mon officine", to: "/dashboard" },
      { label: "UPP & Moi", to: "/mon-upp-et-moi" },
      { label: "Achats", to: "/offres" },
      { label: "Opérations", to: "/operations" },
      { label: "Solutions", to: "/solutions" },
      { label: "Actualités", to: "/actualites" },
      { label: "Médiathèque", href: window.CONTEXT.mediasUrl },
      { label: "Forum & PA", href: window.CONTEXT.forumUrl },
      { divider: true },
      {
        icon: <Inbox size="18px" />,
        label: "Espace Président",
        to: "/espace-president",
        accent: true,
      },
    ],
  },
  [ROLES.ADHERENT_HOLDER]: {
    tabs: [
      { icon: <Home width={16} height={16} />, to: "/accueil" },
      { label: "Mon officine", to: "/dashboard" },
      { label: "UPP & Moi", to: "/mon-upp-et-moi" },
      { label: "Achats", to: "/offres" },
      { label: "Opérations", to: "/operations" },
      { label: "Solutions", to: "/solutions" },
      { label: "Actualités", to: "/actualites" },
      { label: "Médiathèque", href: `${window.CONTEXT.mediasUrl}/portal` },
      { label: "Forum & PA", href: window.CONTEXT.forumUrl },
    ],
  },
  [ROLES.ADHERENT_TEAM]: {
    tabs: [
      { icon: <Home width={16} height={16} />, to: "/accueil" },
      { label: "UPP & Moi", to: "/mon-upp-et-moi" },
      { label: "Achats", to: "/offres" },
      { label: "Opérations", to: "/operations" },
      { label: "Solutions", to: "/solutions" },
      { label: "Actualités", to: "/actualites" },
      { label: "Médiathèque", href: `${window.CONTEXT.mediasUrl}/portal` },
      { label: "Forum & PA", href: window.CONTEXT.forumUrl },
    ],
  },
  [ROLES.EXTERN_CONTACT_LABORATORY]: {
    tabs: [
      { label: "Tableau de bord", to: "/dashboard" },
      { label: "Mon laboratoire", to: "/mon-laboratoire" },
      { label: "Conditions commerciales", to: "/conditions-commerciales" },
      { label: "Pharm-UPP", to: "/pharm-upp" },
      { label: "Animations des ventes", to: "/animation-des-ventes" },
      { label: "Réclamations", to: "/reclamations" },
    ],
  },
}

// HEADER COMPONENTS

import {
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  AmountChip,
  BackLink,
  LogoCircleChip,
  PageHeader,
  ROLES,
  SideNav,
  SideNavLink,
  SidebarCard,
  formatPrice,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import { Check } from "react-feather"
import { useParams } from "react-router-dom"
import type { RequestStatus } from "./useBonusOperationDisplayApi"
import { useBonusOperationSummaryApi } from "./useBonusOperationSummaryApi"

interface BonusOpLayoutProps {
  title?: (name: string) => string
  status?: RequestStatus
  children: ReactNode
}

export const BonusOpLayout = ({ title, status, children }: BonusOpLayoutProps) => {
  const { palette, typography } = useTheme()
  const isPresident = useRoleCheck(ROLES.UPP_PRESIDENT)
  const { id } = useParams()

  const { summary } = useBonusOperationSummaryApi({ id: id! })

  const amountChipProps = {
    variant: "outlined",
    prefix: "+",
    unit: "€",
    color: palette.common.electricBlue,
  } as const

  return (
    <>
      <BackLink to="../../">Liste</BackLink>

      {/* SIDEBAR */}
      {!!summary && (
        <Stack gridArea="sidebar" spacing={2}>
          {/* NAVIGATION */}
          {!!isPresident && (
            <SideNav>
              <SideNavLink label="Détail de la prime" to="../detail" />
              {status === "ACTIVE" && (
                <SideNavLink
                  label="Suivi participation"
                  to="../participation"
                  counter={summary?.inProgressParticipants || 0}
                  counterTotal={summary?.totalParticipants || 0}
                  counterVariant="count"
                />
              )}
            </SideNav>
          )}

          {/* INFOS PANEL */}
          <SidebarCard>
            <Stack spacing={5}>
              {!!summary?.tag && (
                <Stack spacing={1}>
                  <SidebarLabel>Catégorie</SidebarLabel>
                  <Box>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={summary.tag}
                      color="primary"
                    />
                  </Box>
                </Stack>
              )}
              {!!summary?.validityStartDate && !!summary?.validityEndDate && (
                <Stack spacing={1}>
                  <SidebarLabel>Validité</SidebarLabel>
                  <Validity
                    start={summary?.validityStartDate}
                    end={summary?.validityEndDate}
                  />
                </Stack>
              )}
              <Divider />
              {!!isPresident &&
                status === "ACTIVE" &&
                !!summary?.totalParticipants && (
                  <Stack spacing={1}>
                    <SidebarLabel>Montant total primes validées</SidebarLabel>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        fontSize={typography.pxToRem(23)}
                      >
                        <Typography
                          fontSize="inherit"
                          fontWeight={(summary?.paidBonuses || 0) > 0 ? 500 : 400}
                          color={
                            (summary?.paidBonuses || 0) > 0
                              ? "success.main"
                              : "grey.light"
                          }
                        >
                          {formatPrice(summary?.paidBonuses)}
                        </Typography>
                        <Typography fontSize="inherit" color="grey.light">
                          /
                        </Typography>
                        <Typography fontSize="inherit" color="grey.light">
                          {formatPrice(summary?.maxPaidBonuses)}
                        </Typography>
                      </Stack>
                      {!!summary?.validatedParticipants && (
                        <Typography
                          fontSize={typography.pxToRem(14)}
                          color="success.main"
                        >
                          {summary?.validatedParticipants}{" "}
                          {(summary?.validatedParticipants || 0) > 1
                            ? "adhérents"
                            : "adhérent"}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                )}
              {!!isPresident && status === "ACTIVABLE" && (
                <Stack spacing={1}>
                  <SidebarLabel>Prime max</SidebarLabel>
                  <SidebarBonus>+ {formatPrice(summary?.maxBonus)}</SidebarBonus>
                </Stack>
              )}
              {(!isPresident || status === "SUBMITTED") && (
                <Stack spacing={1}>
                  <SidebarLabel>Prime</SidebarLabel>
                  <SidebarBonus>+ {formatPrice(summary?.bonus)}</SidebarBonus>
                </Stack>
              )}
            </Stack>
          </SidebarCard>
        </Stack>
      )}

      {/* HEADER */}
      <PageHeader
        title={summary ? (title ? title(summary.name) : summary.name) : ""}
        level={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {!!isPresident && (
            <>
              {status === "ACTIVABLE" && !!summary?.maxBonus && (
                <>
                  <HeaderLabel>Jusqu&apos;à</HeaderLabel>
                  <AmountChip {...amountChipProps}>{summary?.maxBonus}</AmountChip>
                </>
              )}
              {status !== "ACTIVABLE" && !!summary?.bonus && (
                <>
                  <HeaderLabel>Prime</HeaderLabel>
                  <AmountChip {...amountChipProps}>{summary?.bonus}</AmountChip>
                </>
              )}
              {status === "ACTIVE" && <ActiveChip />}
              {status === "SUBMITTED" && <SubmittedChip />}
            </>
          )}
          {!isPresident && !!summary?.bonus && (
            <AmountChip {...amountChipProps}>{summary?.bonus}</AmountChip>
          )}
        </Stack>
      </PageHeader>

      {/* CONTENT */}
      {children}
    </>
  )
}

const HeaderLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.light,
  fontSize: theme.typography.pxToRem(14),
}))

export const ActiveChip = () => (
  <LogoCircleChip color="success.main" icon={<Check />}>
    Opération active
  </LogoCircleChip>
)
export const SubmittedChip = () => (
  <LogoCircleChip
    color="warning.main"
    icon={<Typography fontWeight={600}>!</Typography>}
  >
    En attente de validation
  </LogoCircleChip>
)

// SIDEBAR COMPONENTS

const SidebarLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.light,
  fontSize: theme.typography.pxToRem(12),
}))
const SidebarBonus = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  fontSize: theme.typography.pxToRem(23),
  fontWeight: 500,
}))

const Validity = ({ start, end }: { start: string; end: string }) => (
  <Typography
    color="common.electricBlue"
    fontWeight={500}
  >{`Du ${start} - Au ${end}`}</Typography>
)

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  FormControl as MuiFormControl,
  Radio,
  RadioGroup,
  type RadioGroupProps,
  styled,
} from "@mui/material"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"

export type FormRadioLineProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  radios: { label: string; value: string | number | boolean }[]
  label?: string
  required?: boolean
} & RadioGroupProps

export const FormRadioLine = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  radios,
  required = false,
  ...props
}: FormRadioLineProps<TFieldValues>) => {
  const conf = useFieldRoleConf(name)

  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControl
            error={!!error}
            variant="standard"
            sx={{ flexDirection: "row" }}
            required={required}
          >
            {!!label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}
            <RadioGroup
              {...(label && { "aria-labelledby": `${name}-label` })}
              row
              {...props}
              {...field}
              onChange={(...args) => {
                props.onChange?.(...args)
                field.onChange(...args)
              }}
              {...conf}
              value={field.value ?? ""} // Prevent MUI error on uncontrolled field
            >
              {radios.map(({ label: radioLabel, value }) => (
                <FormControlLabel
                  key={radioLabel}
                  label={radioLabel}
                  value={value}
                  control={
                    <Radio
                      color="success"
                      required={required}
                      disabled={!!conf.disabled}
                    />
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormHelperText error>{error?.message}</FormHelperText>
        </>
      )}
    />
  )
}

const FormControl = styled(MuiFormControl)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
})

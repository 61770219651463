import { FormHelpers } from "@pharmupp/p3-front-commons"
import { formatISO } from "date-fns"
import type { FormValues } from "../UserForm/UserForm.component"
import type { ApiUserModel } from "../UserForm/models/apiUser.model"

export const formToServer = (data: FormValues): ApiUserModel => ({
  ...FormHelpers.convertObjectValues(data, "", null),
  function:
    !data.isExternSpecificationVisible && data.type !== "HOLDER"
      ? data.function
      : null,
  externSpecification: data.isExternSpecificationVisible
    ? data.externSpecification
    : null,
  unactivateDate:
    data.programmedUnactivate && data.unactivateDate
      ? formatISO(new Date(data.unactivateDate), { representation: "date" })
      : null,
})

export const serverToForm = (formValues: ApiUserModel): FormValues => ({
  ...formValues,
  isUpdate: true,
})

import { type LinkProps, styled } from "@mui/material"
import { Link } from "@pharmupp/p3-front-commons"
import type * as React from "react"
import { ChevronRight } from "react-feather"

export const FrontLink = ({
  children,
  to,
  ...props
}: React.PropsWithChildren<{ to: string }> & LinkProps) => (
  <CustomLink to={to} {...props}>
    {children}
    <ChevronRight size="1.2em" />
  </CustomLink>
)

const CustomLink = styled<any>(Link)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gridArea: "backlink",
  fontFamily: theme.typography,
  fontWeight: 600,
  fontSize: "0.75rem",
  textDecoration: "none",
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.light,
  },
  "&:active,&:focus": {
    color: theme.palette.primary.main,
  },
  cursor: "pointer",
}))

import { Autocomplete, Checkbox, TextField } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import qs from "qs"
import { type ReactNode, useState } from "react"
import { ChevronDown } from "react-feather"
import { Controller } from "react-hook-form"
import { api } from "../../../../hooks"
import type { ReferentialCode } from "../../../../types"
import type { PaginatedApiResponse } from "../../../dataDisplay"

type FormSearchAsyncFieldProps<T extends ReferentialCode> = {
  name: string
  label: string
  endpoint: string
  queryParams?: Record<string, string | number | boolean>
  multiple?: boolean
  placeholder?: string
  required?: boolean
  optionLabelFormatter?: (option: T) => ReactNode
}

const PAGE_SIZE = 5

export const FormSearchAsyncField = <
  Option extends ReferentialCode = ReferentialCode,
>({
  name,
  label,
  placeholder,
  multiple = false,
  endpoint,
  queryParams,
  required,
  optionLabelFormatter,
}: FormSearchAsyncFieldProps<Option>) => {
  const [search, setSearch] = useState<string>("")
  const [open, setOpen] = useState(false)

  const { data: options, isRefetching: isLoading } = useQuery({
    queryKey: ["form", "asyncSearch", endpoint, search],
    queryFn: () => {
      const finalQueryParams = qs.stringify({
        ...queryParams,
        search,
        size: PAGE_SIZE,
      })
      return api.get<PaginatedApiResponse<Option>>(`${endpoint}?${finalQueryParams}`)
    },
    select: (data) => data.content || [],
    enabled: open,
  })

  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<Option, typeof multiple, boolean, false>
          id={name}
          {...field}
          multiple={multiple}
          clearOnBlur={!multiple}
          forcePopupIcon
          noOptionsText="Aucun résultat"
          loadingText="Chargement..."
          loading={isLoading}
          openOnFocus
          popupIcon={<ChevronDown />}
          ChipProps={{ variant: "outlined", color: "primary" }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={options || []}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : (option.label ?? "")
          }
          filterOptions={(options) => options}
          value={multiple ? (field.value ?? []) : (field.value ?? null)}
          onChange={(_event, newValue) => field.onChange(newValue ?? null)}
          isOptionEqualToValue={(option, currValue) =>
            option.code === currValue.code
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error?.message}
              required={required}
              fullWidth
            />
          )}
          onInputChange={(_event, newInputValue) => setSearch(newInputValue)}
          renderOption={({ key, ...optionProps }, option, { selected }) => (
            <li key={option.code} {...optionProps}>
              {!!multiple && <Checkbox checked={selected} />}
              {optionLabelFormatter ? optionLabelFormatter(option) : option.label}
            </li>
          )}
        />
      )}
    />
  )
}

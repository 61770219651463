import { Box, type BoxProps } from "@mui/material"

export type FormContainerProps = BoxProps

/**
 * - Enforce consistent padding for all forms
 * - Enforce consistent padding for FieldSection separator
 */
export const FormContainer = ({ children, sx, ...args }: FormContainerProps) => (
  <Box sx={{ overflowY: "auto", ...sx }} {...args}>
    {children}
  </Box>
)

import {
  FilterSearchAndSelect,
  type FilterSearchOptions,
  FilterToggle,
  ROLES,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"

export const PostListFilterSection = ({
  rubrics,
  statuses,
}: { rubrics: FilterSearchOptions; statuses: FilterSearchOptions }) => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)
  return (
    <>
      {!!isAdmin && (
        <FilterSearchAndSelect name="rubric" label="Rubrique" options={rubrics} />
      )}
      <FilterSearchAndSelect name="status" label="Statut" options={statuses} />
      {!!isAdmin && <FilterToggle name="onlyPresident" label="Info président" />}
    </>
  )
}

import { Box, Stack } from "@mui/material"
import type { ReactNode } from "react"
import type { RequireAllOrNone } from "type-fest"
import { BackLink } from "../../navigation"
import {
  PageSubtitle,
  PageTitle,
  type PageTitleLevel,
  getHeaderHeight,
} from "../PageHeader"
export type FullWidthHeaderProps = {
  title: string
  subtitle?: string
  chip?: ReactNode
  level?: PageTitleLevel
} & RequireAllOrNone<{ linkLabel: string; linkTo: string }, "linkLabel" | "linkTo">

export const FullWidthHeader = ({
  title,
  subtitle,
  chip,
  level = 1,
  ...props
}: FullWidthHeaderProps) => (
  <Box mb={6} height={getHeaderHeight} position="relative">
    {!!props.linkLabel && !!props.linkTo && (
      <BackLink
        to={props.linkTo}
        sx={{
          position: "absolute",
          left: (theme) => theme.spacing(-1),
          top: (theme) => theme.spacing(-4),
        }}
      >
        {props.linkLabel}
      </BackLink>
    )}
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2} minWidth="180px">
        <PageTitle level={level}>{title}</PageTitle>
        {chip}
      </Stack>
      {!!subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
    </Stack>
  </Box>
)

import {
  IconButton,
  InputBase,
  type InputBaseProps,
  alpha,
  inputBaseClasses,
  styled,
  useTheme,
} from "@mui/material"
import { type SyntheticEvent, useEffect, useState } from "react"
import { Search, X } from "react-feather"

export type SearchFieldProps = {
  defaultOpen?: boolean
  value?: string
  onSubmit: (value: string) => void
} & Omit<InputBaseProps, "value" | "onSubmit">

export const SearchField = ({
  placeholder,
  defaultOpen = false,
  value = "",
  onSubmit,
  ...props
}: SearchFieldProps) => {
  const theme = useTheme()

  const [currValue, setCurrValue] = useState<string>(value)
  const [focus, setFocus] = useState(defaultOpen)

  useEffect(() => setCurrValue(value), [value])

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    onSubmit(currValue)
  }
  const handleReset = () => {
    setCurrValue("")
    onSubmit("")
  }

  return (
    <form onSubmit={handleSubmit}>
      <SearchContainer className={focus || currValue ? "hasFocus" : undefined}>
        <SearchIconWrapper>
          <Search color={theme.palette.primary.main} size={ICON_SIZE} />
        </SearchIconWrapper>
        <StyledInputBase
          {...props}
          type="text"
          value={currValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => setCurrValue(e.target.value)}
          placeholder={placeholder || "Rechercher..."}
          inputProps={{
            "aria-label": "search",
          }}
          endAdornment={
            !!currValue && (
              <IconButton
                onClick={handleReset}
                color="primary"
                size="small"
                sx={{ marginRight: ICON_PADDING }}
              >
                <X size="16px" />
              </IconButton>
            )
          }
        />
      </SearchContainer>
    </form>
  )
}

const ICON_SIZE = "24px"
const ICON_PADDING = "6px"

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: `calc(${ICON_SIZE} + ${theme.spacing(2)})`,
  height: `calc(${ICON_SIZE} + ${theme.spacing(2)})`,
  display: "inline-flex",
  alignItems: "center",
  borderRadius: "100px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "white",
  "&:hover:not(.hasFocus)": {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  "&.hasFocus": {
    width: "270px",
    marginRight: "10px",
    borderColor: theme.palette.grey.light,
  },
  transition: theme.transitions.create(["width", "background", "border"]),
}))

const SearchIconWrapper = styled("div")({
  height: "100%",
  position: "absolute",
  left: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  backgroundColor: "transparent",
  paddingRight: theme.spacing(1),
  [`& .${inputBaseClasses.input}`]: {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(${ICON_SIZE} + ${theme.spacing(2)})`,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:focus": {
      cursor: "inherit",
    },
    "&::placeholder": {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))

import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  type TextFieldProps,
} from "@mui/material"
import { type SyntheticEvent, useEffect, useState } from "react"
import { ChevronDown } from "react-feather"
import {
  Controller,
  type FieldPath,
  type FieldValues,
  useFormContext,
} from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"
import { StyledSelectTextField } from "../FormSelectField"

type CodeValue = string | number
export type FormMultiSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
> = {
  name: FieldPath<TFieldValues>
  options: { label: string; code: CodeValue }[]
} & TextFieldProps

// still in use in p3-adherent,p3-labo, p3-user, p3-upp
/** @deprecated use FormSearchField instead */
export const FormMultiSelectField = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  options,
  ...props
}: FormMultiSelectFieldProps<TFieldValues>) => {
  const methods = useFormContext()
  const [sortedOpt, setSortedOpt] = useState<{ label: string; code: CodeValue }[]>(
    [],
  )
  const getLabelFromCode = (givenCode: CodeValue) =>
    sortedOpt.find(({ code }) => code === givenCode)?.label

  // RoleConf
  const fieldConf = useFieldRoleConf(name)

  const handleDelete = (items: CodeValue[], index: number) => {
    const itemsCopy = [...items]
    itemsCopy.splice(index, 1)
    methods.setValue(name, itemsCopy as TFieldValues[typeof name])
  }

  useEffect(() => {
    setSortedOpt(options.sort((a, b) => a.label.localeCompare(b.label)))
  }, [options])

  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <StyledSelectTextField
          // Link label to input
          id={name}
          {...field}
          {...props}
          {...fieldConf}
          select
          SelectProps={{
            ...props?.SelectProps,
            IconComponent: ChevronDown,
            multiple: true,
            renderValue: (selectedItems) => {
              if (Array.isArray(selectedItems)) {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedItems.map((selectCode: CodeValue, index) => (
                      <Chip
                        key={selectCode}
                        label={getLabelFromCode(selectCode)}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleDelete(selectedItems, index)}
                        onMouseDown={stopPropagation}
                      />
                    ))}
                  </Box>
                )
              }
            },
          }}
          // Important to have default to [] to avoid a MUI error
          value={
            Array.isArray(field.value)
              ? field.value
              : field.value
                ? [field.value]
                : []
          }
          error={!!error}
          helperText={error?.message}
        >
          {sortedOpt?.map(({ code, label }) => (
            <MenuItem key={code} value={code}>
              <Checkbox
                checked={
                  Array.isArray(field.value) && field.value.indexOf(code) > -1
                }
              />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </StyledSelectTextField>
      )}
    />
  )
}

const stopPropagation = (event: SyntheticEvent) => event.stopPropagation()

import {
  type ReferentialCode,
  api,
  useDialog,
  useMutation,
  useQuery,
  useQueryClient,
  useRoleConf,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import { getUpdateUserQueryKey } from "../UpdateUser"
import { serverToForm } from "../UpdateUser/userForm.adapter"
import { apiErrorHandlerConfig } from "../apiErrorHandlerConfig"
import { apiRoleConf } from "../apiRoleConf"
import { type UnhandledError, useApiErrorHandler } from "../useApiErrorHandler"
import type { ApiUserModel } from "./models/apiUser.model"

export const useUserFormApi = () => {
  const queryClient = useQueryClient()
  const toaster = useToaster()
  const dialog = useDialog()
  const { id } = useParams()
  const isUpdate = !!id
  const action = id ? "update" : "create"
  const apiConf = useRoleConf(apiRoleConf)
  const apiErrorHandler = useApiErrorHandler(apiErrorHandlerConfig)

  // Referential
  const referentialEndpoint = apiConf.referentialEndpoint(action)
  const { data: referential, isLoading: loading } = useQuery({
    queryKey: ["users", "form", id || "create", "referential", action],
    queryFn: () => api.get<UserFormReferential>(referentialEndpoint),
    enabled: !!referentialEndpoint,
  })

  // Send values
  const { mutateAsync: sendValues } = useMutation({
    mutationFn: (formValues: string) =>
      api<ApiUserModel>(`${apiConf.resourceEndpoint}/${id || ""}`, {
        method: isUpdate ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" },
        body: formValues,
      }).then(serverToForm),
    onMutate: () => toaster.info("Requête en cours..."),
    onSuccess() {
      toaster.success("Sauvegarde effectuée")
      queryClient.invalidateQueries({ queryKey: ["users", "form", id] })
    },
    onError() {
      toaster.error("Une erreur est survenue")
    },
  })

  const deleteAction = (onSuccess: () => void) => {
    dialog.delete({
      endpoint: `${apiConf.resourceEndpoint}/${id}`,
      onSuccess,
      onError: (error: unknown) =>
        apiErrorHandler.handleDeleteError(error as UnhandledError),
    })
  }

  const activate = (onError: (error: unknown) => void) =>
    dialog.activate<ApiUserModel>({
      endpoint: `${apiConf.resourceEndpoint}/${id}/activate`,
      onSuccess: (updatedValues: ApiUserModel) =>
        queryClient.setQueryData<ApiUserModel>(
          getUpdateUserQueryKey(id),
          updatedValues,
        ),
      onError,
    })

  const deactivate = (onError: (error: unknown) => void) =>
    dialog.deactivate<ApiUserModel>({
      endpoint: `${apiConf.resourceEndpoint}/${id}/deactivate`,
      onSuccess: (updatedValues: ApiUserModel) =>
        queryClient.setQueryData<ApiUserModel>(
          getUpdateUserQueryKey(id),
          updatedValues,
        ),
      onError,
    })

  return {
    referential,
    referentialLoading: loading,
    sendValues,
    deleteAction,
    activate,
    deactivate,
  }
}

export interface UserFormReferential {
  externSpecification: ReferentialCode<string>[]
  statuses: (ReferentialCode<string> & { color: string })[]
  type: (ReferentialCode<string> & { refs?: { multi: boolean; refs: string } })[]
  upps: { id: number; name: string }[]
}

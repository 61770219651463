import { Box, Divider, Stack, Typography, styled } from "@mui/material"
import {
  FormTextField,
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalTitle,
} from "@pharmupp/p3-front-commons"
import { FormProvider, useForm } from "react-hook-form"
import { type ApiCancelEventBody, useCancelEventApi } from "./useCancelEventApi"

interface CancelDialogProps {
  open: boolean
  onClose: () => void
}
export const CancelDialog = ({ open, onClose }: CancelDialogProps) => {
  const { cancelEvent } = useCancelEventApi()
  const formApi = useForm<ApiCancelEventBody>()

  const validateAction = formApi.handleSubmit(
    async function onValid(formData) {
      await cancelEvent(formData)
      onClose()
    },
    function onInvalid(err) {
      console.error(err)
    },
  )

  return (
    <Modal open={open}>
      <ModalTitle>Annulation</ModalTitle>

      <ModalContent>
        <FormProvider {...formApi}>
          <Box textAlign="center" width="420px">
            <Stack spacing={2} mx="auto" mb={2} textAlign="center" width="80%">
              <Text color="error">
                Cet évènement ne sera plus disponible sur l’Espace Adhérent.
              </Text>
              <Divider />
              <Text>
                Vous pouvez informer les adhérents inscrits de la raison de
                l’annulation :
              </Text>
            </Stack>
            <FormTextField
              name="cancellationReason"
              label="Raison de l'annulation"
              rows={3}
              multiline
              sx={{ width: "100%" }}
            />
          </Box>
        </FormProvider>
      </ModalContent>

      <ModalBasicActions
        validateText="Confirmer"
        validateAction={validateAction}
        cancelAction={onClose}
      />
    </Modal>
  )
}

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}))

import { Stack, Typography } from "@mui/material"

export const PharmacyOptionFormatter = (option: PharmacySearch) => (
  <Stack width="90%">
    <Stack flexDirection="row" gap={0.5}>
      <Typography color="primary.dark1" fontWeight="bold" whiteSpace="nowrap">
        {`${option.label} -`}
      </Typography>
      <Typography color="primary.dark1" noWrap>
        {option.city}
      </Typography>
    </Stack>
    <Typography variant="12px" color="grey.dark">
      {`ID : ${option.centralBusinessId} - CIP : ${option.cip}`}
    </Typography>
    <Typography variant="12px" color="primary.dark2">
      {`Destinataire : ${option.primaryHolder.firstName} ${option.primaryHolder.lastName}`}
    </Typography>
  </Stack>
)

export type PharmacySearch = {
  code: number
  label: string
  centralBusinessId: string
  cip: string
  city: string
  primaryHolder: {
    firstName: string
    lastName: string
  }
}

import { Box, Stack, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { ButtonLink, MarkdownDisplay } from "@pharmupp/p3-front-commons"
import { ChevronRight, Info } from "react-feather"
import { useParams } from "react-router-dom"
import {
  HistoryLayout,
  HistoryLine,
  LoyaltyCategoryDisplay,
  RatingCardGrid,
  RatingCardLabel,
  RatingCardLayout,
  RatingCardQuantity,
  RatingCardValue,
  TrackingRow,
  useLoyaltyCategoryApi,
} from "./commons"

const category = "CATEGORICAL"

export const CategoricalCategory = () => {
  const { year: yearStr = `${new Date().getFullYear()}`, adherentId } = useParams()
  const year = Number.parseInt(yearStr)
  const { loyaltyCategory } = useLoyaltyCategoryApi({
    categoryEnum: category,
    year,
    adherentId,
  })

  return (
    <LoyaltyCategoryDisplay loyaltyCategory={loyaltyCategory} currentYear={year}>
      {!!loyaltyCategory && (
        <>
          <RatingCardGrid>
            {loyaltyCategory.challenges?.map((challenge, index) => (
              <QualitativeCard
                key={index}
                tag={challenge.tag}
                quantity={challenge.quantity}
                numberField={challenge.numberField}
                label={challenge.formattedLabel}
                value={challenge.expectedPoint}
                active={challenge.unlocked}
                tooltip={challenge.tooltip}
              />
            ))}
          </RatingCardGrid>

          {/* TRACKING */}
          <TrackingRow
            category={category}
            totalEarnPoints={loyaltyCategory.totalEarnPoints}
            totalExpectedPoints={loyaltyCategory.totalExpectedPoints}
            totalRealized={
              loyaltyCategory.challenges?.filter(({ unlocked }) => !!unlocked)
                ?.length ?? 0
            }
            lastYearValue={loyaltyCategory.lastYearValue}
          />

          {/* HISTORY */}
          <HistoryLayout>
            {(loyaltyCategory.history || [])?.map((history) => (
              <HistoryLine
                key={history.historyDate}
                date={history.historyDate}
                title={history.label}
                bonus={history.pointDiff}
              />
            ))}
          </HistoryLayout>

          <Box mt={3} textAlign="center">
            <ButtonLink
              variant="text"
              to="/dashboard?menu%5Bitem%5D=pdm"
              endIcon={<ChevronRight />}
              sx={{ color: "grey.dark" }}
            >
              Détail des PDM catégorielles dans Mon Officine
            </ButtonLink>
          </Box>
        </>
      )}
    </LoyaltyCategoryDisplay>
  )
}

const QualitativeCard = ({
  active = false,
  quantity,
  tag,
  numberField,
  label,
  value,
  unit,
  tooltip,
}: {
  active?: boolean
  quantity?: string | null
  numberField?: string | null
  tag?: string | null
  label: string
  value: number
  unit?: string
  tooltip?: string | null
}) => {
  const { palette, typography } = useTheme()
  return (
    <RatingCardLayout active={active}>
      <RatingCardLabel>
        {/* QUANTITY */}
        {!!quantity && (
          <RatingCardQuantity active={active}>{quantity}</RatingCardQuantity>
        )}

        {/* LABEL */}
        <CardLabel>
          {!!tag && (
            <Tag color={active ? palette.loyalty.dark3 : palette.loyalty.dark2}>
              {tag}
            </Tag>
          )}
          <Box
            component="div"
            sx={{
              display: "inline",
              "& *": { display: "inline" },
              "& svg": { verticalAlign: "middle" },
              color: palette.common.darkBlue,
            }}
          >
            <Typography
              component="span"
              color="inherit"
              fontSize={typography.pxToRem(15)}
              fontWeight={600}
            >
              {numberField}&nbsp;
            </Typography>
            <Typography
              component="div"
              fontSize={typography.pxToRem(13)}
              sx={{
                "& p,*": {
                  color: `${palette.common.darkBlue}!important`,
                },
              }}
            >
              <MarkdownDisplay>{label}</MarkdownDisplay>
            </Typography>
            {!!tooltip && (
              <Tooltip title={tooltip}>
                <Info height={16} color={palette.grey.light} />
              </Tooltip>
            )}
          </Box>
        </CardLabel>
      </RatingCardLabel>
      <RatingCardValue active={active} value={value} unit={unit} />
    </RatingCardLayout>
  )
}
const CardLabel = styled(Stack)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  "& p": {
    margin: 0,
    lineHeight: theme.typography.pxToRem(17),
  },
}))

const Tag = styled(Typography)(({ theme: { typography } }) => ({
  textTransform: "uppercase",
  fontSize: typography.pxToRem(14),
  fontWeight: 600,
}))

import { Typography, styled } from "@mui/material"
import Stack from "@mui/material/Stack"
import { CreateUserButton } from "./CreateUserButton.component"

const StyledTypography = styled(Typography)({
  textAlign: "left",
  paddingTop: "0.75rem",
  paddingLeft: "1rem",
})

export const NoResultsLayout = ({
  message,
  onClick,
  allowCreate,
}: {
  message: string
  onClick: () => void
  allowCreate: boolean
}) => {
  return (
    <Stack direction="column" spacing={2}>
      <StyledTypography>{message}</StyledTypography>
      {!!allowCreate && <CreateUserButton onClick={onClick} />}
    </Stack>
  )
}

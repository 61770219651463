import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  Paper,
  type PaperProps,
  Popover,
  Stack,
  Typography,
  lighten,
  popoverClasses,
  styled,
  useTheme,
} from "@mui/material"
import {
  MyNegativeSegmentationBanner,
  useAdherentSummaryApi,
} from "@pharmupp/p3-adherent-domain"
import { useBonusOpSummaryApi } from "@pharmupp/p3-bonus-operation-domain"
import { ButtonLink } from "@pharmupp/p3-front-commons"
import { useLoyaltySummaryApi } from "@pharmupp/p3-loyalty-domain"
import { Shortcuts } from "@pharmupp/p3-solutions-domain/src/lib/Solution/AdherentSolutions/Services/Shortcuts"
import { type MouseEventHandler, useState } from "react"
import { ChevronRight, PieChart } from "react-feather"
import {
  BONUS_OP_COLOR,
  BonusOpIcon,
  ChallengesIcon,
  LoyaltyGauge,
} from "../routes/Adherent/MyUppAndMe/LoyaltyTracking"
import { CircleIconButton } from "./layouts"

const LoyaltyPopover = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [`& .${popoverClasses.paper}`]: {
    maxHeight: "calc(100% - 70px)",
  },
}))

export const AdhLoyaltyPopover = () => {
  const theme = useTheme()

  const { summary } = useAdherentSummaryApi()

  // Menu opening
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {/* BUTTON */}
      <Badge
        badgeContent={<b>!</b>}
        overlap="circular"
        color="error"
        invisible={!summary?.negativeSegmentation}
      >
        <CircleIconButton onClick={handleClick} active={open}>
          <PieChart size="22px" />
        </CircleIconButton>
      </Badge>

      {/* POPUP */}
      <LoyaltyPopover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        onClick={handleClose}
      >
        <Box width="800px" px={5} pt={3} pb={4}>
          {/* TITLE */}
          <Stack
            direction="row"
            alignItems="baseline"
            justifyContent="center"
            mb={2}
          >
            <Typography
              component="span"
              fontSize={theme.typography.pxToRem(21)}
              fontWeight={500}
            >
              Pharm UPP & Moi -&nbsp;
            </Typography>
            <Typography component="span" fontSize={theme.typography.pxToRem(16)}>
              Programme fidélité {new Date().getFullYear()}
            </Typography>
          </Stack>

          {!!summary?.negativeSegmentation && (
            <MyNegativeSegmentationBanner
              size="small"
              iconSize="large"
              onClick={(e) => e.stopPropagation()}
            />
          )}

          <LoyaltyCharts sx={{ mt: -0.5 }} />

          <Stack
            direction="row"
            alignItems="baseline"
            justifyContent="center"
            mt={1}
            mb={3}
          >
            <Typography
              component="span"
              fontSize={theme.typography.pxToRem(21)}
              fontWeight={500}
            >
              Services -&nbsp;
            </Typography>
            <Typography component="span" fontSize={theme.typography.pxToRem(16)}>
              Mes raccourcis
            </Typography>
          </Stack>

          <Shortcuts variant="centered" />
        </Box>
      </LoyaltyPopover>
    </>
  )
}

const LoyaltyPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  border: "none",
  padding: theme.spacing(7),
  paddingBottom: theme.spacing(2),
  backgroundColor: lighten(theme.palette.primary.main, 0.95),
  display: "flex",
  gap: theme.spacing(6),
  flexDirection: "column",
}))

const currentYear = new Date().getFullYear()

const LoyaltyCharts = (props: PaperProps) => {
  const { palette } = useTheme()
  const { summary: bonusOpSummary, isLoading: loadingBonusOp } =
    useBonusOpSummaryApi({ year: currentYear })
  const { summary: loyaltySummary, isLoading: loadingLoyalty } =
    useLoyaltySummaryApi({ year: currentYear })

  const loading = loadingBonusOp || loadingLoyalty
  return loading ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="200px"
    >
      <CircularProgress />
    </Box>
  ) : (
    <LoyaltyPaper {...props}>
      {/* BONUS OP */}
      <Stack direction="row" spacing={5}>
        <BonusOpIcon />
        <LoyaltyGauge
          title="Vie de l'UPP"
          description="Total primes validées"
          currentValue={bonusOpSummary?.validated}
          maxValue={bonusOpSummary?.total}
          unit="€"
          color={BONUS_OP_COLOR}
        />
      </Stack>

      {/* CHALLENGES */}
      <Stack direction="row" spacing={5}>
        <ChallengesIcon />
        <LoyaltyGauge
          title="Challenges"
          description="Total points cumulés"
          currentValue={loyaltySummary?.earnedPoint}
          maxValue={loyaltySummary?.expectedPoint}
          lastYearValue={loyaltySummary?.lastYearEarnedPoints}
          unit="pts"
          color={palette.loyalty.main}
        />
      </Stack>

      <Box>
        <Divider />

        <Box textAlign="center" mt={2}>
          <ButtonLink
            variant="text"
            endIcon={<ChevronRight />}
            sx={(theme) => ({ color: theme.palette.grey.dark })}
            to="/mon-upp-et-moi"
          >
            Détail de mon suivi fidélité
          </ButtonLink>
        </Box>
      </Box>
    </LoyaltyPaper>
  )
}

import { Yup } from "@pharmupp/p3-front-commons"

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

export const servicesSchema = Yup.object().shape({
  id: Yup.number(),
  privilegeCard: Yup.object().shape({
    subscribed: Yup.boolean().required(),
    subscriptionDate: Yup.date()
      .max(
        tomorrow,
        "La date d'adhésion ne peut pas être postérieure à la date du jour",
      )
      .when("subscribed", {
        is: true,
        then: (schema) => schema.required("La date d'adhésion et requise"),
      }),
    status: Yup.string().when("subscribed", {
      is: true,
      then: (schema) => schema.required("Le status est requis"),
    }),
  }),
  meSoigner: Yup.object().shape({
    subscribed: Yup.boolean().required(),
    site: Yup.string().when("subscribed", {
      is: true,
      then: (schema) =>
        schema
          .max(100, "Le site doit être inferieur à 100 caractères")
          .required("Le site est requis"),
    }),
    type: Yup.string().when("subscribed", {
      is: true,
      then: (schema) => schema.required("Le type est requis"),
    }),
  }),
})

import { useState } from "react"
import { getDocSigningLink } from "./utils"

export function useMyDocSigningLink(templateId: string) {
  const [isLoading, setIsLoading] = useState(false)

  return {
    async open() {
      setIsLoading(true)
      const link = await getDocSigningLink(
        `/api/documents/adherent/templates/${templateId}/signing-link`,
      )
      setIsLoading(false)
      window.open(link, "_blank")
    },
    isLoading,
  }
}

import { MyUnsignedDocsModal } from "@pharmupp/p3-document-domain"
import { ROLES, useRoleCheck } from "@pharmupp/p3-front-commons"

export const AdhGlobalModals = () => {
  const isAdhHolder = useRoleCheck(ROLES.ADHERENT_HOLDER)

  if (!isAdhHolder) return null

  return <MyUnsignedDocsModal />
}

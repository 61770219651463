import {
  Box,
  IconButton,
  type ModalProps,
  Modal as MuiModal,
  Paper,
  alpha,
  styled,
} from "@mui/material"
import type * as React from "react"
import { X } from "react-feather"

export type FullScreenModalProps = Omit<ModalProps, "onClose"> & {
  onClose: () => void
}

export const FullScreenModal: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<FullScreenModalProps>>
> = ({ open, onClose, children }) => (
  <Modal open={open} onClose={onClose}>
    <ModalPaper>
      {children}
      <CloseButton onClick={onClose} size="small">
        <X />
      </CloseButton>
    </ModalPaper>
  </Modal>
)

const Modal = styled(MuiModal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const PAPER_PADDING = "0.5rem"
const BTN_SIZE = "34px"
const BTN_MARGIN = "0.5rem"

const ModalPaper = styled(Paper)({
  width: `calc(100vw - (${BTN_SIZE} + ${BTN_MARGIN}) * 2)`,
  height: `calc(100vh - (${BTN_SIZE} + ${BTN_MARGIN}) * 2)`,
  padding: PAPER_PADDING,
  boxSizing: "border-box",
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: BTN_MARGIN,
  right: BTN_MARGIN,
  color: theme.palette.getContrastText("#FFF"),
  backgroundColor: "#FFF",
  "&:hover": {
    backgroundColor: alpha("#FFF", 0.8),
  },
}))

export type FullScreenPictureModalProps = Omit<FullScreenModalProps, "children"> & {
  imageSrc: string
}

export const FullScreenPictureModal = ({
  open,
  onClose,
  imageSrc,
}: FullScreenPictureModalProps) => (
  <FullScreenModal open={open} onClose={onClose}>
    <Box textAlign="center" height="100%" width="100%">
      <FullScreenPicture src={imageSrc} component="img" />
    </Box>
  </FullScreenModal>
)

export const FullScreenPicture = styled(Box)({
  height: "100%",
}) as typeof Box

import {
  Box,
  Container,
  type ContainerProps,
  Fab,
  darken,
  styled,
} from "@mui/material"
import { useEffect, useState } from "react"
import { ArrowUpIcon } from "./ArrowUpIcon"

export const MainContainer = ({ children, ...props }: ContainerProps) => {
  const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false)

  useEffect(() => {
    const onScroll = () => setIsScrollBtnVisible(window.scrollY >= 200)

    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <Wrapper {...props}>
      <GridAreas> {children}</GridAreas>
      {!!isScrollBtnVisible && (
        <ScrollTopBtn
          color="primary"
          sx={{ boxShadow: 0 }}
          onClick={() => document.body.scrollIntoView({ behavior: "smooth" })}
        >
          <ArrowUpIcon />
        </ScrollTopBtn>
      )}
    </Wrapper>
  )
}

const GridAreas = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `"backlink header header header"
  "sidebar main main main"
  ". main main main"`,
  rowGap: theme.spacing(6),
  gridTemplateColumns: "20% 1fr 1fr 1fr",
  columnGap: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    columnGap: theme.spacing(3),
  },
}))

const Wrapper = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(4),
  marginBottom: "130px",
  position: "relative",
}))

Wrapper.defaultProps = {
  maxWidth: "lg",
}

const ScrollTopBtn = styled(Fab)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(7),
  bottom: theme.spacing(-7),
  width: "50px",
  height: "50px",
  backgroundColor: "#CBE3E3",
  "&:hover": {
    backgroundColor: darken("#CBE3E3", 0.03),
  },
}))

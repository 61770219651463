import type { SVGProps } from "react"

export const PillsIcon = ({
  width = "25px",
  height = "29px",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Tableau-de-bord"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mon-Officine-Dashboard-Plus-de-categories---Desktop-Copy-2"
        transform="translate(-543.000000, -959.000000)"
        fill="#C3CBD2"
        fillRule="nonzero"
      >
        <g id="Group-9" transform="translate(111.000000, 308.000000)">
          <g id="Group-8" transform="translate(0.000000, 430.000000)">
            <g id="Group-5-Copy-3" transform="translate(409.000000, 172.000000)">
              <g id="Icon-PIlls" transform="translate(23.000000, 49.000000)">
                <path
                  d="M5.97820851,0 C2.68019999,0 0,2.70871737 0,6.04188354 L0,22.9581845 C0,26.2913507 2.68028415,29 5.97820851,29 C7.35336057,29 8.61178461,28.5242514 9.61512384,27.7411857 C11.9317585,28.0325475 14.3040221,26.9405233 15.5436788,24.783427 L23.9010518,10.2331969 C25.5509818,7.36176325 24.575962,3.66977419 21.7215115,2.01394984 C20.6510979,1.39302209 19.4568027,1.15180533 18.3087948,1.2335547 C16.3958691,1.36976962 14.5821664,2.42228842 13.5508865,4.216573 L11.9568378,6.99827966 L11.9568378,6.0418155 C11.9568378,2.70864934 9.27655366,0 5.97829266,0 L5.97820851,0 Z M5.97820851,1.61132657 C8.42108115,1.61132657 10.362705,3.57341336 10.362705,6.04188354 L10.362705,9.76746707 L8.10817935,13.6947024 L1.59413283,13.6947024 L1.59413283,6.04196859 C1.59413283,3.57358346 3.5356725,1.61132657 5.97829266,1.61132657 L5.97820851,1.61132657 Z M18.6323006,2.80703434 C18.8436818,2.80271545 19.0557195,2.8176655 19.2674374,2.84457594 C19.8328824,2.9170005 20.3953314,3.10437596 20.9240159,3.41102113 C23.0388382,4.63794198 23.7498945,7.29369066 22.5309409,9.4149897 L18.7444756,15.9979737 L11.1468867,11.6050086 L14.9333519,5.0223647 C15.7331872,3.63036321 17.1537091,2.83736376 18.6323763,2.80708622 L18.6323006,2.80703434 Z M10.3499129,13.0027415 L17.9471652,17.3953665 L14.1610366,23.9783506 C12.9420829,26.0995645 10.2847741,26.8158495 8.17028842,25.5892689 C6.05546613,24.3626882 5.34440984,21.7069396 6.56336348,19.5853003 L10.3499129,13.0027415 Z M1.59404867,15.306046 L7.18630569,15.306046 L5.19376589,18.7674665 C3.54383588,21.6385599 4.51852751,25.3308892 7.37330617,26.9867135 C7.43478152,27.0222616 7.49757481,27.0541551 7.56003734,27.0873779 C7.07184994,27.2803972 6.53826738,27.3893708 5.97810752,27.3893708 C3.53557151,27.3893708 1.59394768,25.427284 1.59394768,22.9588139 L1.59404867,15.306046 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

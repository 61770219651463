import { Alert, Snackbar } from "@mui/material"
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

const Toaster = ({ open = false, type, message, onClose }: ToasterConf) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    onClose={onClose}
  >
    <Alert severity={type} onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
)

interface ToasterConf {
  open: boolean
  type: "success" | "info" | "warning" | "error"
  message: string
  onClose: () => void
}

interface ToasterContextValue {
  openToaster: (toasterConf: Pick<ToasterConf, "type" | "message">) => void
}
const ToasterContext = createContext<ToasterContextValue>(undefined!)

export const ToasterProvider = ({ children }: { children?: ReactNode }) => {
  const [snackPack, setSnackPack] = useState<ToasterConf[]>([])
  const [toaster, setToaster] = useState<ToasterConf>({
    open: false,
    type: "info",
    message: "",
    onClose: () => undefined,
  })

  const openToaster = useCallback<ToasterContextValue["openToaster"]>(
    (toasterConf) => {
      // @ts-ignore TODO: patch types
      setSnackPack((actualStack) => [...actualStack, toasterConf])
    },
    [],
  )
  const closeToaster = useCallback(() => {
    if (toaster) {
      setToaster((toasterConf) => ({ ...toasterConf, open: false }))
    }
  }, [toaster])

  useEffect(() => {
    if (snackPack.length && !toaster?.open) {
      // Set a new snack when we don't have an active one
      setToaster({ ...snackPack[0], open: true })
      setSnackPack((prev) => prev.slice(1))
    } else if (snackPack.length && toaster?.open) {
      // Close an active snack when a new one is added
      closeToaster()
    }
  }, [snackPack, toaster, closeToaster])

  return (
    <ToasterContext.Provider value={{ openToaster }}>
      {children}
      {!!toaster && (
        <Toaster
          // Use a boolean prop instead ov evaluating current toaster obj
          // to avoid an empty toaster rendering when resetting obj
          open={toaster.open}
          type={toaster.type}
          message={toaster.message}
          onClose={closeToaster}
        />
      )}
    </ToasterContext.Provider>
  )
}

export const useToaster = () => {
  const ctx = useContext(ToasterContext)

  return useMemo(
    () => ({
      info: (message: string) => ctx.openToaster({ type: "info", message }),
      success: (message: string) => ctx.openToaster({ type: "success", message }),
      warn: (message: string) => ctx.openToaster({ type: "warning", message }),
      error: (message: string) => ctx.openToaster({ type: "error", message }),
    }),
    [ctx],
  )
}

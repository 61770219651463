export type GeneralInfosFields = typeof fields
export const fields = {
  email: "email",
  firstName: "firstName",
  lastName: "lastName",
  phone: "phone",
  status: "status",
  type: "type",
  function: "function",
  externSpecification: "externSpecification",
  programmedUnactivate: "programmedUnactivate",
  unactivateDate: "unactivateDate",
} as const

import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { formatPercentage } from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import type { Icon as FeatherIcon } from "react-feather"
import { NoDataWarning, StatCardTitle } from "."
import { useEvolutionUI } from "./useEvolutionUI"

export const StatCard = ({
  Icon,
  secondaryIcons = [],
  title,
  subtitle,
  mainStat,
  evolution,
  franceEvolution,
  hasValue = true,
}: {
  Icon: FeatherIcon
  secondaryIcons?: FeatherIcon[]
  title: string
  subtitle: string
  mainStat: ReactNode
  evolution?: number | null
  franceEvolution?: number | null
  hasValue?: boolean
}) => {
  const { palette, typography } = useTheme()
  const evolutionUI = useEvolutionUI(evolution)
  const GrowthIcon = evolutionUI.Icon

  return (
    <Card component={Box} px={3} py={2}>
      <Stack justifyContent="space-between" height="100%">
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* ICONS */}
            <Stack direction="row" alignItems="flex-end" spacing={0.5}>
              <Icon size={typography.pxToRem(30)} color={palette.tertiary.dark2} />
              {secondaryIcons.map((SecondaryIcon, index) => (
                <SecondaryIcon
                  key={index}
                  color={palette.tertiary.dark4}
                  size={typography.pxToRem(24)}
                />
              ))}
            </Stack>

            {/* TITLE */}
            <StatCardTitle title={title} subtitle={subtitle} />
          </Stack>

          {/* MAIN STAT */}
          {hasValue ? (
            <Stack direction="row" spacing={1} mb={2}>
              <Typography
                fontSize={typography.pxToRem(19)}
                fontWeight={600}
                color={palette.common.darkBlue}
              >
                {mainStat}
              </Typography>

              {/* GROWTH */}
              {evolution !== undefined && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  color={evolutionUI?.color}
                >
                  <GrowthIcon size="12px" />
                  <Typography variant="12px" noWrap>
                    {formatPercentage(evolution, {
                      maximumFractionDigits: 1,
                      signDisplay: "never",
                    })}
                  </Typography>
                </Stack>
              )}
            </Stack>
          ) : (
            <NoDataWarning />
          )}
        </Stack>

        {!!hasValue && franceEvolution != null && (
          <Stack spacing={1} mt={2}>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontSize={typography.pxToRem(9)}
                color={palette.grey.light}
              >
                Evol. France
              </Typography>
              <Typography variant="11px" color={palette.grey.light}>
                {`+${formatPercentage(franceEvolution)}`}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}

export const ValueDetail = styled("span")(({ theme: { spacing, typography } }) => ({
  marginLeft: spacing(0.5),
  fontSize: typography.pxToRem(14),
  fontWeight: 500,
}))

import {
  FilterSearchAndSelect,
  FilterToggle,
  ROLES,
  useRoleCheck,
  useSectorsList,
  useUppsList,
} from "@pharmupp/p3-front-commons"
import { minEntryDateOptions, statusOptions } from "./common"

/** Default adherent filters shared on multiple lists */
export const AdherentDefaultFilters = () => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)
  const isPresident = useRoleCheck(ROLES.UPP_PRESIDENT)
  const { uppsList } = useUppsList({ enabled: isAdmin })
  const { sectorsList } = useSectorsList({ enabled: isAdmin || isPresident })

  return (
    <>
      <FilterToggle name="isUltra" label="Ultra" />
      <FilterToggle name="isNegativeSegmentation" label="Segmentation négative" />
      <FilterSearchAndSelect
        name="minEntryDate"
        label="Ancienneté"
        options={minEntryDateOptions}
        sorted={false}
      />
      {uppsList?.length && (
        <FilterSearchAndSelect
          name="uppIds"
          label="Upp"
          options={uppsList}
          multiple
        />
      )}
      {sectorsList?.length && (
        <FilterSearchAndSelect
          name="sectorIds"
          label="Secteur CDO"
          options={sectorsList}
          multiple
        />
      )}
      <FilterSearchAndSelect
        name="adherentStatus"
        label="Statut adhérent"
        options={statusOptions}
      />
    </>
  )
}

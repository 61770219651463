import {
  Box,
  Link as MuiLink,
  Tabs as MuiTabs,
  Paper,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material"
import { type ReactNode, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { SideNavCtx } from "./SideNavContext"

/** A navigation aware sidebar (links are selected based on current route) */
export const SideNav = ({
  children,
  externalLinks,
}: {
  children: ReactNode
  externalLinks?: ExternalLink[]
}) => {
  const [links, setLinks] = useState<string[]>([])
  const registerLink = useCallback(
    (newLink: string) => setLinks((existingLinks) => [...existingLinks, newLink]),
    [],
  )
  const [activeLinkIndex, setActiveLinkIndex] = useState<number>(0)
  const setActiveLink = useCallback(
    (activeLink: string) =>
      setActiveLinkIndex(Math.max(links.indexOf(activeLink), 0)),
    [links],
  )

  return (
    <Box gridArea="sidebar">
      <Paper>
        <SideNavCtx.Provider value={{ setActiveLink, registerLink }}>
          <Tabs
            orientation="vertical"
            textColor="primary"
            indicatorColor="secondary"
            value={activeLinkIndex}
          >
            {children}
          </Tabs>
        </SideNavCtx.Provider>
      </Paper>

      {!!externalLinks && (
        <Stack mt={4} spacing={0.5}>
          <Typography
            fontSize={(theme) => theme.typography.pxToRem(12)}
            color="grey.dark"
            textTransform="uppercase"
          >
            Raccourcis
          </Typography>
          <Paper>
            <Tabs
              orientation="vertical"
              value={false}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              {externalLinks.map(({ label, href, to, target }, index) => (
                <Tab
                  key={label}
                  label={label}
                  value={index}
                  {...(to !== undefined
                    ? { to, component: Link }
                    : { href, component: MuiLink, target: target ?? "_blank" })}
                />
              ))}
            </Tabs>
          </Paper>
        </Stack>
      )}
    </Box>
  )
}

const TAB_HEIGHT_IN_PX = 56
const INDICATOR_HEIGHT_IN_PX = 20

const Tabs = styled(MuiTabs)(({ theme: { spacing, palette } }) => ({
  "& .MuiTabs-flexContainerVertical": {
    display: "flex",
    gap: 0,
    flexDirection: "column",
    justifySelf: "start",
    width: "100%",
    "& .MuiTab-root": {
      minHeight: `${TAB_HEIGHT_IN_PX}px`,
      flexBasis: "100%",
      alignItems: "flex-start",
      textAlign: "left",
      paddingLeft: spacing(2),
      "&:not(:first-of-type)": {
        borderTop: `solid 1px ${palette.grey.extraLight}`,
      },
      "&:hover": {
        color: "primary.main",
      },
      "&.Mui-selected": {
        paddingLeft: "29px",
      },
    },
    "& + .MuiTabs-indicator": {
      minWidth: "4px",
    },
  },
  "& .MuiTabs-indicator": {
    height: `${INDICATOR_HEIGHT_IN_PX}px!important`,
    marginTop: `${(TAB_HEIGHT_IN_PX - INDICATOR_HEIGHT_IN_PX) / 2}px`,
  },
}))

type ExternalLink = {
  label: string
} & (
  | { to: string; href?: never; target?: never }
  | { to?: never; href: string; target?: string }
)

import { Button, type ButtonProps, Stack, Typography, styled } from "@mui/material"
import type { Icon } from "react-feather"
import { NumberFormat } from "../numberDisplays"

export type AmountChipProps = (Omit<
  ButtonProps,
  "variant" | "color" | "children"
> & {
  unit: string
  color: string
  hasUnderline?: boolean
  prefix?: string
  PrefixIcon?: Icon
  prefixColor?: string
  borderColor?: string
  variant?: "outlined" | "contained"
}) &
  (
    | { children: number }
    | {
        labels: [string, string]
        children: number[]
      }
  )

export const AmountChip = ({
  variant = "contained",
  color,
  hasUnderline = true,
  prefix,
  PrefixIcon,
  prefixColor,
  borderColor,
  unit,
  ...props
}: AmountChipProps) => {
  const fontColor = variant === "contained" ? "white" : color
  const bgColor = variant === "contained" ? color : "transparent"
  const valueDisplayProps = {
    color: fontColor,
    prefix,
    prefixColor,
    unit,
    hasUnderline,
  }

  return (
    <Wrapper
      {...props}
      variant={variant || "contained"}
      bgColor={bgColor}
      borderColor={borderColor || color}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        {!!PrefixIcon && <PrefixIcon color={fontColor} height={20} />}
        {"labels" in props ? (
          <Stack direction="row" alignItems="baseline" spacing={2}>
            <Label>{props.labels[0]}</Label>
            <ValueDisplay {...valueDisplayProps}>{props.children[0]}</ValueDisplay>
            <Label>{props.labels[1]}</Label>
            <ValueDisplay {...valueDisplayProps}>{props.children[1]}</ValueDisplay>
          </Stack>
        ) : (
          <ValueDisplay {...valueDisplayProps}>{props.children}</ValueDisplay>
        )}
      </Stack>
    </Wrapper>
  )
}

const Wrapper = styled(Button, {
  shouldForwardProp: (prop) => !(prop === "borderColor" || prop === "bgColor"),
})<{ borderColor: string; bgColor: string }>(({ theme, borderColor, bgColor }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  border: `1px solid ${borderColor}`,
  backgroundColor: bgColor,
  pointerEvents: "none",
  whiteSpace: "nowrap",
}))

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
}))

const ValueDisplay = ({
  color,
  hasUnderline,
  prefix,
  prefixColor,
  unit,
  children,
}: Pick<
  AmountChipProps,
  "color" | "prefix" | "prefixColor" | "unit" | "hasUnderline"
> & {
  children: number
}) => (
  <Value
    color={color}
    hasPrefix={!!prefix}
    prefixColor={prefixColor ?? color}
    hasUnderline={hasUnderline}
  >
    <NumberFormat
      prefix={prefix ? `${prefix} ` : undefined}
      suffix={unit}
      value={children}
    />
  </Value>
)

const Value = styled(Typography, {
  shouldForwardProp: (prop) =>
    !(prop === "prefixColor" || prop === "hasPrefix" || prop === "hasUnderline"),
})<{ hasPrefix: boolean; prefixColor: string; hasUnderline?: boolean }>(
  ({ theme, color, hasPrefix, prefixColor, hasUnderline }) => ({
    ...(!!hasUnderline && {
      borderBottom: `1px solid ${color}`,
      paddingBottom: theme.spacing(0.5),
    }),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(16),
    ...(hasPrefix && {
      "&::first-letter": {
        color: prefixColor,
      },
    }),
  }),
)

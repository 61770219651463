import {
  Box,
  Button,
  Stack,
  TableCell,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  Chip,
  type ColumnRenderDefinition,
  NumberFormat,
} from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import { ArrowRight, Check, Minus, RefreshCw } from "react-feather"
import type { OperationType } from "../../operationMarketing.model"
import type {
  ApiListAdhMarketingOperation,
  OperationState,
  OperationStateEnum,
} from "../model"
import { DangerCircle, SuccessCircle, WarnCircle } from "./icons"

export const marketingOpColumns = {
  type: {
    renderHeader: ({ width }) => <TableCell width={width}>Type</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Type type={row.type} />
      </TableCell>
    ),
  },
  divider: {
    renderHeader: ({ width }) => <TableCell align="center" width={width} />,
    renderRow: () => (
      <TableCell align="center" sx={{ position: "relative" }}>
        <Separator />
      </TableCell>
    ),
  },
  operation: {
    renderHeader: ({ width }) => <TableCell width={width}>Opération</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack
          fontSize={({ typography }) => typography.pxToRem(12)}
          color="common.darkBlue"
          spacing={1}
        >
          <Typography fontSize="inherit" fontWeight={600}>
            {row.name}
          </Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography
              fontSize="inherit"
              color={row.state === "OUTDATED" ? "error.main" : "inherit"}
            >
              {row.validityStartDate}
            </Typography>
            <ArrowRight size="13px" style={{ marginTop: "-2px" }} />
            <Typography
              fontSize="inherit"
              color={row.state === "OUTDATED" ? "error.main" : "inherit"}
            >
              {row.validityEndDate}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
    ),
  },
  points: {
    renderHeader: ({ width }) => <TableCell width={width}>Points</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Points state={row.state} nbLoyaltyPoints={row.nbLoyaltyPoints} />
      </TableCell>
    ),
  },
  participation: {
    renderHeader: ({ width }) => <TableCell width={width}>Participation</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        {row.registered ? (
          <SuccessStatus label="Inscrit" />
        ) : (
          <SubscriptionState state={row.state} />
        )}
      </TableCell>
    ),
  },
  justification: {
    renderHeader: ({ width }) => <TableCell width={width}>Justification</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Justification state={row.state} />
      </TableCell>
    ),
  },
  status: {
    renderHeader: ({ width }) => (
      <TableCell align="right" width={width}>
        Statut
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <Cta
          state={row.state}
          kitOrderStartDate={row.kitOrderStartDate}
          kitOrderEndDate={row.kitOrderEndDate}
          validityStartDate={row.validityStartDate}
          validityEndDate={row.validityEndDate}
        />
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ApiListAdhMarketingOperation>>

const TypeChip = styled(Chip)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(9),
}))

const Type = ({ type }: { type: OperationType }) => {
  const { label, color } = useOpeType(type)
  return <TypeChip label={label} fontColor={color} size="small" />
}

const useOpeType = (type: keyof typeof OperationType) => {
  switch (type) {
    case "DISPLAY_BASIN":
      return { label: "Operation vasque", color: "#5981c7" }
    case "PROMOTIONAL":
      return { label: "Offre du moment", color: "#e29b00" }
    case "BUSINESS":
      return { label: "Opération  métier", color: "#339ca9" }
  }
}

const Separator = styled("div")(({ theme: { palette, spacing } }) => ({
  position: "absolute",
  top: spacing(2),
  bottom: spacing(2),
  width: "1px",
  backgroundColor: palette.grey.extraLight,
}))

const SuccessStatus = ({ label }: { label: string }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <SuccessCircle />
    <Typography
      color="success.main"
      fontSize={({ typography }) => typography.pxToRem(12)}
    >
      {label}
    </Typography>
  </Box>
)

const WarnStatus = ({ label }: { label: string }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <WarnCircle />
    <Typography
      color="grey.light"
      fontSize={({ typography }) => typography.pxToRem(12)}
    >
      {label}
    </Typography>
  </Box>
)

const DangerStatus = ({ icon, label }: { icon?: ReactNode; label: string }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <DangerCircle icon={icon} />
    <Typography
      color="grey.light"
      fontSize={({ typography }) => typography.pxToRem(12)}
    >
      {label}
    </Typography>
  </Box>
)

const SubscriptionState = ({ state }: { state: OperationStateEnum }) => {
  switch (state) {
    case "REGISTRABLE":
    case "UPCOMING":
      return <WarnStatus label="Non inscrit" />
    case "WAITING":
      return <WarnStatus label="À venir" />
    case "REVIEWED":
      return <WarnStatus label="Validation en cours" />
    case "REJECTED":
      return <DangerStatus label="Justificatif refusé" />
    case "VALIDATED":
    case "STARTED":
      return <SuccessStatus label="Inscrit" />
    case "EXPIRED":
      return <DangerStatus icon={<Minus />} label="Non participé" />
    case "OUTDATED":
      return <DangerStatus icon={<Minus />} label="Inscription clôturée" />
    default:
      return null
  }
}

const JustifyButton = ({
  label,
  disabled,
}: {
  label: string
  disabled?: boolean
}) => (
  <Button variant="outlined" size="small" color="primary" disabled={disabled}>
    {label}
  </Button>
)

const SubscribeButton = ({
  label,
  disabled = false,
}: {
  label: string
  disabled?: boolean
}) => (
  <Button variant="contained" size="small" color="primary" disabled={disabled}>
    {label}
  </Button>
)

const ValidatedButton = () => (
  <Button variant="contained" size="small" color="success" sx={{ color: "white" }}>
    Validée
  </Button>
)

export const MissedBonus = ({ bonus }: { bonus: number }) => (
  <Button
    variant="outlined"
    size="small"
    disabled
    sx={{
      position: "relative",
      overflow: "hidden",
    }}
  >
    <NumberFormat value={bonus} suffix=" pts" />
    <Box
      bgcolor="#c9d0d6"
      width="100%"
      height="1px"
      position="absolute"
      top="50%"
      left="0"
      right="0"
      sx={{ transform: "rotate(-15deg)", transformOrigin: "center" }}
    />
  </Button>
)

const JustificationLabel = styled("span")(({ theme }) => ({
  color: "inherit",
  fontSize: theme.typography.pxToRem(12),
}))

const Justification = ({ state }: { state: OperationState }) => {
  switch (state) {
    case "REJECTED":
      return (
        <Box display="flex" alignItems="center" gap={0.5} color="error.main">
          <DangerCircle />
          <JustificationLabel>Justificatifs refusés</JustificationLabel>
        </Box>
      )
    case "REVIEWED":
      return (
        <Box display="flex" alignItems="center" gap={0.5} color="success.main">
          <SuccessCircle variant="outlined" icon={<RefreshCw />} />
          <JustificationLabel>Justificatifs transmis</JustificationLabel>
        </Box>
      )
    case "VALIDATED":
      return (
        <Box display="flex" alignItems="center" gap={0.5} color="success.main">
          <SuccessCircle variant="outlined" icon={<Check />} />
          <JustificationLabel>Justificatifs validés</JustificationLabel>
        </Box>
      )
    default:
      return null
  }
}

const Cta = ({
  state,
  kitOrderStartDate,
  kitOrderEndDate,
  validityStartDate,
  validityEndDate,
}: Pick<
  ApiListAdhMarketingOperation,
  | "state"
  | "kitOrderStartDate"
  | "kitOrderEndDate"
  | "validityStartDate"
  | "validityEndDate"
>) => {
  const { typography } = useTheme()

  switch (state) {
    case "REGISTRABLE":
      return (
        <Stack spacing={0.5}>
          <SubscribeButton label="S'inscrire" />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            Avant le {kitOrderEndDate}
          </Typography>
        </Stack>
      )
    case "UPCOMING":
      return (
        <Stack spacing={0.5}>
          <SubscribeButton label="S'inscrire" disabled />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            À partir du {kitOrderStartDate}
          </Typography>
        </Stack>
      )
    case "WAITING":
      return (
        <Stack spacing={0.5}>
          <JustifyButton label="Justifier" disabled />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            À partir du {validityStartDate}
          </Typography>
        </Stack>
      )
    case "REVIEWED":
      return (
        <Stack spacing={0.5}>
          <JustifyButton label="Justifier" disabled />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            Jusqu’au {validityEndDate}
          </Typography>
        </Stack>
      )

    case "STARTED":
      return (
        <Stack spacing={0.5}>
          <JustifyButton label="Justifier" />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            Jusqu’au {validityEndDate}
          </Typography>
        </Stack>
      )
    case "REJECTED":
      return (
        <Stack spacing={0.5}>
          <JustifyButton label="Justifier à nouveau" />
          <Typography color="grey.light" fontSize={typography.pxToRem(10)}>
            Jusqu’au {validityEndDate}
          </Typography>
        </Stack>
      )
    case "VALIDATED":
      return <ValidatedButton />
    default:
      return null
  }
}

const Points = ({
  state,
  nbLoyaltyPoints,
}: Pick<ApiListAdhMarketingOperation, "state" | "nbLoyaltyPoints">) => {
  switch (state) {
    case "EXPIRED":
    case "OUTDATED":
    case "REJECTED":
      return <MissedBonus bonus={nbLoyaltyPoints || 0} />
    case "VALIDATED":
      return (
        <Button
          variant="outlined"
          color="success"
          size="small"
          sx={{ pointerEvents: "none" }}
        >
          <NumberFormat value={nbLoyaltyPoints || 0} prefix="+ " suffix=" pts" />
        </Button>
      )
    default:
      return (
        <Button variant="outlined" size="small" sx={{ pointerEvents: "none" }}>
          <NumberFormat value={nbLoyaltyPoints || 0} suffix=" pts" />
        </Button>
      )
  }
}

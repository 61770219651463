import { Paper, Stack, styled } from "@mui/material"
import { AdherentDefaultFilters } from "@pharmupp/p3-adherent-domain"
import {
  FilterSearchAndSelect,
  PageHeader,
  type ReferentialCode,
  Table,
} from "@pharmupp/p3-front-commons"
import { FileText } from "react-feather"
import { useParams } from "react-router-dom"
import { useTemplateSummaryApi } from "../../Template/useTemplateSummaryApi"
import { AddSignatoryButton } from "./AddSignatoryButton"
import { SummaryBanner } from "./SummaryBanner"
import { type ExtraRowApi, columns } from "./columns"
import type { ApiSignatory, SignatoryStatus } from "./types"

export const SignatoriesList = () => {
  const templateId = useParams().templateId!

  return (
    <>
      <PageHeader title={<PageTitle templateId={templateId} />}>
        <AddSignatoryButton templateId={templateId} />
      </PageHeader>

      <Stack gridArea="main" gap={2}>
        <SummaryBanner templateId={templateId} />

        <Paper>
          <Table<ApiSignatory, ApiSignatory, ExtraRowApi>
            queryConfig={{
              queryKey: ["documents", "templates", templateId, "signatories"],
              resourceEndpoint: `/api/documents/templates/${templateId}/signatories`,
            }}
            FilterSectionElement={
              <FilterSearchAndSelect
                label="Statut"
                name="status"
                options={statusOptions}
              />
            }
            HiddenFilterSectionElement={<AdherentDefaultFilters />}
            columns={[
              { renderDefinition: columns.pharmacy, width: "25%" },
              { renderDefinition: columns.primaryHolder, width: "25%" },
              { renderDefinition: columns.signingLink, width: "15%" },
              { renderDefinition: columns.sendingDate, width: "10%" },
              { renderDefinition: columns.status, width: "15%" },
              { renderDefinition: columns.action, width: "10%" },
            ]}
            extraRowApi={{ templateId }}
            emptyMessage="Aucun destinatire"
          />
        </Paper>
      </Stack>
    </>
  )
}

const PageTitle = ({ templateId }: { templateId: string }) => {
  const { summary } = useTemplateSummaryApi({ templateId })

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TitleIcon />
      <span>{summary.name}</span>
    </Stack>
  )
}

const TitleIcon = styled(FileText)(({ theme }) => ({
  width: "26px",
  height: "26px",
  color: theme.palette.primary.dark1,
}))

const statusOptions: ReferentialCode<SignatoryStatus>[] = [
  { label: "Attente envoi", code: "SENDING" },
  { label: "Erreur", code: "ERROR" },
  { label: "À signer", code: "UNSIGNED" },
  { label: "Signé", code: "SIGNED" },
  { label: "Refusé", code: "REFUSED" },
]

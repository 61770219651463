import type { ReferentialCode } from "@pharmupp/p3-front-commons"
import { Status } from "./api.model"

export interface ApiFormReferentialCreate {
  rubrics: ReferentialCode[]
  uppCategories: NewsCategory[]
  laboratories: ReferentialCode[]
  solutions: ReferentialCode[]
  upps: { id: number; name: string }[]
}

export type ApiFormReferentialUpdate = ApiFormReferentialCreate & {
  statuses: {
    label: string
    code: string
    color: string
  }[]
}

export type ApiFormReferential = ApiFormReferentialCreate | ApiFormReferentialUpdate

export const statusOptions = [
  { label: "Active", code: Status.PUBLISHED },
  { label: "Archivée", code: Status.ARCHIVED },
]

export interface NewsCategory {
  code: string
  label: string
  display: boolean
  color?: {
    /* HEX color */
    bkgColor: string
    /* HEX color */
    fontColor: string
  }
}

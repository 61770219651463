import {
  api,
  formatDateQueryParam,
  useSuspenseQuery,
} from "@pharmupp/p3-front-commons"
import { endOfMonth, startOfMonth, startOfYear, sub } from "date-fns"
import {
  type MarketShareCategory,
  marketShareCategories,
} from "../../../../../model"

export const useLabMarketShareApi = ({
  labId,
  period,
}: { labId: string; period: Period }) => {
  const dateRange = periodDates[period]

  const { data: labMarketShare } = useSuspenseQuery({
    queryKey: ["laboratories", "adherent", labId, "sales", period],
    queryFn: () =>
      api.get<ApiLabMarketShare>(
        `/api/laboratories/adherent/${labId}/sales?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
      ),
    select: (response) => ({
      categories:
        response.categories
          ?.sort((a, b) =>
            marketShareCategories[a.marketShareCategory].order <
            marketShareCategories[b.marketShareCategory].order
              ? -1
              : 1,
          )
          .map((category) => ({
            ...category,
            label: marketShareCategories[category.marketShareCategory].label,
            roundedTotalSales: Math.round(category.totalSales),
            laboratorySplits: (category.laboratorySplits || [])
              // Sort by
              // - kind to ensure the order is SELECTED, PRIORITY, OTHER
              // - splitSales to ensure the order is descending
              .sort((a, b) => {
                if (a.kind === b.kind) return a.splitSales < b.splitSales ? 1 : -1
                if (a.kind === "SELECTED") return -1
                if (b.kind === "SELECTED") return 1
                if (a.kind === "PRIORITY") return -1
                if (b.kind === "PRIORITY") return 1
                return 0
              })
              .map((split) => ({
                label: split.name,
                value: Math.round((split.splitSales * 100) / category.totalSales),
                color: getLabColorByKind(split.kind),
              })),
          })) ?? [],
    }),
  })

  return { labMarketShare }
}

const getLabColorByKind = (category: SplitKind) => {
  switch (category) {
    case "SELECTED":
      return "#1d3e76"
    case "PRIORITY":
      return "#c4bbf6"
    case "OTHER":
      return "#f4f5fa"
  }
}

type SplitKind = "SELECTED" | "PRIORITY" | "OTHER"

export type ApiLabMarketShare = {
  categories?: Array<{
    marketShareCategory: MarketShareCategory
    totalSales: number
    laboratorySplits?: {
      name: string
      /** integer */
      splitPercentage: number
      splitSales: number
      kind: SplitKind
    }[]
  }>
}

export type Period = "LAST_4_MONTHS" | "CURRENT_YEAR" | "LAST_12_MONTHS"
const today = new Date()
export const periodDates: Record<Period, { startDate: string; endDate: string }> = {
  CURRENT_YEAR: {
    startDate: formatDateQueryParam(startOfYear(sub(today, { months: 1 }))),
    endDate: formatDateQueryParam(endOfMonth(sub(today, { months: 1 }))),
  },
  LAST_4_MONTHS: {
    startDate: formatDateQueryParam(startOfMonth(sub(today, { months: 4 }))),
    endDate: formatDateQueryParam(endOfMonth(sub(today, { months: 1 }))),
  },
  LAST_12_MONTHS: {
    startDate: formatDateQueryParam(startOfMonth(sub(today, { months: 12 }))),
    endDate: formatDateQueryParam(endOfMonth(sub(today, { months: 1 }))),
  },
}

import {
  api,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
  useToaster,
} from "@pharmupp/p3-front-commons"
import type { RATING_CATEGORY } from "../rating.commons"

export const useRatingApi = ({ labId }: { labId: string }) => {
  const toaster = useToaster()
  const queryClient = useQueryClient()

  // GET
  const { data: rating } = useSuspenseQuery({
    queryKey: ["laboratories", "adherent", labId, "rating"],
    queryFn: () =>
      api.get<ApiGetAdhLabRating>(`/api/laboratories/adherent/${labId}/rating`),
  })

  // PUT
  const { mutateAsync: updateRating } = useMutation({
    mutationFn: (rating: ApiPutAdhLabRating) =>
      api.put<ApiGetAdhLabRating>(`/api/laboratories/adherent/${labId}/rating`, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(rating),
      }),
    onSuccess() {
      toaster.success("Notes enregistrées")

      queryClient.invalidateQueries({
        queryKey: ["laboratories", "adherent", labId, "rating"],
      })
    },
    onError() {
      toaster.error("Erreur lors de l'envoi des notes")
    },
  })

  return {
    rating,
    updateRating,
  }
}

export type ApiGetAdhLabRating = Partial<Record<RATING_CATEGORY, { rating: number }>>
type ApiPutAdhLabRating = Record<RATING_CATEGORY, { rating: number }>

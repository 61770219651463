import { Stack } from "@mui/material"
import {
  Box,
  Card,
  CardActionArea,
  MenuItem,
  Select,
  type SelectProps,
  Typography,
  outlinedInputClasses,
  selectClasses,
  styled,
} from "@mui/material"
import { PaperContainer } from "@pharmupp/p3-front-commons"
import { PhoneNumber } from "@pharmupp/p3-front-commons/src/components/dataDisplay/numberDisplays"
import { ExternalLink, Mail, MapPin, Phone } from "react-feather"
import { ChevronDown, ChevronRight } from "react-feather"
import { Link } from "react-router-dom"

type SmallLinkCardProps = {
  icon: React.ReactNode
  label: string
} & ({ to?: string } & { onClick?: () => void })

export const SmallLinkCard = ({ icon, label, ...props }: SmallLinkCardProps) => (
  <Card sx={{ border: "none" }}>
    <CardActionArea
      {...("to" in props
        ? {
            to: props.to,
            component: Link,
          }
        : {
            onClick: props.onClick,
          })}
      color="primary.dark1"
      sx={{
        px: 4,
        py: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#e2e9fc",
        height: "100%",
      }}
    >
      <Box display="flex" alignItems="center" gap={3}>
        {icon}
        <Typography
          color="inherit"
          fontSize={(theme) => theme.typography.pxToRem(20)}
          fontWeight={500}
        >
          {label}
        </Typography>
      </Box>
      <ChevronRight
        size="20px"
        color="#7d9fd2"
        style={{ justifySelf: "flex-end" }}
      />
    </CardActionArea>
  </Card>
)

export const ContactLaboCard = ({
  paperElement,
  header,
  name,
  title,
  phone,
  email,
  wording,
  wedia,
  line1,
  line2,
  postalCode,
  city,
  webSite,
}: {
  paperElement?: string
  header: string
  name: string
  title?: string
  phone?: string
  email?: string
  wording?: string
  wedia?: string
  line1?: string
  line2?: string
  postalCode?: string
  city?: string
  webSite?: string
}) =>
  paperElement ? (
    <PaperContainer padding={1.5}>
      <ContactTitleBlue my={1} sx={{ marginLeft: "5px" }}>
        {header}
      </ContactTitleBlue>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
        <Box sx={{ marginLeft: "5px" }}>
          <StyleContactContactDetails>{name}</StyleContactContactDetails>
          <StyleContactContactDetails>{line1}</StyleContactContactDetails>
          {line2 && <StyleContactContactDetails>{line2}</StyleContactContactDetails>}
          <StyleContactContactDetails>
            {postalCode} {city}
          </StyleContactContactDetails>
        </Box>
        <Box>
          {!!phone && (
            <Stack direction="row" color="primary.main" spacing={1} alignItems="top">
              <Phone size="20px" />
              <StyleContactContactDetails noWrap pb={1}>
                <PhoneNumber value={phone} />
              </StyleContactContactDetails>
            </Stack>
          )}
          {!!webSite && (
            <Stack
              direction="row"
              color="primary.main"
              spacing={1}
              alignItems="center"
            >
              <ExternalLink size="20px" />

              <RightCoordonneLink to={webSite} target="blank">
                {webSite}
              </RightCoordonneLink>
            </Stack>
          )}
        </Box>
      </Box>
    </PaperContainer>
  ) : (
    <Box padding={1.5}>
      <ContactTitle my={1}>{header}</ContactTitle>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
        <Box>
          <StyleContact>{name}</StyleContact>
          {!!title && <LeftContactSmall py={1}>{title}</LeftContactSmall>}
        </Box>
        <Box>
          {!!wording && (
            <Stack
              direction="row"
              color="primary.main"
              spacing={1}
              alignItems="center"
            >
              <MapPin size="20px" />
              <StyleContact noWrap>{wording}</StyleContact>
            </Stack>
          )}
          {!!wedia && (
            <Stack
              direction="row"
              color="primary.main"
              alignItems="center"
              marginLeft={3.4}
            >
              <RightContactLink to={wedia ?? ""} target="_blank">
                Voir la liste
              </RightContactLink>
            </Stack>
          )}
          {!!phone && (
            <Stack direction="row" color="primary.main" spacing={1} alignItems="top">
              <Phone size="20px" />
              <StyleContact noWrap pb={1}>
                <PhoneNumber value={phone} />
              </StyleContact>
            </Stack>
          )}
          {!!email && (
            <Stack direction="row" color="primary.main" spacing={1} alignItems="top">
              <Mail size="20px" />
              <StyleContact noWrap>
                <StyleContactMail to={`mailto:${email}`} target="_blank">
                  {email}
                </StyleContactMail>
              </StyleContact>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  )

const StyleContact = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: theme.palette.primary.main,
}))

const RightContactLink = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: "#176ae5",
  cursor: "pointer",
  textDecoration: "underline",
}))
const RightCoordonneLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.primary,
  textDecoration: "none!important",
}))
const LeftContactSmall = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  color: theme.palette.primary.main,
}))
const StyleContactContactDetails = styled(StyleContact)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
const StyleContactMail = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: theme.palette.primary.main,
  textDecoration: "none!important",
}))
const ContactTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "normal",
  color: theme.palette.grey.light,
}))
const ContactTitleBlue = styled(ContactTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export type PeriodSelectorOption<T> = { value: T; label: string | number }
type PeriodSelectorProps<T extends string | number> = Omit<
  SelectProps,
  "value" | "onChange"
> & {
  value: T
  onChange: (value: T) => void
  options: PeriodSelectorOption<T>[]
}
export const PeriodSelector = <T extends string | number>({
  value,
  onChange,
  options,
  ...props
}: PeriodSelectorProps<T>) => (
  <PeriodSelect
    {...props}
    value={value}
    onChange={(e) => onChange(e.target.value as T)}
    size="small"
    IconComponent={ChevronDown}
  >
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </PeriodSelect>
)
const PeriodSelect = styled(Select)(({ theme }) => ({
  borderRadius: "20px",
  backgroundColor: "#f6f6fb",

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
  },
  [`& .${selectClasses.icon}`]: {
    width: "18px",
    color: theme.palette.primary.dark4,
    top: "calc(50% - 11px)",
  },
  [`& .${selectClasses.select}`]: {
    padding: theme.spacing(1, 2.5),
    fontSize: "18px",
    lineHeight: "18px",
    minHeight: "auto!important",
    fontWeight: 500,
    color: theme.palette.primary.light1,
  },
}))

import { PaperContainerTitle } from "@pharmupp/p3-front-commons"

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grow,
  Stack,
  type SvgIcon,
  styled,
  useTheme,
} from "@mui/material"
import { type FC, type SVGProps, useState } from "react"
import { Feather, Home, LifeBuoy, Minus, Plus } from "react-feather"

import { Restaurant } from "@mui/icons-material"
import type {
  CategoryType,
  OspharmKeyFigures,
  SpecificMedicationType,
} from "../../kpi.models"
import { Category } from "../MyActivity/components"
import { BlisterMedicineIcon } from "../MyActivity/components/BlisterMedicineIcon"
import { CapsulesIcon } from "../MyActivity/components/CapsulesIcon"
import { PillsIcon } from "../MyActivity/components/PillsIcon"
import { SnowFlakeIcon } from "../MyActivity/components/SnowFlakeIcon"
import { NoData, OspharmCTA, SectionTitle, SyncDate } from "../layouts"

interface MyActivityProps {
  loading: boolean
  categories?: OspharmKeyFigures["categories"]
  specificMedications?: OspharmKeyFigures["specificMedications"]
  lastSyncDate?: string
}

export const MySales = ({
  loading,
  categories,
  specificMedications,
  lastSyncDate,
}: MyActivityProps) => {
  const { typography } = useTheme()
  const [viewMoreCategory, setViewMoreCategory] = useState(false)

  const firstFourCategs = categories?.slice(0, 4) || []
  const lastCategs = categories?.slice(4) || []

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      {/* TITLE */}
      <PaperContainerTitle mb={[2, 2, 2, 4, 4]}>Mes ventes</PaperContainerTitle>

      <Stack spacing={1} divider={<Divider />}>
        {!categories?.length && !specificMedications?.length ? (
          <NoData />
        ) : (
          <Stack spacing={4} divider={<Divider />}>
            {/* CATEGORIES SECTION */}
            {!!categories && (
              <Box>
                <SectionTitle
                  title="RÉPARTITION PAR CATÉGORIE"
                  preTitle="DÉTAIL DEPUIS LE DÉBUT DE L’ANNÉE (Montant HT à J-7)"
                  caption="Evolution par rapport à l’année dernière"
                />

                {/* MAIN CATEGORIES */}
                <CategoriesContainer>
                  {firstFourCategs.map((category) => (
                    <Category
                      key={category.name}
                      {...category}
                      Icon={CategoryIcon[category.categoryTypeEnum]}
                    />
                  ))}
                </CategoriesContainer>
                <Grow
                  in={viewMoreCategory}
                  mountOnEnter
                  unmountOnExit
                  style={{ transformOrigin: "top center" }}
                >
                  <CategoriesContainer mt={[2, 2, 2, 4, 4]}>
                    {lastCategs.map((category) => (
                      <Category
                        key={category.name}
                        {...category}
                        Icon={CategoryIcon[category.categoryTypeEnum]}
                      />
                    ))}
                  </CategoriesContainer>
                </Grow>
                <Stack justifyContent="center" mt={3}>
                  <Button
                    onClick={() => setViewMoreCategory((current) => !current)}
                    endIcon={
                      viewMoreCategory ? (
                        <Minus size={typography.pxToRem(18)} />
                      ) : (
                        <Plus size={typography.pxToRem(18)} />
                      )
                    }
                    sx={{ color: "grey.light", mx: "auto" }}
                  >
                    {viewMoreCategory ? "Moins de catégories" : "Plus de catégories"}
                  </Button>
                </Stack>
              </Box>
            )}

            {/* SPECIFIC CATEGORIES SECTION */}
            {!!specificMedications && (
              <Box mb={2}>
                <SectionTitle
                  title="Médicaments spécifiques"
                  caption="Évolution par rapport à l’année dernière"
                />

                <CategoriesContainer>
                  {specificMedications.map((specificMedication) => (
                    <Category
                      key={specificMedication.name}
                      {...specificMedication}
                      Icon={
                        SpecificMedicationIcon[specificMedication.categoryTypeEnum]
                      }
                    />
                  ))}
                </CategoriesContainer>

                <SyncDate date={lastSyncDate} mt={3} />
              </Box>
            )}
          </Stack>
        )}

        {/* OSPHARM CTA */}
        <OspharmCTA />
      </Stack>
    </Box>
  )
}

const CategoriesContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: theme.spacing(4),
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
    gap: theme.spacing(2),
  },
}))

type AnyIcon = FC<SVGProps<SVGSVGElement>> | typeof SvgIcon
const CategoryIcon: Record<CategoryType, AnyIcon> = {
  MEDICINES: PillsIcon,
  HOMECARE: Home,
  SELF_MEDICATION: LifeBuoy,
  PARAPHARMACY: PillsIcon,
  NUTRITION: Restaurant,
  ORTHOPEDICS_COMPRESSION: Home,
  BABY_PREGNANCY: Home,
  VETERINARY: Feather,
}

const SpecificMedicationIcon: Record<SpecificMedicationType, AnyIcon> = {
  BIO_SIMILAR: CapsulesIcon,
  CAPPED_MARGIN_MEDICATION: PillsIcon,
  COLD_PRODUCTS: SnowFlakeIcon,
  GENERIC: BlisterMedicineIcon,
}

import {
  Box,
  Card,
  CardActionArea,
  Link,
  Skeleton,
  Stack,
  Typography,
  cardActionAreaClasses,
  touchRippleClasses,
  useTheme,
} from "@mui/material"
import { Suspense } from "react"
import { ExternalLink, type Icon as FeatherIcon } from "react-feather"
import { ChevronDivider } from "../ChevronDivider"
import {
  type OpeazOpeType,
  useMyBriOperationsSummary,
} from "./useMyBriOperationsSummary"

type ExternalLinkCardProps = {
  title: string
  Icon: FeatherIcon
  type: OpeazOpeType
  variant?: ExternalLinkCardVariant
  divider?: boolean
}

type ExternalLinkCardVariant = "neutral" | "info" | "warning" | "success"

export const ExternalLinkCard = ({
  title,
  Icon,
  variant = "neutral",
  divider = true,
  type,
}: ExternalLinkCardProps) => (
  <Suspense
    fallback={
      <Skeleton component={Card} variant="rectangular" height="170px" width="100%" />
    }
  >
    <ExternalLinkCardInner
      title={title}
      Icon={Icon}
      variant={variant}
      divider={divider}
      type={type}
    />
  </Suspense>
)

const ExternalLinkCardInner = ({
  title,
  Icon,
  variant = "neutral",
  divider = true,
  type,
}: ExternalLinkCardProps) => {
  const { typography } = useTheme()
  const style = useLinkCardStyle(variant)
  const { operations } = useMyBriOperationsSummary()

  return (
    <Box position="relative">
      <Card sx={{ width: "100%", height: "100%", borderColor: style.borderColor }}>
        <CardActionArea
          component={Link}
          href={operations[type].url}
          height="100%"
          sx={{
            [`& > .${cardActionAreaClasses.focusHighlight},.${touchRippleClasses.child}`]:
              { bgcolor: style.borderColor },
          }}
        >
          <Stack gap={1.25} px={3} pt={2} pb={3} height="100%">
            <Stack gap={1.25}>
              <Box height="36px" display="flex" alignItems="center">
                <Icon color={style.iconColor} size="34px" />
              </Box>
              <Typography
                fontSize={typography.pxToRem(22)}
                fontWeight={600}
                color="common.darkBlue"
              >
                {operations[type].count ?? "-"}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography
                lineHeight={typography.pxToRem(20)}
                fontSize={typography.pxToRem(16)}
                fontWeight={500}
                color="common.darkBlue"
                width="70%"
              >
                {title}
              </Typography>
              <ExternalLink size="18px" color={style.linkIconColor} />
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>

      {/* DIVIDER */}
      {!!divider && <ChevronDivider accent />}
    </Box>
  )
}

const useLinkCardStyle = (variant: ExternalLinkCardVariant) => {
  const { palette } = useTheme()
  switch (variant) {
    case "info":
      return {
        iconColor: "#176ae5",
        borderColor: "#176ae5",
        linkIconColor: "#7d9fd2",
      }
    case "warning":
      return {
        iconColor: "#f67848",
        borderColor: "#f67848",
        linkIconColor: "#e1997e",
      }
    case "success":
      return {
        borderColor: palette.success.main,
        iconColor: palette.success.main,
        linkIconColor: palette.success.light,
      }
    default:
      return {
        iconColor: palette.common.darkBlue,
        borderColor: palette.common.darkBlue,
        linkIconColor: palette.common.darkBlue,
      }
  }
}

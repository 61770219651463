import { IconButton, type IconButtonProps, alpha, styled } from "@mui/material"
import { ChevronLeft, ChevronRight } from "react-feather"

const BTN_SIZE = 48
export const DISTANCE_FROM_EDGE = (2 / 5) * BTN_SIZE

type NavButtonProps = {
  direction: "PREVIOUS" | "NEXT"
} & IconButtonProps

export const NavButton = ({ direction, ...props }: NavButtonProps) => (
  <TransparentButton
    {...props}
    sx={
      direction === "PREVIOUS"
        ? { marginLeft: `${-DISTANCE_FROM_EDGE}px` }
        : { marginRight: `${-DISTANCE_FROM_EDGE}px` }
    }
  >
    {direction === "PREVIOUS" ? (
      <ChevronLeft color="white" size="26px" />
    ) : (
      <ChevronRight color="white" size="26px" />
    )}
  </TransparentButton>
)

const TransparentButton = styled(IconButton)(({ theme }) => ({
  width: `${BTN_SIZE}px`,
  height: `${BTN_SIZE}px`,

  backgroundColor: alpha(theme.palette.primary.dark1, 0.62),
  color: "common.white",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark1,
  },
}))

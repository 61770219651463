import {
  Badge,
  Box,
  Chip,
  Stack,
  TableCell,
  Typography,
  styled,
} from "@mui/material"
import {
  ActionButton,
  type ColumnRenderDefinition,
  ListItemState,
  type ReferentialValue,
  SortedTableCell,
  formatNumber,
  formatPrice,
  type useDialog,
} from "@pharmupp/p3-front-commons"
import { Check, Eye, Info, Slash, Trash } from "react-feather"
import type { BonusOperation } from "../models/BonusOperation.model"

export const columns = {
  date: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="date" width={width}>
        Date
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>{row.year || getDateYear(row.validityStartDate)}</TableCell>
    ),
  },

  category: {
    renderHeader: ({ width }) => <TableCell width={width}>Catégorie</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        {row.tag ? (
          <CategoryChip variant="outlined" label={row.tag.name} size="small" />
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  title: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="title" width={width}>
        Nom
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell sx={{ fontWeight: 700 }}>{row.title}</TableCell>
    ),
  },

  submittedRequests: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        Demandes
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        {row.submittedRequest ? (
          <Badge badgeContent={row.submittedRequest} color="primary" />
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  maxBonus: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="maxBonus"
        align="center"
        width={width}
      >
        Prime max
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">{formatPrice(row.maxBonus)}</TableCell>
    ),
  },

  uppBonus: {
    renderHeader: ({ width }) => <TableCell width={width}>Prime UPP</TableCell>,
    renderRow: ({ row }) => (
      <TableCell sx={{ fontWeight: row.uppBonus ? 600 : 400 }}>
        {row.uppBonus ? formatPrice(row.uppBonus) : "-"}
      </TableCell>
    ),
  },

  validatedBonuses: {
    renderHeader: ({ width }) => (
      <TableCell width={width}>Primes validées</TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        {row.maxPaidBonuses ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            {row.paidBonuses !== undefined && row.paidBonuses > 0 ? (
              <Typography fontSize="inherit" fontWeight={600} color="success.main">
                {formatPrice(row.paidBonuses)}
              </Typography>
            ) : (
              <Typography fontSize="inherit" color="inherit">
                {formatNumber(row.paidBonuses)}
              </Typography>
            )}

            <Typography fontSize="inherit">/</Typography>
            {formatPrice(row.maxPaidBonuses)}
          </Stack>
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  participantCount: {
    renderHeader: ({ width }) => (
      <TableCell align="center" width={width}>
        Participation
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        {row.status === "ACTIVE" && row.totalParticipants ? (
          <Counter>{row.totalParticipants}</Counter>
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  status: {
    renderHeader: ({ width }) => <TableCell width={width}>Statut</TableCell>,
    renderRow: ({ row, api }) => (
      <TableCell>
        <ListItemState
          options={api.referential.statusOptions}
          value={row.status ?? ""}
        />
      </TableCell>
    ),
  },

  state: {
    renderHeader: ({ width }) => <TableCell width={width}>Statut</TableCell>,
    renderRow: ({ row, api }) => (
      <TableCell>
        <ListItemState
          options={api.referential.statusOptions}
          value={row.status ?? "AVAILABLE"}
        />
      </TableCell>
    ),
  },

  crudActions: {
    renderHeader: ({ width }) => <TableCell width={width} />,
    renderRow: ({ row, api }) => (
      <TableCell>
        <ActionButton
          IconProps={{ edge: "end" }}
          items={[
            {
              Icon: Eye,
              label: "Consulter",
              onClick: () => api.goToDetails(row.id),
            },
            {
              Icon: Slash,
              label: "Désactiver",
              onClick: () =>
                api.dialog.deactivate({
                  endpoint: `/api/operation/bonus/${row.id}/deactivate`,
                  onSuccess: api.refresh,
                }),
              hidden: !row.active,
            },
            {
              Icon: Trash,
              label: "Supprimer",
              onClick: () =>
                api.dialog.delete({
                  endpoint: `/api/operation/bonus/${row.id}`,
                  onSuccess: api.refresh,
                  onError: () =>
                    api.dialog.info({
                      title: "Suppression",
                      message: "Une erreur est survenue",
                    }),
                }),
              hidden: !row.deletePossible,
            },
          ]}
        />
      </TableCell>
    ),
  },

  enablingActions: {
    renderHeader: ({ width }) => <TableCell width={width} />,
    renderRow: ({ row, api }) => (
      <TableCell>
        <ActionButton
          items={[
            {
              Icon: Check,
              label: "Activer",
              onClick: () => api.showActivationModal(row),
              hidden: row.status !== null,
            },
            {
              Icon: Eye,
              label: "Consulter",
              onClick: () => api.goToDetails(row.bonusOperationId),
            },
            {
              Icon: Info,
              label: "Participants",
              onClick: () => api.goToParticipants(row.bonusOperationId),
              hidden: row.status !== "ACTIVE",
            },
          ]}
        />
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<BonusOperation, BonusOpColumnApi>>

export interface BonusOpColumnApi {
  dialog: ReturnType<typeof useDialog>
  showActivationModal: (row: BonusOperation) => void
  goToDetails: (id: number) => void
  goToParticipants: (id: number) => void
  referential: {
    statusOptions: (ReferentialValue<string> & { color: string })[]
  }
}

const getDateYear = (dateStr: string) => new Date(dateStr).getFullYear()

const CategoryChip = styled(Chip)(({ theme }) => ({
  borderRadius: "2px",
  borderColor: theme.palette.primary.light4,
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
}))

const Counter = styled(Box)<{ children: number }>(
  ({ children, theme: { palette, spacing, typography } }) => {
    const color = children === 0 ? palette.grey.dark : palette.common.electricBlue
    return {
      margin: "auto",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: color,
      borderRadius: "100px",
      padding: `${spacing(0.5)} ${spacing(1)}`,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      color,
      fontSize: typography.pxToRem(12),
      minWidth: `calc(${typography.pxToRem(12)} + ${spacing(2)})`,
    }
  },
)

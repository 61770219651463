import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"
import { useState } from "react"

export const useLoyaltyApi = ({ labId }: { labId: string }) => {
  const [year, setYear] = useState(new Date().getFullYear())

  const { data: loyalty } = useSuspenseQuery({
    queryKey: ["laboratories", labId, "adherent", "loyalty", "summary", year],
    queryFn: () =>
      api.get<ApiLoyalty>(
        `/api/laboratories/adherent/${labId}/loyalty/summary?year=${year}`,
      ),
  })

  return { loyalty, year, setYear }
}

export type ApiLoyalty = {
  categoricalChallenge: {
    points: number | null
  } | null
  sdavChallenge: {
    points: number | null
    countOrders: number
  } | null
  pharmeDisoundCompensation: number | null
  opeazCompensation: number | null
}

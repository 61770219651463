import { Check } from "@mui/icons-material"
import {
  Box,
  type BoxProps,
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { CardContainer } from "@pharmupp/p3-front-commons"
import { ChevronDown } from "react-feather"
import { Link } from "react-router-dom"
import { OfferCard } from "../../OfferList/OfferCard"
import { useLabOffersApi } from "../../api/useLabOffersApi"

export type LabOffersWidgetProps = {
  labId: string
  viewAllLink: string
} & BoxProps

export const LabOffersWidget = ({
  labId,
  viewAllLink,
  ...props
}: LabOffersWidgetProps) => {
  const theme = useTheme()

  // API
  const { offers } = useLabOffersApi({ labId, size: 8 })

  return offers?.length ? (
    <Box bgcolor="#e6f1fd" pt={4} px={4} pb={5} {...props}>
      <Box>
        {/* TITLE */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            color="success.main"
            borderColor="success.main"
            border="1px solid"
            display="flex"
            borderRadius="50%"
            p={2}
          >
            <Check />
          </Box>
          <Typography
            textTransform="uppercase"
            color="common.electricBlue"
            fontSize={theme.typography.pxToRem(21)}
            fontWeight={500}
          >
            {getOffersTitle(offers?.length)}
          </Typography>
        </Stack>

        {/* OFFER CARDS */}
        <CardContainer mt={3} mb={5} sx={{ overflowY: "auto" }}>
          {offers?.map((offer) => (
            <OfferCard key={offer.title} offer={offer} showLabLogo={false} />
          ))}
        </CardContainer>

        {/* VIEW MORE BTN */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <ViewMoreBtn
            component={Link}
            to={viewAllLink}
            variant="outlined"
            endIcon={<ChevronDown />}
          >
            Voir toutes les offres
          </ViewMoreBtn>
        </Box>
      </Box>
    </Box>
  ) : null
}

const getOffersTitle = (count = 0) => {
  switch (count) {
    case 0:
      return "Aucune offre en cours"
    case 1:
      return "1 offre en cours"
    default:
      return `${count} offres en cours`
  }
}

const ViewMoreBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light1,
  borderColor: theme.palette.primary.light1,
  "&:hover": {
    color: theme.palette.primary.main,
  },
})) as typeof Button

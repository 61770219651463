import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useMyUnsignedDocsApi = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["documents", "adherent", "unsigned"],
    queryFn: () =>
      api.get<ApiUnsignedDocument[]>("/api/documents/adherent/unsigned"),
    select: sortByMostCritical,
  })

  return { unsignedDocs: data, isLoading }
}

export const sortByMostCritical = (docs: ApiUnsignedDocument[]) =>
  docs.sort((a, b) => +b.critical - +a.critical)

export type ApiUnsignedDocument = {
  templateId: string
  templateName: string
  critical: boolean
}

import {
  type ReferentialCode,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { apiRoleConf } from "./conf/apiRoleConf"
import { listRoleConf } from "./conf/listRoleConf"
import type { BonusOperation } from "./models/BonusOperation.model"

export const useBonusOperationList = () => {
  // Role Conf
  const listConf = useRoleConf(listRoleConf)

  // Modals
  const [operationToActivate, setOperationToActivate] = useState<BonusOperation>()

  // API
  const apiConf = useRoleConf(apiRoleConf)

  const { data: referential, isLoading: loading } = useQuery({
    queryKey: ["bonus-operation", "list", "referential"],
    queryFn: () => api.get<BonusOpListReferential>(apiConf.referentialUrl),
  })

  return {
    listConf,
    setOperationToActivate,
    operationToActivate,
    referential,
    referentialLoading: loading,
    ...apiConf,
  }
}

interface BonusOpListReferential {
  status: (ReferentialCode<string> & { color: string })[]
}

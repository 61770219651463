import type { Crop } from "react-image-crop"
import { centerCrop, makeAspectCrop } from "react-image-crop"
import type { UploadedFile } from "../../../../types"
import { getPublicFileUrl } from "../../../../utils"

export const getInitialCrop = ({
  width,
  height,
  ratio,
}: {
  width: number
  height: number
  ratio: number
}): Crop => {
  // Is ratio reached ?
  if ((width / height).toPrecision(2) === ratio.toPrecision(2)) {
    return {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      unit: "%",
    }
  }
  return centerCrop(
    makeAspectCrop({ unit: "%", width: 90 }, ratio, width, height),
    width,
    height,
  )
}

export async function downloadFile(file: UploadedFile) {
  const response = await fetch(getPublicFileUrl(file))
  const data = await response.blob()
  const metadata = { type: file.contentType }

  return new File([data], file.originalFileName, metadata)
}

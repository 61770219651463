import type { Tab } from "@pharmupp/p3-front-commons"
import { LastYears } from "./LastYears"

const currentYear = new Date().getFullYear()
const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3]

export const lastYearsTabs: Tab[] = years.map((year) => ({
  Element: (props) => <LastYears {...props} year={year} />,
  label: year,
  to: year.toString(),
}))

import { FormControl, FormHelperText, Stack, styled } from "@mui/material"
import type { ReactNode } from "react"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import { Rating, type RatingProps } from "../../../dataDisplay/Rating"

export type FormRatingFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  renderRatingLabel?: (value: number) => ReactNode
} & RatingProps

export const FormRatingField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  ...otherProps
}: FormRatingFieldProps<TFieldValues>) => (
  <Controller<TFieldValues>
    name={name}
    render={({ field, fieldState: { error } }) => (
      <FormControl>
        <Stack gap={0.5}>
          <Rating id={name} {...field} {...otherProps} readOnly={false} />

          {!!error && <ErrorText error={!!error}>{error?.message}</ErrorText>}
        </Stack>
      </FormControl>
    )}
  />
)

const ErrorText = styled(FormHelperText)(({ theme }) => ({
  ...theme.typography["12px"],
  marginLeft: 0,
}))

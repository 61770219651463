import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TableCell,
  Typography,
  chipClasses,
  styled,
} from "@mui/material"
import {
  ActionButton,
  type ColumnRenderDefinition,
  LoadingIconButton,
  formatDateWithSlashes,
  useDialog,
  useFileDownload,
  useUppsList,
} from "@pharmupp/p3-front-commons"
import { AlertTriangle, Copy, FileText, MapPin, X } from "react-feather"
import { useSignatoryDocSigningLink } from "../../Document/useSignatoryDocSigningLink"
import { useTemplateSummaryApi } from "../../Template/useTemplateSummaryApi"
import { STATUS_COLOR } from "../../commons/components"
import {
  DialogInfoErrorMessage,
  DialogInfoErrorTitle,
} from "./SignatoryDialogInfoError"
import type { ApiSignatory } from "./types"

export const columns = {
  pharmacy: {
    renderHeader: ({ width }) => <TableCell width={width}>Pharmacie</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          <Typography fontSize="inherit" fontWeight={600}>
            {row.pharmacy.name}
          </Typography>
          <UppName uppId={row.pharmacy.uppId} />
          <Typography fontSize="inherit" color="grey.light">
            ID : {row.pharmacy.centralBusinessId} - CIP : {row.pharmacy.cip}
          </Typography>
        </Stack>
      </TableCell>
    ),
  },
  primaryHolder: {
    renderHeader: ({ width }) => <TableCell width={width}>Titulaire</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          <span>
            {`${row.pharmacy.primaryHolder?.lastName} ${row.pharmacy.primaryHolder?.firstName}`}
          </span>
          <Typography fontSize="inherit" color="primary.main">
            {row.pharmacy.primaryHolder?.email} - {row.pharmacy.primaryHolder?.phone}
          </Typography>
        </Stack>
      </TableCell>
    ),
  },
  signingLink: {
    renderHeader: ({ width }) => (
      <TableCell width={width}>Lien e-signature</TableCell>
    ),
    renderRow: ({ row, api: { templateId } }) => (
      <TableCell>
        {row.status === "UNSIGNED" ? (
          <SigningLink adherentId={row.pharmacy.id} templateId={templateId} />
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },
  sendingDate: {
    renderHeader: ({ width }) => <TableCell width={width}>Date d'envoi</TableCell>,
    renderRow: ({ row }) => {
      if (row.isEsigned === false) {
        return <TableCell>Transmis manuellement</TableCell>
      }
      return (
        <TableCell>
          {row.sendingDate ? formatDateWithSlashes(row.sendingDate) : "-"}
        </TableCell>
      )
    },
  },
  status: {
    renderHeader: ({ width }) => (
      <TableCell width={width} align="center">
        Statut
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <StatusDisplay
          status={row.status}
          signingDate={row.signingDate}
          isEsigned={row.isEsigned}
        />
      </TableCell>
    ),
  },
  action: {
    renderHeader: ({ width }) => <TableCell width={width} align="right" />,
    renderRow: ({ row, api: { templateId } }) => (
      <TableCell height="100%">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Action
            status={row.status}
            pharmacy={row.pharmacy}
            templateId={templateId}
            errors={row.errors}
          />
        </Box>
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ApiSignatory, ExtraRowApi>>

export type ExtraRowApi = {
  templateId: string
}

const UppName = ({ uppId }: { uppId: number }) => {
  const { uppsNameById } = useUppsList()

  if (!uppsNameById) return null

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <UppIcon />
      <Typography fontSize="inherit">{uppsNameById[uppId]}</Typography>
    </Stack>
  )
}

const UppIcon = styled(MapPin)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  width: theme.typography.pxToRem(12),
  height: theme.typography.pxToRem(12),
}))

const SigningLink = ({
  templateId,
  adherentId,
}: { templateId: string; adherentId: string }) => {
  const signingLink = useSignatoryDocSigningLink(templateId, adherentId)

  if (signingLink.isLoading) {
    return <CircularProgress size={14} />
  }

  return (
    <Link
      onClick={() => signingLink.fetchToClipboard()}
      href="#"
      color="grey.light"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      <Typography variant="12px" color="common.blue">
        Copier le lien
      </Typography>
      <Copy size={14} />
    </Link>
  )
}

const StatusDisplay = ({
  status,
  signingDate,
  isEsigned,
}: Pick<ApiSignatory, "status" | "signingDate" | "isEsigned">) => {
  switch (status) {
    case "SENDING":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.SENDING, color: STATUS_COLOR.SENDING }}
          label="Attente envoi"
        />
      )
    case "UNSIGNED":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.UNSIGNED, color: STATUS_COLOR.UNSIGNED }}
          label="À signer"
        />
      )
    case "SIGNED":
      return (
        <Stack alignItems="flex-end" gap={0.5}>
          <StatusChip
            variant="filled"
            sx={{ bgcolor: "success.main", color: "white" }}
            label="Signé"
          />
          {isEsigned === false && <EsignedChip label="Hors process" />}
          {signingDate && (
            <Typography variant="11px" color="primary.main">
              Le {formatDateWithSlashes(signingDate)}
            </Typography>
          )}
        </Stack>
      )
    case "REFUSED":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.REFUSED, color: STATUS_COLOR.REFUSED }}
          label="Refusé"
        />
      )
    case "ERROR":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.ERROR, color: STATUS_COLOR.ERROR }}
          label="Erreur"
        />
      )
  }
}

const StatusChip = styled(Chip)(({ theme }) => ({
  minWidth: "80px",
  height: "auto",
  padding: theme.spacing(0.6, 1),
  ...theme.typography["12px"],
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}))

const EsignedChip = styled(Chip)(({ theme }) => ({
  zIndex: 1,
  position: "relative",
  marginTop: theme.spacing(-1),
  padding: theme.spacing(0.3, 0),
  borderColor: theme.palette.success.main,
  minWidth: "80px",
  height: "auto",
  color: theme.palette.success.main,
  backgroundColor: "white",
  fontSize: theme.typography.pxToRem(11),
  lineHeight: theme.typography.pxToRem(11),
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}))
EsignedChip.defaultProps = {
  variant: "outlined",
}

const Action = ({
  status,
  pharmacy,
  templateId,
  errors,
}: { templateId: string } & Pick<
  ApiSignatory,
  "status" | "pharmacy" | "errors"
>) => {
  const { summary } = useTemplateSummaryApi({ templateId })
  const { startDownload, isDownloading } = useFileDownload({
    name: "unsigned-document",
    endpoint: `/api/documents/templates/${templateId}/signatories/${pharmacy.id}/file/UNSIGNED_DOCUMENT`,
    extension: "pdf",
  })
  const dialog = useDialog()

  switch (status) {
    case "REFUSED":
    case "SENDING":
      return (
        <IconButton edge="end" color="primary" size="small">
          <X />
        </IconButton>
      )
    case "SIGNED":
      return <DocDownloadBtn templateId={templateId} adherentId={pharmacy.id} />
    case "ERROR":
      return (
        <ActionButton
          IconProps={{ edge: "end", size: "small" }}
          items={[
            {
              Icon: AlertTriangle,
              label: "Détail erreur",
              hidden: status !== "ERROR",
              onClick: () =>
                dialog.info({
                  title: (
                    <DialogInfoErrorTitle
                      documentName={summary?.name}
                      pharmacy={pharmacy}
                    />
                  ),
                  message: <DialogInfoErrorMessage errors={errors} />,
                  validateText: "Fermer",
                  iconSvgSrc: "/icons/circle-alert-orange.svg",
                }),
            },
            { divider: true },
            {
              Icon: X,
              label: "Supprimer",
              onClick: () => alert("TODO"),
              disabled: true,
            },
          ]}
        />
      )
    case "UNSIGNED":
      return (
        <ActionButton
          IconProps={{ edge: "end", size: "small" }}
          loading={isDownloading}
          items={[
            {
              Icon: FileText,
              label: "Voir le document",
              onClick: () => startDownload(),
            },
            { divider: true },
            {
              Icon: X,
              label: "Supprimer",
              onClick: () => alert("TODO"),
              disabled: true,
            },
          ]}
        />
      )
  }
}

const DocDownloadBtn = ({
  templateId,
  adherentId,
}: {
  templateId: string
  adherentId: string
}) => {
  const { startDownload, isDownloading } = useFileDownload({
    name: "Document à signer",
    endpoint: `/api/documents/templates/${templateId}/signatories/${adherentId}/file/SIGNED_DOCUMENT`,
    extension: "pdf",
  })

  return (
    <LoadingIconButton
      onClick={startDownload}
      loading={isDownloading}
      size="small"
      edge="end"
      sx={{ p: 0 }}
    >
      <FileText />
    </LoadingIconButton>
  )
}

import { Box, Divider, Stack, Typography, styled, useTheme } from "@mui/material"
import {
  AmountChip,
  BackLink,
  ButtonLink,
  Chip,
  NumberFormat,
  PageHeader,
  PaperContainer,
  PaperContainerHeader,
  SidebarBigValue,
  SidebarCard,
  SidebarLabel,
  SidebarValue,
  formatDateWithDots,
} from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import { ChevronRight, PieChart } from "react-feather"
import { LoyaltyCategoryAlert } from "./LoyaltyCategoryAlert"
import type { LoyaltyCategory } from "./useLoyaltyCategoryApi"

export const LoyaltyCategoryDisplay = ({
  loyaltyCategory,
  currentYear,
  children,
}: {
  loyaltyCategory?: LoyaltyCategory
  currentYear: number
  children: ReactNode
}) => {
  const start = formatDateWithDots(new Date(currentYear, 0, 1))
  const end = formatDateWithDots(new Date(currentYear, 11, 31))
  const validity = `Du ${start} - Au ${end}`
  const theme = useTheme()
  const amountChipProps = {
    variant: "outlined",
    prefix: "+",
    unit: " pts",
    color: theme.palette.loyalty.main,
    borderColor: theme.palette.loyalty.light3,
  } as const

  return (
    <>
      <BackLink to="./../../">Liste</BackLink>

      {/* SIDEBAR */}
      <SidebarCard gridArea="sidebar" sx={{ pb: 8 }}>
        {!!loyaltyCategory && (
          <Stack spacing={5}>
            <Stack>
              <SidebarLabel>Catégorie</SidebarLabel>
              <Box>
                <Chip
                  size="small"
                  variant="outlined"
                  label={loyaltyCategory.name}
                  fontColor="primary"
                />
              </Box>
            </Stack>

            <Stack>
              <SidebarLabel>Validité</SidebarLabel>
              <SidebarValue>{validity}</SidebarValue>
            </Stack>
            <Divider />

            <Stack>
              <SidebarLabel>Points acquis</SidebarLabel>
              <SidebarBigValue>
                <NumberFormat
                  value={loyaltyCategory.totalEarnPoints}
                  suffix=" pts"
                />
              </SidebarBigValue>
            </Stack>
            <Divider />

            <ButtonLink
              variant="text"
              to="/dashboard?menu%5Bitem%5D=pdm"
              endIcon={<ChevronRight color={theme.palette.grey.ultraLight} />}
              startIcon={<PieChart />}
              sx={{
                fontSize: theme.typography.pxToRem(14),
                padding: "0px",
                marginTop: "0px",
              }}
            >
              Détail PDM catégorielles
            </ButtonLink>
          </Stack>
        )}
      </SidebarCard>

      {/* HEADER */}
      <PageHeader
        title={`Challenge ${loyaltyCategory?.name || ""} ${currentYear || ""}`}
        level={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {!!loyaltyCategory && (
            <>
              <HeaderLabel>Jusqu&apos;à</HeaderLabel>
              <AmountChip {...amountChipProps}>
                {loyaltyCategory.totalExpectedPoints}
              </AmountChip>
            </>
          )}
        </Stack>
      </PageHeader>

      {/* CONTENT */}
      <Box gridArea="main" mb={4}>
        <PaperContainer loading={!loyaltyCategory} pb={12}>
          {!!loyaltyCategory && (
            <>
              <PaperContainerHeader
                title="Détail du barème"
                label="Validité"
                value={validity}
                mb={2}
              />

              <Box mb={5}>
                <LoyaltyCategoryAlert year={currentYear} />
              </Box>

              {children}
            </>
          )}
        </PaperContainer>
      </Box>
    </>
  )
}

const HeaderLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.light,
  fontSize: theme.typography.pxToRem(14),
}))

export type CatalogName = "fdl" | "fdlPlus" | "sagitta" | "coalia" | "sdav"
export type CatalogType = Record<CatalogName, boolean>

export const catalogLabel: Record<CatalogName, string> = {
  fdl: "FDL",
  fdlPlus: "FDL +",
  sagitta: "Ma Boutique",
  coalia: "Coalia",
  sdav: "SDAV",
}

export type MarketShareCategory =
  | "GENERICS"
  | "ORTHOPEDICS"
  | "COMPRESSION"
  | "NUTRITION"
  | "WHITE_PRODUCT_LINE"
  | "INCONTINENCE"
  | "BIOSIMILARS"
  | "VACCINATION"

export const marketShareCategories: Record<
  MarketShareCategory,
  { label: string; order: number }
> = {
  GENERICS: { label: "Génériques", order: 1 },
  ORTHOPEDICS: { label: "Orthopédie", order: 2 },
  COMPRESSION: { label: "Compression", order: 3 },
  NUTRITION: { label: "Nutrition", order: 4 },
  WHITE_PRODUCT_LINE: { label: "Gamme Blanche", order: 5 },
  INCONTINENCE: { label: "Incontinence", order: 6 },
  BIOSIMILARS: { label: "Biosimilaires", order: 7 },
  VACCINATION: { label: "Vaccination", order: 8 },
}

import {
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material"
import { format, parse } from "date-fns"
import * as React from "react"
import {
  Check,
  Circle,
  type Icon as FeatherIcon,
  FileText,
  Plus,
  Trash,
  X,
} from "react-feather"
import {
  type FieldPath,
  type FieldValues,
  useFieldArray,
  useFormContext,
} from "react-hook-form"
import type { UploadedFile } from "../../../../types"
import { getPublicFileUrl, triggerFileDownload } from "../../../../utils"
import { FormRoleConfContext } from "../../../context"
import { useDialog } from "../../../feedback"
import { FieldText } from "../../layout"
import { FormRadioGroup } from "../FormRadioGroup"

export interface StatusDefinition {
  Icon: FeatherIcon
  iconColor?: string
  label: string
}

const STATUSES = {
  NEW: { Icon: Plus, iconColor: "rgb(40, 84, 161)", label: "nouveau" },
  SUBMITTED: { Icon: Circle, label: "validation en cours" },
  APPROVED: { Icon: Check, iconColor: "#1db778", label: "actif" },
  REJECTED: { Icon: X, iconColor: "#f03308", label: "inactif" },
}
type Status = keyof typeof STATUSES

export interface FormFilesUploadedListConf {
  mode: "uploader" | "reviewer"
}

export interface FormFilesUploadedListProps<
  TFieldValues extends FieldValues = FieldValues,
> {
  name: FieldPath<TFieldValues>
}
export const FormFilesUploadedList = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
}: FormFilesUploadedListProps<TFieldValues>) => {
  const dialog = useDialog()

  const conf = React.useContext(FormRoleConfContext)
  const fieldConf = (conf[name] as unknown as FormFilesUploadedListConf) || {}

  const methods = useFormContext()
  const { fields, remove } = useFieldArray({
    name,
    control: methods.control,
    keyName: "arrayKey",
  })
  const uploads = methods.watch(name) as UploadedEntity[]
  const onDelete = (id: number) => {
    dialog.confirm({
      title: "Êtes-vous sur ?",
      message: "Voulez-vous vraiment supprimer ce fichier ?",
      validateButtonText: "Supprimer",
      validateAction: () => remove(id),
    })
  }

  return fields.length ? (
    <Stack spacing={2.5}>
      {fields.map(
        (field, index) =>
          uploads[index] && (
            <FileRow
              key={field.arrayKey}
              name={`${name}.${index}`}
              uploadedEntity={uploads[index]}
              status={STATUSES[uploads[index].status]}
              onDelete={() => onDelete(index)}
              mode={
                "uploader" === fieldConf.mode || "reviewer" === fieldConf.mode
                  ? fieldConf.mode
                  : undefined
              }
            />
          ),
      )}
    </Stack>
  ) : (
    <>
      {"reviewer" === fieldConf.mode && (
        <FieldText>Aucun fichier à valider</FieldText>
      )}
    </>
  )
}

interface FileRowProps {
  name: string
  uploadedEntity: UploadedEntity
  status: StatusDefinition
  onDelete: () => void
  onChangeStatus?: (status: string) => void
  mode?: "uploader" | "reviewer"
}

const FileRow = ({
  name,
  uploadedEntity,
  status,
  onChangeStatus,
  onDelete,
  mode,
}: FileRowProps) => {
  const downloadFile = async () => {
    const blobUrl = getPublicFileUrl(uploadedEntity.file)
    triggerFileDownload(blobUrl, uploadedEntity.file.originalFileName)
  }

  return (
    <Paper variant="outlined">
      <Stack direction="row" justifyContent="space-between" p={2}>
        <Link
          target="_blank"
          title="télécharger"
          onClick={downloadFile}
          sx={{ cursor: "pointer" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <FileText color="#a9afb8" size="33px" />
            <Stack>
              <Typography fontSize={14}>
                {uploadedEntity.file?.originalFileName}
              </Typography>
              <DateDisplay date={uploadedEntity.uploadDate} />
            </Stack>
          </Stack>
        </Link>
        <Stack direction="row" alignItems="center">
          {mode === "reviewer" ? (
            <FormRadioGroup
              name={`${name}.status`}
              onChange={(_event, value) => onChangeStatus?.(value)}
              radios={statusRadios}
              row
            />
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <StatusDisplay {...status} />
              {mode === "uploader" && (
                <DeleteButton color="primary" onClick={onDelete}>
                  <Trash />
                </DeleteButton>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

const statusRadios = [
  { label: "Accepté", value: "APPROVED" },
  { label: "Refusé", value: "REJECTED" },
]

const DateDisplay = ({ date }: { date: string }) => (
  <Typography color="grey.light" fontSize={12}>
    {format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}
  </Typography>
)

const StatusDisplay = ({ Icon, iconColor, label }: StatusDefinition) => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={1}
    width="90px"
  >
    <Icon size="12px" color={iconColor || "#a9afb8"} />
    <Typography fontSize={12} color="primary.main">
      {label}
    </Typography>
  </Stack>
)

export interface UploadedEntity {
  status: Status
  /** format: 2022-05-24 */
  uploadDate: string
  file: UploadedFile
}

const DeleteButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
}))

import { UserForm } from "../UserForm"
import { formDefaults } from "../UserForm/tabs/GeneralInfos/GeneralInfos.default"
import { UserTypeEnum } from "../UsersList/models/UserType.enum"
import { StatusEnum } from "../enums"

const defaultValues = {
  ...formDefaults,
}

export function CreateUser() {
  return <UserForm defaultValues={defaultValues} />
}

const myUserDefaultValues = {
  ...defaultValues,
  type: UserTypeEnum.TEAM.code,
  status: StatusEnum.ACTIVE.code,
}

export function CreateMyUser() {
  return <UserForm defaultValues={myUserDefaultValues} />
}

import { Box, type BoxProps, Button, type SelectProps, styled } from "@mui/material"
import type * as React from "react"
import type { ReferentialCode, ReferentialValue } from "../../../../types"

// Override values by accessing entity referential
// Override colors by accessing theme colors
export const DEFAULT_OPTIONS = [
  { label: "Actif", value: "ACTIVE", color: "success.main" },
  { label: "Inactif", value: "INACTIVE", color: "error.main" },
]

type Option = (ReferentialCode<string> | ReferentialValue<string>) & {
  color: BoxProps["bgcolor"]
}
export type ListItemStateProps = {
  value: string
  options?: Option[]
} & SelectProps

export const ListItemState = ({
  value,
  options = DEFAULT_OPTIONS,
}: ListItemStateProps) => {
  const optionSelected = value
    ? options.find((option) =>
        "code" in option ? option.code === value : option.value === value,
      )
    : options[0]
  if (!optionSelected) return null
  return (
    <ReadonlyButton variant="outlined" onClick={readOnlyOnClick} disableRipple>
      <Dot bgcolor={optionSelected.color} />
      {upperFirst(optionSelected.label)}
    </ReadonlyButton>
  )
}

const Dot = styled(Box)({
  display: "inline-block",
  minWidth: "8px",
  height: "8px",
  marginRight: "7px",
  borderRadius: "50%",
})

const readOnlyOnClick = (event: React.SyntheticEvent) => {
  event.stopPropagation()
  return event.preventDefault()
}
const ReadonlyButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(0.6)} ${theme.spacing(1)}`,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "normal",
  cursor: "default",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "inherit",
  },
}))

// Azy nik CSS 🖕
const upperFirst = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

import {
  Box,
  Card,
  CardActionArea,
  type CardProps,
  Divider,
  Skeleton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material"
import { Link, ROLES, useRoleCheck } from "@pharmupp/p3-front-commons"
import { ChevronRight } from "react-feather"
import { useMyAgendaApi } from "./useMyAgendaApi"

export type MyAgendaProps = {
  viewOneLink: string
  viewAllLink: string
  adherentId?: string
} & Omit<CardProps<typeof Stack>, "component">

export const MyAgenda = ({
  viewOneLink,
  viewAllLink,
  adherentId,
  ...otherProps
}: MyAgendaProps) => {
  const isAdherent = useRoleCheck(ROLES.ADHERENT_HOLDER)
  const { events, loading } = useMyAgendaApi(adherentId)

  return loading ? (
    <Skeleton variant="rectangular" width="100%" />
  ) : (
    <Card p={3} spacing={3} {...otherProps} component={Stack}>
      <Title>Mon agenda</Title>

      <Stack spacing={2} divider={<Divider />}>
        {events.length > 0 ? (
          events.map((event) => (
            <Event {...event} link={`${viewOneLink}/${event.id}`} key={event.id} />
          ))
        ) : (
          <Typography color="grey.light">Aucun évènement</Typography>
        )}
      </Stack>

      {!!isAdherent && (
        <>
          <Divider />
          <Link
            to={viewAllLink}
            display="flex"
            alignItems="flex-end"
            alignSelf="flex-end"
            color="success.main"
            height="100%"
            underline="hover"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                fontSize={(theme) => theme.typography.pxToRem(12)}
                textTransform="uppercase"
              >
                Voir tous
              </Typography>
              <ChevronRight size="14px" />
            </Stack>
          </Link>
        </>
      )}
    </Card>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textTransform: "uppercase",
  color: theme.palette.grey.dark,
}))

interface EventProps {
  link: string
  id: number
  title: string
  day: string
  month: string
  startTime: string
  endTime: string
  cancelled: boolean
}

const Event = ({
  link,
  title,
  day,
  month,
  startTime,
  endTime,
  cancelled,
}: EventProps) => {
  const isAdherent = useRoleCheck(ROLES.ADHERENT_HOLDER)

  return (
    <Card
      {...(isAdherent && {
        component: Link,
        to: link,
      })}
      sx={{
        backgroundColor: "transparent",
        border: 0,
        pointerEvents: isAdherent ? "auto" : "none",
      }}
    >
      <CardActionArea>
        <Stack direction="row" spacing={2}>
          <DateSquare>
            <Typography
              fontSize={(theme) => theme.typography.pxToRem(16)}
              fontWeight={600}
              color={cancelled ? "common.red" : "success.main"}
            >
              {day}
            </Typography>
            <Typography
              fontSize={(theme) => theme.typography.pxToRem(12)}
              fontWeight={600}
              textTransform="uppercase"
              color="grey.light"
            >
              {month}
            </Typography>
          </DateSquare>

          <Stack justifyContent="space-evenly" spacing={0.5}>
            <EventTitle color={cancelled ? "grey.light" : "common.darkBlue"}>
              {title}
            </EventTitle>
            <Box
              fontSize={(theme) => theme.typography.pxToRem(12)}
              lineHeight={(theme) => theme.typography.pxToRem(14)}
              color={cancelled ? "common.red" : "common.darkBlue"}
            >
              {!!cancelled && (
                <Typography fontSize="inherit" lineHeight="inherit" component="span">
                  Annulé /{" "}
                </Typography>
              )}
              <Typography
                fontSize="inherit"
                lineHeight="inherit"
                component="span"
                sx={{
                  textDecoration: cancelled ? "line-through" : "unset",
                }}
              >
                {startTime} - {endTime}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

const DateSquare = styled(Stack)(({ theme }) => ({
  minWidth: "51px",
  height: "51px",
  borderRadius: "2px",
  border: `1px solid ${alpha(theme.palette.grey.ultraLight, 0.2)}`,

  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",

  backgroundColor: alpha(theme.palette.grey.ultraLight, 0.15),
}))

const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  lineHeight: theme.typography.pxToRem(16),
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "2",
  lineClamp: "2",
}))

import type { IterableElement } from "type-fest"

export type ApiSignatory = {
  pharmacy: {
    id: string
    name: string
    cip: string
    centralBusinessId: string
    primaryHolder: Partial<{
      firstName: string
      lastName: string
      email: string
      phone: string
    }> | null
    uppId: number
  }
  isEsigned: boolean | null
  /** ISO date YYYY-MM-DD */
  sendingDate?: string | null
  /** ISO date YYYY-MM-DD */
  signingDate?: string | null
  status: SignatoryStatus
  errors?: Array<SignatoryError> | null
}

export type SignatoryError =
  | IterableElement<typeof ERRORS_UTILS>
  | IterableElement<typeof ERRORS_UNEXPECTED>

export const ERRORS_UTILS = new Set([
  "MISSING_EMAIL",
  "MISSING_MOBILE_PHONE",
  "MISSING_FIRST_NAME",
  "MISSING_LAST_NAME",
  "MISSING_SIRET",
] as const)
export const ERRORS_UNEXPECTED = new Set([
  "PHARMACY_NOT_FOUND",
  "PRIMARY_HOLDER_NOT_FOUND",
  "MULTIPLE_PRIMARY_HOLDERS",
  "SIGNATURE_REQUEST_ALREADY_EXISTS",
] as const)

export type SignatoryStatus = "SENDING" | "UNSIGNED" | "SIGNED" | "REFUSED" | "ERROR"

export type ApiSignatoriesSummary = {
  id: string
  name: string
  isGeneratable: boolean
  // Counts
  receiversCount: number
  errorCount: number
  unsignedCount: number
  signedCount: number
  refusedCount: number
}

import { Yup, type YupTypes } from "@pharmupp/p3-front-commons"
import { add } from "date-fns"
import { removeTime } from "../../../../core"

export const schema = Yup.object({
  publishLater: Yup.boolean(),
  publicationDate: Yup.date().when(
    "publishLater",
    ([publishNow]: boolean[], schemaRef) =>
      publishNow === true
        ? schemaRef
            .min(
              removeTime(new Date()),
              "La date de publication doit être superieure à la date du jour",
            )
            .required("Une date de publication est requise")
        : schemaRef,
  ),
  hasDeactivation: Yup.boolean(),
  deactivationDate: Yup.date()
    .when("hasDeactivation", ([hasDeactivation]: boolean[], schemaRef) =>
      hasDeactivation === true
        ? schemaRef
            .min(
              new Date(),
              "La date de désactivation doit être superieure à la date du jour",
            )
            .required("Une date de désactivation est requise")
        : schemaRef,
    )
    .when("publicationDate", ([publicationDate]: (Date | undefined)[], schemaRef) =>
      publicationDate !== undefined
        ? schemaRef.min(
            add(publicationDate, { days: 1 }),
            "La date de désactivation doit être ultérieure à la date de publication",
          )
        : schemaRef,
    ),
  title: Yup.string().required("Un titre est requis"),
  bannerFile: Yup.object().required("Une image est requise"),
  externalLink: Yup.string().url("L'URL doit être valide"),
})

export type GeneralInfosType = YupTypes.InferType<typeof schema>

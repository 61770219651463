import { Button } from "@mui/material"
import { Suspense, useState } from "react"
import { useTemplateSummaryApi } from "../../Template/useTemplateSummaryApi"
import { AddSignatoryModal } from "../AddSignatoryModal"

export const AddSignatoryButton = ({ templateId }: { templateId: string }) => {
  return (
    <Suspense fallback={null}>
      <AddSignatoryButtonInner templateId={templateId} />
    </Suspense>
  )
}

const AddSignatoryButtonInner = ({ templateId }: { templateId: string }) => {
  const { summary } = useTemplateSummaryApi({ templateId })
  const [addSignatoryModalOpen, setAddSignatoryModalOpen] = useState(false)

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setAddSignatoryModalOpen(true)}
        disabled={!summary.isGeneratable}
      >
        Ajouter un destinataire
      </Button>

      {/* MODALS */}
      {addSignatoryModalOpen && (
        <AddSignatoryModal
          onClose={() => setAddSignatoryModalOpen(false)}
          templateId={templateId}
        />
      )}
    </>
  )
}

import {
  Box,
  Card,
  CardActionArea,
  type CardProps,
  CircularProgress,
} from "@mui/material"

export const LoadingCard = ({
  loading,
  onClick,
  sx,
  children,
}: { loading: boolean } & CardProps) => (
  <Card sx={{ ...sx, position: "relative" }}>
    {loading && (
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
        sx={{ inset: 0 }}
      >
        <CircularProgress sx={{ color: "#666" + "!important" }} />
      </Box>
    )}
    <CardActionArea
      component={Box}
      onClick={onClick}
      sx={(theme) => ({
        transition: theme.transitions.create("filter"),
        ...(loading && {
          filter: "opacity(0.5)",
        }),
      })}
    >
      {children}
    </CardActionArea>
  </Card>
)

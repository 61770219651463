import { Chip, type ChipProps, Stack, Typography, styled } from "@mui/material"

export const STATUS_COLOR = {
  SENDING: "grey.light",
  ERROR: "common.orange",
  REFUSED: "common.orangeDark2",
  UNSIGNED: "#5496e3",
  SIGNED: "success.main",
}

export const InfoCount = ({
  type,
  value,
}: {
  type: "receivers" | "error" | "unsigned" | "signed" | "refused"
  value: number
}) => {
  switch (type) {
    case "receivers":
      return (
        <InfoCountChip
          label="Destinataires"
          count={value}
          chipProps={{
            sx: { borderColor: "primary.dark1", color: "common.darkBlue" },
          }}
        />
      )
    case "error":
      return (
        <InfoCountChip
          label="Erreur"
          count={value}
          chipProps={{
            sx: { borderColor: STATUS_COLOR.ERROR, color: STATUS_COLOR.ERROR },
          }}
        />
      )
    case "unsigned":
      return (
        <InfoCountChip
          label="À signer"
          count={value}
          chipProps={{
            sx: { borderColor: STATUS_COLOR.UNSIGNED, color: STATUS_COLOR.UNSIGNED },
          }}
        />
      )
    case "signed":
      return (
        <InfoCountChip
          label="Signé"
          count={value}
          chipProps={{
            variant: "filled",
            sx: { bgcolor: STATUS_COLOR.SIGNED, color: "white" },
          }}
        />
      )
    case "refused":
      return (
        <InfoCountChip
          label="Refusé"
          count={value}
          chipProps={{
            sx: { borderColor: STATUS_COLOR.REFUSED, color: STATUS_COLOR.REFUSED },
          }}
        />
      )
  }
}

const InfoCountChip = ({
  label,
  count,
  chipProps,
}: { label: string; count: number; chipProps: ChipProps }) => {
  return (
    <Stack alignItems="center" gap={1} width="100%">
      <Label>{label}</Label>
      <Chip
        label={count}
        variant="outlined"
        {...chipProps}
        sx={{ ...chipProps.sx, minWidth: "66px", width: "100%" }}
      />
    </Stack>
  )
}

export const Label = styled(Typography)(({ theme: { typography, palette } }) => ({
  ...typography["10px"],
  color: palette.primary.light1,
}))

import { TableCell, TableRow, alpha } from "@mui/material"
import { formatPercentage, formatPrice } from "@pharmupp/p3-front-commons"
import type { FC } from "react"
import type { LabValues, MonthKey, MonthlyValues } from "./useCoaliaStatsApi"

export const PriorityRow = (props: Omit<BaseLabRowProps, "isPriority">) => (
  <BaseLabRow {...props} isPriority />
)
export const DefaultRow = (
  props: Omit<BaseLabRowProps, "getValueBgColor" | "isEven" | "valueKey">,
) => <BaseLabRow {...props} valueKey="turnover" />
export const TotalRow = (
  props: Omit<BaseLabRowProps, "bold" | "valueKey" | "getValueBgColor" | "isEven">,
) => (
  <BaseLabRow
    {...props}
    rowBgColor={["#dae1ef", "#c8d4e7"]}
    valueKey="turnover"
    bold
  />
)

interface BaseLabRowProps {
  name: string
  values: LabValues
  valueKey: keyof MonthlyValues
  getValueBgColor?: (value: number) => string
  rowBgColor?: [string, string]
  isPriority?: boolean
  isEven?: boolean
  bold?: boolean
}
const BaseLabRow: FC<BaseLabRowProps> = ({
  name,
  isPriority = false,
  values,
  valueKey,
  rowBgColor,
  getValueBgColor,
  isEven = false,
  bold = false,
}) => {
  const valueFormatter = (value: number) =>
    valueKey === "goalPercent" || valueKey === "lastYearTurnoverPercent"
      ? formatPercentage(value)
      : formatPrice(value)

  return (
    <TableRow
      sx={{
        "&:nth-of-type(2n)": {
          bgcolor: rowBgColor?.[0],
        },
        "&:nth-of-type(2n+1)": {
          bgcolor: rowBgColor?.[1],
        },
        bgcolor: rowBgColor,
        mixBlendMode: "multiply",
      }}
    >
      <LabName name={name} isPriority={isPriority} isEven={isEven} />
      {Array.from({ length: 12 }).map((_, index) => {
        const monthName = new Date(1970, index)
          .toLocaleString("en-US", { month: "long" })
          .toUpperCase() as MonthKey
        const value = values[monthName]?.[valueKey]
        return (
          <TableCell
            key={index}
            align="right"
            sx={{
              fontWeight: bold ? 800 : undefined,
              ...(value != null &&
                !!getValueBgColor && {
                  bgcolor: alpha(getValueBgColor(value), 0.6),
                }),
            }}
          >
            {value != null ? valueFormatter(value) : "-"}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const LabName = ({
  name,
  isPriority,
  isEven,
}: {
  name: string
  isPriority: boolean
  isEven: boolean
}) => (
  <TableCell
    sx={({ palette }) => ({
      textTransform: "uppercase",
      mixBlendMode: "multiply",
      fontWeight: 600,
      ...(isPriority && {
        bgcolor: isEven ? palette.primary.dark1 : palette.primary.main,
        color: "white",
      }),
    })}
  >
    {name}
  </TableCell>
)

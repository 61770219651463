import { api, useQuery } from "./http"

export const useSectorsList = ({ enabled }: { enabled: boolean }) => {
  const { data: sectorsList } = useQuery({
    queryKey: ["admin", "cdo", "sectors", "dataref"],
    queryFn: () => api.get<SectorsDatarefApi>("/api/sectors/dataref"),
    select: (rawData) =>
      rawData.content.map((sector) => ({
        code: sector.id,
        label: sector.name,
      })),
    enabled,
  })
  return { sectorsList }
}

interface SectorsDatarefApi {
  content: {
    id: number
    name: string
  }[]
}

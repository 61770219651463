import { api, useQuery } from "@pharmupp/p3-front-commons"
import type { ApiOrder } from "../AdherentOrdersList"

const sdavCategoryEnum = "SDAV"
export const useAdherentSdavChallengeItemOrdersApi = ({
  itemId,
  year,
}: {
  itemId: number
  year: number
}) => {
  // Order page
  const { data: orderPage, isLoading: isReferentialLoading } = useQuery({
    queryKey: ["loyalty", "referential", "categories", sdavCategoryEnum, year],
    queryFn: () =>
      api.get<CategoryReferential>(
        `/api/loyalties/referential/category/${sdavCategoryEnum}?year=${year}`,
      ),
    select: (data) => data.orderPages?.find((page) => page.itemId === itemId),
  })

  // Orders list
  const { data: orders, isLoading: isOrdersLoading } = useQuery({
    queryKey: [
      "offers",
      "my-orders",
      "sdav-challenge",
      orderPage?.startIndex,
      orderPage?.endIndex,
      year,
    ],
    queryFn: () =>
      api.get<ApiOrder[]>(
        `/api/offers/my-orders/sdav-challenge?year=${year}&startIndex=${orderPage?.startIndex}&endIndex=${orderPage?.endIndex}`,
      ),
    enabled: !!orderPage,
  })

  return {
    orders,
    isLoading: isReferentialLoading || isOrdersLoading,
  }
}

interface CategoryReferential {
  orderPages?: OrderPage[] // only for SDAV orders
}

interface OrderPage {
  itemId: number // to match with ChallengeHistory.descriptionItem.id
  startIndex: number
  endIndex: number
}

import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"
import type { RATING_CATEGORY } from "./rating.commons"

export const useRatingSummaryApi = ({ labId }: { labId: string }) => {
  // GET
  const { data: ratingSummary } = useSuspenseQuery({
    queryKey: ["laboratories", "adherent", labId, "rating", "summary"],
    queryFn: () =>
      api.get<ApiAdhLabRatingSummary>(
        `/api/laboratories/adherent/${labId}/rating/summary`,
      ),
  })

  return { ratingSummary }
}

export type ApiAdhLabRatingSummary = Record<
  RATING_CATEGORY,
  {
    averageRating: number | null
    ratingsCount: number
  }
>

import { useCallback, useState } from "react"
import { useToaster } from "../components"
import { triggerFileDownload } from "../utils"
import { API_BASE_URL, fetchInterceptor } from "./http"

export interface FileDownloadOptions {
  /** ⚠️ Without file extension / date */
  name: string
  endpoint: string
  /** Without dot */
  extension?: string
}

export function useFileDownload({
  name,
  endpoint,
  extension = "csv",
}: FileDownloadOptions) {
  const toaster = useToaster()
  const [isFetching, setIsFetching] = useState(false)

  const startDownload = useCallback(async () => {
    setIsFetching(true)

    try {
      const options = await fetchInterceptor.run({
        headers: {
          Accept: "application/octet-stream, application/pdf, text/csv, image/*",
        },
      })
      const res = await fetch(API_BASE_URL + endpoint, options)

      if (res.status === 200) {
        const blob = await res.blob()
        const blobUrl = URL.createObjectURL(blob)

        const contentDisposition = res.headers.get("Content-Disposition")

        if (!contentDisposition) {
          const date = new Date().toLocaleDateString("fr-FR")
          const time = new Date().toLocaleTimeString("fr-FR")
          triggerFileDownload(blobUrl, `${name}_${date}_${time}.${extension}`)
        } else {
          const filename = contentDisposition.split("filename=")[1].replace(/"/g, "")
          triggerFileDownload(blobUrl, filename)
        }
      }
    } catch (err) {
      toaster.error("Erreur lors du téléchargement")
      console.error(err)
    } finally {
      setIsFetching(false)
    }
  }, [extension, name, endpoint, toaster])

  return {
    startDownload,
    isDownloading: isFetching,
  }
}

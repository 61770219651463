import { useTheme } from "@mui/material"
import { formatDateWithDots, getPublicFileUrl } from "@pharmupp/p3-front-commons"
import { useCallback } from "react"
import { getTypeObjectRecord } from "../OfferList/models"
import type { ApiOffer, Offer } from "../types"
import { tagObjectRecord } from "./tags"

export const useApiOffersSelector = () => {
  const theme = useTheme()
  const typeObjectRecord = getTypeObjectRecord(theme)
  return useCallback(
    (apiOffers: ApiOffer[]): Offer[] =>
      apiOffers.map(
        ({
          title,
          tag,
          type,
          startDate,
          endDate,
          cerpRef,
          laboratory,
          ...apiOffer
        }) => {
          const labLogoUrl = laboratory?.logo
            ? getPublicFileUrl(laboratory?.logo)
            : undefined

          return {
            id: cerpRef,
            title,
            offerSource: apiOffer.offerSource,
            startDateFrenchFormat: formatDateWithDots(startDate),
            endDateFrenchFormat: formatDateWithDots(endDate),
            labLogoUrl,
            isCatalog: false,
            isPreorder: apiOffer.isPreorder,
            ...(!!tag && { tag: tagObjectRecord[tag] }),
            ...(!!type && { type: typeObjectRecord[type] }),
            ...(apiOffer.offerSource === "SDAV" && {
              isCatalog: apiOffer.isCatalog,
              labLogoUrl: labLogoUrl || "/logos/sdav.svg",
            }),
            ...(apiOffer.offerSource === "COALIA" && {
              labLogoUrl: labLogoUrl || "/logos/coalia.png",
              maxItems: apiOffer.maxItems,
            }),
            ...(!!apiOffer.lastOrderDate && {
              lastOrderDate: formatDateWithDots(apiOffer.lastOrderDate),
            }),
          }
        },
      ),
    [typeObjectRecord],
  )
}

import {
  Box,
  type BoxProps,
  Button,
  Stack,
  Tooltip,
  Typography,
  type TypographyProps,
  useTheme,
} from "@mui/material"
import { NumberFormat, formatPercentage } from "@pharmupp/p3-front-commons"
import type { ReactNode } from "react"
import { ChevronRight, Clock, Info } from "react-feather"
import { useEvolutionUI } from "./useEvolutionUI"

export const SectionTitle = ({
  title,
  preTitle,
  caption,
}: {
  title: string
  preTitle?: string
  caption?: string
}) => {
  const { typography } = useTheme()
  return (
    <Box color="primary.main" mb={4}>
      <Typography
        fontWeight={600}
        fontSize={typography.pxToRem(11)}
        textTransform="uppercase"
      >
        {preTitle}
      </Typography>
      <Typography
        component="span"
        variant="body2"
        fontWeight={600}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      {!!caption && (
        <Typography component="span" variant="caption">
          - {caption}
        </Typography>
      )}
    </Box>
  )
}

export const NoData = (props: BoxProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100px"
    {...props}
  >
    <Typography fontWeight={500} color="common.darkBlue">
      Aucune donnée
    </Typography>
  </Box>
)

const RawCategoryStats = ({
  color,
  value,
  unit,
}: {
  color: string
  value: number
  unit?: string
}) => (
  <Box border={1} borderColor={color} px={1} borderRadius={2000}>
    <Typography color={color}>
      <NumberFormat
        value={value}
        allowNegative={false}
        prefix={value >= 0 ? "+" : "-"}
        suffix={unit}
      />
    </Typography>
  </Box>
)

// TODO: rename
export const CategoryStats = ({
  evol,
  unit,
  neutral = false,
}: {
  evol: number | null
  unit?: string
  neutral?: boolean
}) => {
  if (neutral || evol == null)
    return <RawCategoryStats color="grey.light" unit={unit} value={evol || 0} />
  return evol >= 0 ? (
    <RawCategoryStats color="success.main" unit={unit} value={evol} />
  ) : (
    <RawCategoryStats color="error.main" unit={unit} value={evol} />
  )
}

const DashboardCTA = ({
  href,
  textLink,
  props,
}: {
  href: string
  textLink: string
  props: BoxProps
}) => {
  const { palette, typography } = useTheme()

  return (
    <Box display="flex" justifyContent="center" {...props}>
      <Button
        component="a"
        href={href}
        variant="text"
        target="_blank"
        endIcon={
          <ChevronRight color={palette.grey.light} size={typography.pxToRem(20)} />
        }
        sx={{ color: "grey.dark" }}
      >
        <Typography fontWeight={500}>{textLink}</Typography>
      </Button>
    </Box>
  )
}

export const OspharmCTA = ({ href, ...props }: BoxProps & { href?: string }) => {
  return (
    <DashboardCTA
      href={href ?? "https://upp.ospharm.org"}
      textLink="Plus de détails sur Ospharm"
      props={props}
    />
  )
}

export const MyDatavizCTA = (props: BoxProps) => {
  return (
    <DashboardCTA
      href={window.CONTEXT.myDataVizUrl}
      textLink="Plus de détails sur MyDataviz"
      props={props}
    />
  )
}

export const InfoTooltip = ({ title }: { title: string }) => {
  const { typography } = useTheme()
  return (
    <Tooltip title={title}>
      <Info color="#c9d0d6" size={typography.pxToRem(27.5)} />
    </Tooltip>
  )
}

export const NoDataWarning = () => {
  const { palette, typography } = useTheme()
  const color = palette.grey.light

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Clock color={color} size={16} />
      <Typography color={color} fontSize={typography.pxToRem(12)} fontStyle="italic">
        En attente de données.
      </Typography>
    </Stack>
  )
}

export const BigKPI = ({
  label,
  value,
  hasValue = true,
}: {
  label: string
  value: ReactNode
  hasValue?: boolean
}) => (
  <Stack spacing={2}>
    <Typography color="primary.dark" fontWeight={600}>
      {label}
    </Typography>
    {hasValue ? (
      <Typography color="common.darkBlue" variant="h3" fontWeight={600}>
        {value}
      </Typography>
    ) : (
      <NoDataWarning />
    )}
  </Stack>
)

export const BigEvolution = ({
  title,
  subtitle,
  evolution,
}: {
  title: string
  subtitle: string
  evolution?: number | null
}) => {
  const evolutionUI = useEvolutionUI(evolution)
  const EvolutionIcon = evolutionUI.Icon

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography
          color="grey.light"
          variant="body2"
          fontWeight={600}
          textTransform="uppercase"
        >
          {title}
        </Typography>
        <Typography color="grey.light" variant="caption">
          {subtitle}
        </Typography>
      </Stack>
      {/* GROWTH */}
      {evolution != null ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <EvolutionIcon color={evolutionUI.color} height={40} width={40} />
          <Typography color="common.darkBlue" variant="h4" fontWeight={600}>
            {`${evolutionUI.prefix} ${formatPercentage(evolution, { maximumFractionDigits: 1, signDisplay: "never" })}`}
          </Typography>
        </Stack>
      ) : (
        <NoDataWarning />
      )}
    </Stack>
  )
}

export const SyncDate = ({
  date,
  ...props
}: { date?: string } & TypographyProps) => {
  const { palette, typography } = useTheme()
  return (
    <Typography
      color={palette.grey.light}
      fontSize={typography.pxToRem(12)}
      textAlign="right"
      {...props}
    >
      Données mises à jour le{" "}
      <Typography component="span" fontSize="inherit" fontWeight={600}>
        {date}
      </Typography>
    </Typography>
  )
}

export const StatCardTitle = ({
  title,
  subtitle,
  titleProps,
  subtitleProps,
}: {
  title: string
  subtitle: string
  titleProps?: TypographyProps
  subtitleProps?: TypographyProps
}) => {
  const { palette, typography } = useTheme()
  return (
    <Stack spacing={0.5}>
      <Typography
        fontSize={typography.pxToRem(16)}
        lineHeight={typography.pxToRem(16)}
        color={palette.grey.light}
        sx={{ hyphens: "auto" }}
        {...titleProps}
      >
        {title}
      </Typography>
      <Typography
        fontSize={typography.pxToRem(11)}
        lineHeight={typography.pxToRem(12)}
        color={palette.common.darkBlue}
        {...subtitleProps}
      >
        {subtitle}
      </Typography>
    </Stack>
  )
}

import {
  Box,
  Card,
  CardActionArea,
  IconButton as MuiIconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { useDialog, useQueryClient } from "@pharmupp/p3-front-commons"
import type { MouseEventHandler } from "react"
import { ChevronRight, MoreHorizontal, Paperclip, Plus, X } from "react-feather"
import {
  type ApiLabAppointment,
  type AppointmentType,
  getAppointmentApiEndpoint,
  getAppointmentQueryKey,
} from "../api/useAppointmentApi"

const AGENDA_BLUE = "#2989ff"
const LIGHT_GREY = "#a4a6b1"

// Lines
export const AppointmentLine = ({
  appointment,
}: { appointment: ApiLabAppointment }) => (
  <RowLayout isShort appointment={appointment} />
)
export const CreateAppointmentLine = () => <RowLayout isShort />

// Cards
export const AppointmentCard = ({
  appointment,
}: { appointment: ApiLabAppointment }) => <LineCard appointment={appointment} />
export const CreateAppointmentCard = () => <LineCard />

interface RawLayoutProps {
  isShort?: boolean
  appointment?: ApiLabAppointment
}

const CHEVRON_CLASS = "appointment-end-chevron"
const RowLayout = ({ isShort = false, appointment }: RawLayoutProps) => {
  const { palette, transitions, typography } = useTheme()

  return (
    <LineWrapper
      sx={{
        transition: transitions.create("opacity"),
        [`.${CHEVRON_CLASS}`]: {
          transition: transitions.create("transform"),
          transform: "translateX(0)",
        },
        "&:hover": {
          opacity: 0.8,
          [`.${CHEVRON_CLASS}`]: {
            transform: "translateX(5px)",
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {/* DATE */}
        {appointment ? <DateBox date={appointment.dateTime} /> : <CreateBox />}

        {/* TITLE */}
        <Title fontWeight={!isShort || appointment ? 600 : 500}>
          {appointment?.title ?? "Nouveau rendez-vous"}
        </Title>

        {/* TYPES */}
        {!isShort && (
          <Stack direction="row" alignItems="flex-end" gap={0.5}>
            <Title>
              {appointment?.types?.length
                ? `- ${appointmentLabel[appointment.types[0]]}`
                : ""}
            </Title>
            {appointment && appointment.types?.length > 1 && (
              <MoreHorizontal
                size={typography.pxToRem(12)}
                color={palette.grey["400"]}
              />
            )}
          </Stack>
        )}
      </Stack>

      {/* END */}
      {!isShort ? (
        <ReportLink appointment={appointment} />
      ) : (
        <ChevronRight
          className={CHEVRON_CLASS}
          size="20px"
          color={palette.primary.dark4}
        />
      )}
    </LineWrapper>
  )
}

const LineWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
})

const Title = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  color: palette.common.darkBlue,
}))

const LineCard = ({ appointment }: RawLayoutProps) => {
  function handleOpenPanel() {
    alert(`🦜 Open panel id here => ${appointment?.id}`)
  }

  return (
    <Card
      sx={{ borderStyle: appointment ? "solid" : "dashed" }}
      onClick={handleOpenPanel}
    >
      <CardActionArea component={Box} sx={{ p: 1, pr: 3 }}>
        <RowLayout appointment={appointment} />
      </CardActionArea>
    </Card>
  )
}

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.error.main,
  },
}))

const ReportLink = ({
  appointment,
}: {
  appointment?: ApiLabAppointment
}) => {
  const {
    palette: { grey },
    typography,
  } = useTheme()
  const dialog = useDialog()
  const queryClient = useQueryClient()
  const { id, hasReport } = appointment || {}

  function refreshAppointment(id: ApiLabAppointment["id"]) {
    const queryKey = getAppointmentQueryKey(id)
    queryClient.invalidateQueries({ queryKey })
  }

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    if (!id) return

    dialog.delete({
      endpoint: getAppointmentApiEndpoint(id),
      onSuccess: () => refreshAppointment(id),
      onError: () =>
        dialog.info({
          title: "Suppression",
          message: "Une erreur est survenue",
        }),
    })
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {/* REPORT DISPLAY */}
      {!hasReport ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <Plus color={grey[400]} />
          <Typography
            sx={{
              textDecoration: "underline",
              color: grey[400],
            }}
          >
            Ajouter un compte rendu
          </Typography>
        </Stack>
      ) : (
        <MuiLink display="flex" alignItems="center" gap={1} color="#81adf8">
          <Paperclip />
          Compte rendu
        </MuiLink>
      )}

      {/* DELETE BTN */}
      {!!appointment && (
        <Tooltip title="Supprimer" placement="top">
          <IconButton onClick={handleDelete}>
            <X size={typography.pxToRem(16)} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

const appointmentLabel: Record<AppointmentType, string> = {
  ANNUAL: "Annuel",
  PROSPECTIVE: "Prospectif",
}

export const Day = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  fontWeight: 600,
  color: AGENDA_BLUE,
}))
export const Month = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  fontWeight: 600,
  color: LIGHT_GREY,
  textTransform: "uppercase",
}))
export const Year = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(10),
  lineHeight: typography.pxToRem(12),
  fontWeight: 600,
  color: LIGHT_GREY,
  textTransform: "uppercase",
}))

const DateBox = ({ date }: { date: string }) => (
  <SquareBox bgcolor="#f6f6fb">
    <Day>{new Date(date).toLocaleDateString("fr-FR", { day: "2-digit" })}</Day>
    <Month>{new Date(date).toLocaleDateString("fr-FR", { month: "short" })}</Month>
    <Year>{new Date(date).toLocaleDateString("fr-FR", { year: "numeric" })}</Year>
  </SquareBox>
)

const CreateBox = () => (
  <SquareBox border="1px solid rgb(237, 240, 247)">
    <Plus color={AGENDA_BLUE} size="20px" />
  </SquareBox>
)

const SquareBox = styled(Box)({
  borderRadius: "2px",
  aspectRatio: 1,
  minHeight: "56px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

import { Box, Card, Stack, Typography, styled } from "@mui/material"
import {
  FullWidthContent,
  FullWidthHeader,
  MainContainer,
  YearTabs,
  get3YearsTabsWithPadding,
} from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { OpeazOperationsSummary } from "./OpeazOperationsSummary"
import { AdhMarketingOpSummary } from "./OperationsSummary/OperationsSummary"

export const OperationsDashboard = () => {
  const [year, setYear] = useState(new Date().getFullYear())

  return (
    <MainContainer>
      <FullWidthContent>
        <FullWidthHeader title="Opérations" />

        <Card component={Box} px={5} pt={3} pb={28}>
          <Stack gap={8}>
            {/* OPEAZ */}
            <Section
              title="Mes OP Conso by Opeaz"
              description="Inscrivez-vous aux opérations commerciales, BRI, TG pour obtenir de la coopération commerciale des laboratoires"
              tags={["BRI", "TG"]}
            >
              <OpeazOperationsSummary />
            </Section>

            {/* PHARM UPP */}
            <Section
              title="Mes OP Challenges by Pharm-UPP"
              description="Inscrivez-vous aux opérations Métier, Offres du moment et Vasque de Pharm-UPP et cumulez des points Challenge"
              tags={["Métier", "Moment", "Vasque"]}
            >
              <YearTabs years={yearTabs} onChange={setYear} selectedYear={year} />
              <AdhMarketingOpSummary year={year} listLink="./liste" />
            </Section>
          </Stack>
        </Card>
      </FullWidthContent>
    </MainContainer>
  )
}

const yearTabs = get3YearsTabsWithPadding()

// Sections

const Section = ({
  title,
  description,
  tags,
  children,
}: {
  title: string
  description: string
  tags: string[]
  children?: React.ReactNode
}) => {
  return (
    <Stack gap={3}>
      <div>
        <Box display="flex" alignItems="baseline">
          <SectionTitle>{title}&nbsp;-&nbsp;</SectionTitle>
          <SectionTags>{tags.join(", ")}</SectionTags>
        </Box>
        <SectionDescription>{description}</SectionDescription>
      </div>
      {children}
    </Stack>
  )
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 500,
  color: theme.palette.primary.dark1,
}))
const SectionTags = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.common.electricBlue,
}))
const SectionDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.common.darkBlue,
}))

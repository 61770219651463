import { Event } from "@mui/icons-material"
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  Divider,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { Link, TinyChip } from "@pharmupp/p3-front-commons"
import { ChevronRight } from "react-feather"
import {
  type ApiNotificationTag,
  type Notification,
  useMyNotifications,
} from "./useMyNotifications"

const sizeChoice = 3

export const NotificationsWidget = ({ adherentId }: { adherentId?: string }) => {
  const { list, count, isLoading } = useMyNotifications({
    size: sizeChoice,
    adherentId,
  })
  const isAdherent = !adherentId

  return (
    <Card p={3} spacing={3} component={Stack}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title>Actions à mener</Title>
        <Badge
          badgeContent={count}
          sx={{ ".MuiBadge-badge": { bgcolor: "#f9b000" }, color: "white" }}
        />
      </Box>
      {isLoading || !list ? (
        <Stack spacing={1.5}>
          {Array.from({ length: sizeChoice }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={84} />
          ))}
        </Stack>
      ) : (
        <>
          {list.length === 0 ? (
            <Typography color="grey.light">Aucune action</Typography>
          ) : (
            <Stack justifyContent="space-between" height="100%">
              <Box display="grid" gridTemplateColumns="1fr" gap={1.5}>
                {list.map((notification, index) => (
                  <NotificationCard
                    key={index}
                    notification={{
                      ...notification,
                      link: isAdherent ? notification.link : null,
                    }}
                    compact
                  />
                ))}
              </Box>

              {/* VIEW ALL LINK */}
              {!!isAdherent && (
                <Link to="/accueil/notifications" underline="hover" mt={3}>
                  <Stack direction="row" justifyContent="right" alignItems="center">
                    <Typography
                      fontSize={(theme) => theme.typography.pxToRem(12)}
                      textTransform="uppercase"
                    >
                      Voir plus
                    </Typography>
                    <ChevronRight size="14px" />
                  </Stack>
                </Link>
              )}
            </Stack>
          )}
        </>
      )}
    </Card>
  )
}

const getColorLine = (criticality: string) => {
  switch (criticality) {
    case "HIGH":
      return "#eb5735"
    case "MEDIUM":
      return "#f9b000"
  }
}

export function NotificationCard({
  notification,
  compact = false,
}: {
  notification: Notification
  compact?: boolean
}) {
  const theme = useTheme()
  return (
    <Card sx={{ border: "none", minHeight: "110px" }}>
      <CardActionArea
        {...(notification.link && {
          component: Link,
          to: notification.link,
        })}
        sx={{
          height: "100%",
          border: `1px solid ${getColorLine(notification.criticality)}`,
          borderRadius: "10px",
          "& .MuiTouchRipple-child": {
            backgroundColor: `${getColorLine(notification.criticality)} !important`,
          },
          pointerEvents: notification.link ? "auto" : "none",
        }}
      >
        <Stack
          height="100%"
          px={2}
          pt={0.5}
          pb={1.5}
          justifyContent="space-between"
          sx={{
            borderLeft: `9px solid ${getColorLine(notification.criticality)}`,
            borderRadius: "8px",
          }}
        >
          <div>
            {getTag(notification.tag, compact)}

            <TexTitle>{notification.title}</TexTitle>
            <TexSubTitle mb={1}>{notification.description}</TexSubTitle>
          </div>

          <div>
            {!compact && <Divider sx={{ mt: 1, mb: 1 }} />}

            <Box display="flex" justifyContent="space-between">
              {/* DATE */}
              <TextDate display="flex" justifyContent="start" alignItems="center">
                <Event sx={{ fontSize: 17, color: "#f9b000", marginRight: "7px" }} />{" "}
                Jusqu&apos;au {notification.deadline}
              </TextDate>

              {/* CTA */}
              {!compact && (
                <Box display="flex" alignItems="center" gap={0.2}>
                  <Typography
                    fontSize={theme.typography.pxToRem(12)}
                    textTransform="uppercase"
                  >
                    {notification.linkText}
                  </Typography>
                  <ChevronRight size="13px" style={{ marginTop: "-1px" }} />
                </Box>
              )}
            </Box>
          </div>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

function getTag(tag: ApiNotificationTag | undefined, compact: boolean) {
  switch (tag) {
    case "PROOF_DOCUMENT_REFUSED":
      return (
        <TinyChip
          variant="outlined"
          label="Justificatif refusé"
          size="small"
          backgroundColor="#e69a9a"
        />
      )
    case "E_SIGNATURE":
      return (
        <TinyChip
          variant="outlined"
          label="e-signature"
          size="small"
          backgroundColor="#a1b4d6"
        />
      )
    default:
      return compact ? null : (
        <TinyChip
          variant="outlined"
          label="placholder"
          size="small"
          sx={{ opacity: 0 }}
        />
      )
  }
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textTransform: "uppercase",
  color: theme.palette.grey.dark,
}))
const TexTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: theme.palette.common.darkBlue,
}))
const TexSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  lineHeight: theme.typography.pxToRem(14),
  color: theme.palette.common.darkBlue,
}))
const TextDate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: "#a9afb8",
}))

import {
  Box,
  Button,
  Stack,
  TableCell,
  Typography,
  type TypographyProps,
  styled,
} from "@mui/material"
import {
  type ColumnRenderDefinition,
  SortedTableCell,
  formatPrice,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { X } from "react-feather"
import { LaboratoryChip, ProductLabel } from "../../../../common"
import type { Laboratory } from "../../useAdherentOrdersReferential"
import type { OrderRow } from "./OrderTable"

export const columns = {
  dateAndNumber: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="orderDate" width={width}>
        Date
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        {!!row.orderDate && (
          <Stack>
            <CellLine>
              {row.showDateYear
                ? new Date(row.orderDate).toLocaleDateString("fr-FR")
                : `Le ${new Date(row.orderDate).toLocaleDateString("fr-FR", {
                    day: "2-digit",
                    month: "long",
                  })}`}
            </CellLine>
            <Typography
              fontSize={(theme) => theme.typography.pxToRem(11)}
              color="text.secondary"
            >
              {new Date(row.orderDate).toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Stack>
        )}
        <Stack
          color={
            !!row.displayCancelled && !!row.cancelled ? "common.red" : "primary.main"
          }
          fontSize={(theme) => theme.typography.pxToRem(11)}
        >
          <CellLine fontSize="inherit" sx={{ textDecoration: "underline" }}>
            N°: {row.orderNumber}
          </CellLine>
          {!!row.displayCancelled && !!row.cancelled && (
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" ml={-0.5}>
                <X size="14px" />
              </Box>
              <Typography fontSize="inherit" textTransform="uppercase">
                Annulée
              </Typography>
            </Box>
          )}
        </Stack>
      </TableCell>
    ),
  },

  pharmacy: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="adherentName" width={width}>
        Officine
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        {row.adherentName ? (
          <Stack spacing={0.25}>
            <CellLine fontWeight={600}>{row.adherentName}</CellLine>
            <CellLine>{row.primaryHolderName}</CellLine>
            <CellLine color="grey.dark">ID : {row.businessCentralId}</CellLine>
          </Stack>
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  upp: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="upps" width={width}>
        UPP
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        {row.upp ? (
          <>
            <CellLine fontWeight={600}>{row.upp?.name}</CellLine>
            <CellLine>{row.city}</CellLine>
          </>
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },

  divider: {
    renderHeader: ({ width }) => <TableCell align="center" width={width} />,
    renderRow: () => (
      <TableCell align="center" sx={{ position: "relative" }}>
        <Separator />
      </TableCell>
    ),
  },

  laboratory: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="laboratory" width={width}>
        Laboratoire
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="left">
        <LaboratoryCell laboratory={row.laboratory} />
      </TableCell>
    ),
  },

  offer: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="offer" width={width}>
        Offre
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        <ProductLabel
          title={row.offer.title}
          source={row.offerSource}
          subtitle={`ID : ${row.offer.cerpRef}`}
          tag={
            row.offerTag
              ? {
                  name: row.offerTag.label,
                  checked: row.unlocksChallenge,
                  chipVariation: row.offerTag.chipVariation,
                }
              : undefined
          }
        />
      </TableCell>
    ),
  },

  total: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="total" width={width}>
        Montant
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell
        sx={{
          fontWeight: !!row.displayCancelled && !!row.cancelled ? "initial" : "bold",
        }}
      >
        {formatPrice(row.total)}
      </TableCell>
    ),
  },

  action: {
    renderHeader: ({ width }) => <TableCell width={width} />,
    renderRow: () => (
      <TableCell>
        <Button variant="contained" size="small">
          Modifier
        </Button>
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<OrderRow>>

export const LaboratoryCell = ({
  laboratory,
}: {
  laboratory: Laboratory | null
}) => {
  const labLogoUrl = laboratory?.logo?.tempFileId
    ? getPublicFileUrl(laboratory.logo)
    : undefined

  return laboratory ? (
    labLogoUrl ? (
      <img
        src={labLogoUrl}
        style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "65px" }}
        alt="Logo laboratoire"
      />
    ) : (
      <LaboratoryChip label={laboratory.name ?? laboratory.id} />
    )
  ) : (
    <>-</>
  )
}

/** Dion */
const CellLine = ({ children, ...props }: TypographyProps) => (
  <Typography
    fontSize="inherit"
    lineHeight={(theme) => theme.typography.pxToRem(14)}
    {...props}
  >
    {children}
  </Typography>
)

const Separator = styled("div")(({ theme: { palette, spacing } }) => ({
  position: "absolute",
  top: spacing(2),
  bottom: spacing(2),
  width: "1px",
  backgroundColor: palette.grey.extraLight,
}))

import { Box, Collapse, Link, Stack, useTheme } from "@mui/material"
import { AdherentSdavOrdersList } from "@pharmupp/p3-offers-domain"
import { useState } from "react"
import { ChevronRight } from "react-feather"
import {
  HistoryLineBonus,
  HistoryLineBox,
  HistoryLineDate,
  HistoryLineTitle,
  type SdavHistory,
} from "../commons"

type SdavHistoryLineProps = {
  date: string
  year: number | null
  title: string
  bonus: number
  itemId?: number
} & SdavHistory
export const SdavHistoryLine = ({
  date,
  year,
  title,
  bonus,
  itemId,
  nbItemsRelative,
  nbItemsRelativeGoal,
}: SdavHistoryLineProps) => {
  const [opened, setOpened] = useState(false)
  const isPending = nbItemsRelative !== nbItemsRelativeGoal

  return (
    <Stack>
      <HistoryLineBox display="grid" gridTemplateColumns="1fr 3fr 1fr 1fr">
        <HistoryLineDate date={date} />
        <HistoryLineTitle bonus={bonus} title={title} isPending={isPending} />
        <HistoryLineBonus
          bonus={bonus}
          isPending={isPending}
          nbItemsRelative={nbItemsRelative}
          nbItemsRelativeGoal={nbItemsRelativeGoal}
        />

        {nbItemsRelative !== undefined && nbItemsRelative > 0 && (
          <ViewMoreLink
            isActive={opened}
            onClick={() => setOpened((currValue) => !currValue)}
            nbItemsRelative={nbItemsRelative}
            nbItemsRelativeGoal={nbItemsRelativeGoal}
          />
        )}
      </HistoryLineBox>
      <Collapse in={opened} mountOnEnter unmountOnExit>
        <Box pb={1.5}>
          {itemId !== undefined && !!year && (
            <AdherentSdavOrdersList
              itemId={itemId}
              nbItems={nbItemsRelative!}
              year={year}
            />
          )}
        </Box>
      </Collapse>
    </Stack>
  )
}

const ViewMoreLink = ({
  isActive = false,
  onClick,
  nbItemsRelative,
  nbItemsRelativeGoal,
}: {
  isActive: boolean
  onClick: () => void
  nbItemsRelative?: number
  nbItemsRelativeGoal?: number
}) => {
  const { palette, transitions, typography } = useTheme()
  return (
    <Link
      onClick={onClick}
      fontSize={typography.pxToRem(14)}
      color={palette.grey.dark}
      underline="always"
      justifySelf="right"
      sx={{ cursor: "pointer", userSelect: "none" }}
    >
      <Box display="flex" alignItems="center" gap={0.5}>
        {nbItemsRelative !== nbItemsRelativeGoal
          ? "Détail"
          : `Voir les ${nbItemsRelative} BdC`}
        <ChevronRight
          color={palette.grey.light}
          style={{
            transform: isActive ? "rotate(90deg)" : "rotate(0)",
            transition: transitions.create("transform", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shortest,
            }),
          }}
        />
      </Box>
    </Link>
  )
}

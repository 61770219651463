import { type ReactNode, createContext } from "react"
import type { SsoService } from "../../sso/keycloak"

export interface AppContextShape {
  sso: SsoService
}

/**
 * ⚠️ Beware, every change creates an app rerender
 */
export const AppContext = createContext<AppContextShape>(undefined!)

export const AppContextProvider = ({
  sso,
  children,
}: Pick<AppContextShape, "sso"> & { children: ReactNode }) => (
  <AppContext.Provider
    value={{
      sso,
    }}
  >
    {children}
  </AppContext.Provider>
)

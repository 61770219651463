import { Box, Typography, styled } from "@mui/material"
import { Rating, formatNumber } from "@pharmupp/p3-front-commons"

// GLOBAL RATING

export const GlobalRating = ({
  averageValue,
  ratingsCount,
}: { averageValue?: number | null; ratingsCount: number }) => (
  <GlobalRatingLine>
    <GlobalRatingValue value={averageValue} />
    <Rating value={averageValue} max={5} size="extraLarge" />
    <Typography variant="19px" fontWeight={500} color="#91a2c3">
      ({ratingsCount} avis)
    </Typography>
  </GlobalRatingLine>
)

export const GlobalRatingLine = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "60px 190px auto",
  alignItems: "center",
  gap: theme.spacing(1),
}))

export const GlobalRatingValue = ({ value }: { value?: number | null }) => (
  <Typography variant="30px" fontWeight={600} color="primary.dark1">
    {value != null ? formatNumber(value) : "-"}
  </Typography>
)

// CATEGORY

export const ScoreLine = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "190px 1fr",
  alignItems: "center",
  columnGap: theme.spacing(3),
  width: "100%",
}))

export const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.dark1,
}))
CategoryTitle.defaultProps = { variant: "14px" }

export const getScoreLabel = (score?: number | null) => {
  switch (score ? Math.floor(score) : undefined) {
    case 1:
      return "Très insatisfait"
    case 2:
      return "Insatisfait"
    case 3:
      return "Satisfait"
    case 4:
      return "Très satisfait"
    case 5:
      return "Top!"
    default:
      return null
  }
}

import { Yup, type YupTypes, inputFileSchema } from "@pharmupp/p3-front-commons"
import type { LabOrExternChoice } from "../../../../core"
import { PostRubric } from "../../../model"
import { fields } from "../../postForm.const"

export const schema = Yup.object({
  publishLater: Yup.boolean(),
  publicationDate: Yup.date().when(
    fields.publishLater,
    ([publishNow]: boolean[], schemaRef) =>
      publishNow === true ? schemaRef.required() : schemaRef,
  ),
  rubric: Yup.string<PostRubric>().required(),
  category: Yup.object({
    label: Yup.string().required(),
    code: Yup.string().required(),
  })
    .default(undefined)
    .notRequired()
    .when(fields.rubric, ([rubric]: string[], schemaRef) =>
      rubric === PostRubric.UPP_GROUP ? schemaRef.required() : schemaRef,
    ),
  labOrExtern: Yup.string<LabOrExternChoice>().when(
    fields.rubric,
    ([rubric], schemaRef) =>
      rubric === PostRubric.LAB_EXTERN ? schemaRef.required() : schemaRef,
  ),
  laboratoryId: Yup.number().when(
    [fields.rubric, "labOrExtern"],
    ([rubric, labOrExtern], schemaRef) =>
      rubric === PostRubric.LAB_EXTERN && labOrExtern === "laboratory"
        ? schemaRef.required()
        : schemaRef,
  ),
  solutionId: Yup.number().when(
    [fields.rubric, "labOrExtern" as const],
    ([rubric, labOrExtern], schemaRef) =>
      rubric === PostRubric.LAB_EXTERN && labOrExtern === "solution"
        ? schemaRef.required()
        : schemaRef,
  ),
  title: Yup.string().max(100).required(),
  description: Yup.string().required(),
  image: inputFileSchema.default(undefined).notRequired(),
  isFlash: Yup.boolean().required(),
  restrictedUppIds: Yup.array().of(Yup.number().required()),
})

export type GeneralInfosValues = YupTypes.InferType<typeof schema>

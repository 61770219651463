import { Box, type BoxProps, Stack, Typography, styled } from "@mui/material"
import type { DropzoneInputProps } from "react-dropzone"
import { PlusCircle } from "react-feather"

type DropZoneProps = {
  label: string
  inputProps: DropzoneInputProps
  acceptedExtensions: string[]
  focused: boolean
  hasError: boolean
  maxFilesCount?: number
  maxFileSize?: number
  error?: string
  boxProps?: BoxProps
}

export const DropZone = ({
  label,
  inputProps,
  acceptedExtensions,
  maxFilesCount,
  maxFileSize,
  error,
  boxProps,
}: DropZoneProps) => (
  <Stack spacing={1.5}>
    <DropBox {...boxProps}>
      <input {...inputProps} />
      <IconWrapper>
        <PlusCircle size={47} />
      </IconWrapper>
      <Label>{label}</Label>
      {maxFilesCount !== undefined && (
        <SizeCaption>
          {`${maxFilesCount} ${maxFilesCount > 1 ? "fichiers" : "fichier"} maximum`}
        </SizeCaption>
      )}
      <ErrorCaption>{error}</ErrorCaption>
    </DropBox>

    <ValidationDetails>
      {`Formats ${acceptedExtensions.join(", ")}`}&nbsp;
      {maxFileSize !== Number.POSITIVE_INFINITY && `(${maxFileSize} Mo maximum)`}
    </ValidationDetails>
  </Stack>
)

const DropBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "focused" && propName !== "hasError",
})<{ focused?: boolean; hasError?: boolean }>(
  ({ theme, focused = false, hasError = false }) => ({
    border: `2px dashed  ${theme.palette.common.electricBlue}`,
    borderRadius: "8px",
    padding: `${theme.spacing(6)} ${theme.spacing(2)}`,
    textAlign: "center",
    cursor: "pointer",
    ...(focused && {
      borderColor: theme.palette.secondary.main,
      svg: {
        color: theme.palette.secondary.main,
      },
    }),
    ...(hasError && {
      borderColor: theme.palette.error.main,
    }),
    transition: theme.transitions.create("borderColor"),
  }),
)

const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.light1,
}))

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.grey.dark,
}))

const SizeCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.dark,
}))

const ErrorCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.error.main,
}))

const ValidationDetails = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.light,
}))

import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  styled,
  tabClasses,
  tabsClasses,
} from "@mui/material"
import { type UseScrollSpyArgs, scrollToId, useScrollSpy } from "../../../hooks"
import { STICKY_CLASS } from "./AppSubHeader"

/**
 * Secondary nav with:
 * - ScrollSpy (set active item depending on scroll position)
 * - Scroll animation
 */
export const AppSubNav = ({ items }: Pick<UseScrollSpyArgs, "items">) => {
  const active = useScrollSpy({ items, itemsSpacing: 24, detectionMargin: 113 })
  const activeIndex = active ? items.findIndex((item) => item.id === active) : false

  return (
    <Tabs value={activeIndex || 0}>
      {items.map(({ label, id }, index) => (
        <Tab
          key={index}
          component="a"
          label={label}
          onClick={() => scrollToId(id)}
        />
      ))}
    </Tabs>
  )
}

const Tabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: "auto",
  [`& .${tabsClasses.flexContainer}`]: {
    gap: theme.spacing(3),
  },
  [`& .${tabsClasses.indicator}`]: {
    height: "3px",
  },
}))

const Tab = styled(MuiTab)(({ theme: { palette, typography, spacing } }) => ({
  padding: spacing(1, 1.5),
  fontSize: typography.pxToRem(16),
  [`.${STICKY_CLASS} &`]: {
    minHeight: "48px",
  },
  minHeight: "auto",
  [`&.${tabClasses.selected}`]: {
    backgroundColor: palette.common.white,
  },
})) as typeof MuiTab

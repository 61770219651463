import * as qr from "@bitjson/qr-code"
import { useTheme } from "@mui/material"
import { type CSSProperties, type ReactNode, useEffect } from "react"

import { QrCodeIcon } from "./QrCodeIcon"

declare global {
  namespace JSXElements {
    interface QrCodeAttributes {
      children: ReactNode
      style: CSSProperties
    }
  }
}

export const QrCodeLink = ({ link }: { link: string }) => {
  const { palette } = useTheme()

  useEffect(() => {
    qr.defineCustomElements(window)
  }, [])

  return (
    <qr-code
      contents={link}
      module-color={palette.primary.dark1}
      position-ring-color={palette.primary.dark2}
      position-center-color={palette.primary.main}
      mask-x-to-y-ratio={1}
      style={{
        width: "200px",
        height: "200px",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <QrCodeIcon slot="icon" color={palette.primary.main} />
    </qr-code>
  )
}

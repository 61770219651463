import {
  type ColumnDefinition,
  ROLES,
  Table,
  type TableProps,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useNavigate } from "react-router-dom"
import { AdherentDefaultFilters } from "../defaultFilters"
import type { ApiAdherentLoyaltyList } from "./types"

export const AdherentsLoyaltyList = ({
  queryConfig,
  exportButtonProps,
  columns,
  extraToolbarElements,
}: { columns: ColumnDefinition<ApiAdherentLoyaltyList>[] } & Pick<
  TableProps<ApiAdherentLoyaltyList, ApiAdherentLoyaltyList>,
  "queryConfig" | "exportButtonProps" | "extraToolbarElements"
>) => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)
  const navigate = useNavigate()

  return (
    <Table<ApiAdherentLoyaltyList>
      queryConfig={queryConfig}
      exportButtonProps={exportButtonProps}
      extraToolbarElements={extraToolbarElements}
      FilterSectionElement={<AdherentDefaultFilters />}
      emptyMessage="Pas encore de pharmacie"
      columns={columns}
      onRowClick={(row) =>
        navigate(`/upp-et-utilisateurs/pharmacies/challenges/${row.id}`)
      }
      rowClickCondition={() => isAdmin}
    />
  )
}

import type { ReactNode } from "react"
import { AlertCircle } from "react-feather"
import type { Icon as FeatherIcon } from "react-feather"
import type { MergeExclusive } from "type-fest"
import { Dialog } from "../Dialog"

export type InfoDialogProps = {
  open: boolean
  title: ReactNode
  message: string | React.JSX.Element
  onClickClose: () => void
  validateText?: string
  width?: string
} & MergeExclusive<{ Icon?: FeatherIcon }, { iconSvgSrc?: string }>

export const InfoDialog = ({
  open,
  title,
  message,
  onClickClose,
  validateText,
  width,
  Icon,
  iconSvgSrc,
}: InfoDialogProps) => {
  const propsIcon = iconSvgSrc ? { iconSvgSrc } : { Icon: Icon ?? AlertCircle }
  return (
    <Dialog
      {...propsIcon}
      title={title}
      validateAction={onClickClose}
      validateText={validateText ?? "OK"}
      open={open}
      loading={false}
      onClose={onClickClose}
      width={width}
    >
      {message}
    </Dialog>
  )
}

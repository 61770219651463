import { Button, InputAdornment, TextField, styled } from "@mui/material"
import type { ChangeEvent } from "react"
import {
  Controller,
  type FieldError,
  type FieldPath,
  type FieldValues,
} from "react-hook-form"

type FormFileSimpleFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  accept: string
  required?: boolean
  error?: FieldError
} & React.HTMLProps<HTMLInputElement>

export const FormFileSimpleField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  required,
  accept,
}: FormFileSimpleFieldProps<TFieldValues>) => (
  <Controller
    name={name}
    render={({ field: rawField, fieldState: { error } }) => {
      const { value, ...field } = rawField
      return (
        <FileField
          {...field}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value) {
              return field.onChange(event.target.files?.[0])
            }
          }}
          type="file"
          placeholder={`Choisir un fichier${required ? "*" : ""}`}
          fullWidth
          error={!!error}
          helperText={error?.message}
          required={required}
          slotProps={{
            htmlInput: { accept, sx: { cursor: "pointer" } },
            input: {
              sx: { paddingRight: RIGHT_PADDING },
              endAdornment: (
                <InputAdornment
                  position="end"
                  disablePointerEvents
                  sx={{ position: "absolute", right: RIGHT_PADDING }}
                >
                  <BrowseButton variant="contained" component="label">
                    Parcourir
                  </BrowseButton>
                </InputAdornment>
              ),
            },
          }}
        />
      )
    }}
  />
)

const RIGHT_PADDING = "4px"

const FileField = styled(TextField)({
  "& input::file-selector-button": { display: "none" },
}) as typeof TextField

const BrowseButton = styled(Button)({
  borderRadius: "4px",
}) as typeof Button

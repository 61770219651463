import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  styled,
} from "@mui/material"
import { type SyntheticEvent, useEffect, useRef, useState } from "react"
import { X } from "react-feather"
import {
  Controller,
  type FieldError,
  type FieldPath,
  type FieldValues,
  useFormContext,
} from "react-hook-form"
import type { UploadedFile } from "../../../../types"
import { useFieldRoleConf } from "../../../context"
import { useUploadFile } from "./fileUploadService"

export type FormFileFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  maxSizeInMo?: number
  onFileClick?: (file: UploadedFile) => Promise<File> | File
  onFileInput?: (file: File) => Promise<File> | File
  error?: FieldError
  required?: boolean
} & React.HTMLProps<HTMLInputElement>

/**
 * An input file field that uploads the file to the server and returns the uploaded file.
 */
export const FormFileField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  onFileInput,
  onFileClick,
  maxSizeInMo,
  error: parentError,
  required = false,
  ...otherProps
}: FormFileFieldProps<TFieldValues>) => {
  const methods = useFormContext()
  const fieldConf = useFieldRoleConf(name)
  const [fileName, setFileName] = useState("")
  const fileInput = useRef<HTMLInputElement | null>(null)

  const { upload, reset, ...uploadState } = useUploadFile({
    url: window.CONTEXT.uploadTempEndpoint,
  })

  const value = methods.watch(name)

  // biome-ignore lint/correctness/useExhaustiveDependencies: setFileName is a stable dep
  useEffect(() => {
    if (!value && uploadState.done) {
      setFileName("")
      reset()
    }

    // Display existing form value if any
    if (value && !uploadState.done && !uploadState.started) {
      setFileName(value?.originalFileName)
    }
  }, [reset, value])

  async function onTextFieldClick(event: SyntheticEvent) {
    if (value && onFileClick && fileInput.current) {
      const editedFile = await onFileClick(value)
      submitFile(editedFile)
    } else {
      openFilePicker(event)
    }
  }

  function openFilePicker(event: SyntheticEvent) {
    event.preventDefault()
    if (fieldConf.disabled) return
    fileInput.current?.click()
  }

  function handleCancel(event: SyntheticEvent) {
    event.preventDefault()
    if (uploadState.started) {
      uploadState.doAbort?.()
    }
    setFileName("")
  }

  function handleClearValue(event: SyntheticEvent) {
    event.stopPropagation()
    reset()
    handleCancel(event)
    methods.resetField(name, { defaultValue: null })
  }

  async function submitFile(file: File) {
    const response = await upload({ data: file })
    methods.setValue(
      name,
      {
        ...response.result,
        originalFileName: decodeURI(response.result.originalFileName),
      } as TFieldValues[typeof name],
      { shouldValidate: true },
    )
  }

  async function handleChange(event: SyntheticEvent<HTMLInputElement>) {
    event.preventDefault()
    if (uploadState.started) {
      handleCancel(event)
    }

    const fileToUpload = event.currentTarget.files?.[0]
    if (fileToUpload) {
      const file = (await onFileInput?.(fileToUpload)) || fileToUpload

      setFileName(file.name)

      if (maxSizeInMo) {
        const fileSizeInMo = getFileSizeInMo(file.size)
        if (fileSizeInMo >= maxSizeInMo) {
          return methods.setError(name, {
            message: `Ce fichier a un poids de ${fileSizeInMo}Mo et doit être inferieur à ${maxSizeInMo}Mo`,
          })
        }
      }

      submitFile(file)
    }
  }

  return (
    <Controller
      name={name}
      render={({ fieldState: { error } }) => (
        <Stack direction="column" spacing={2}>
          {/* FILE INPUT */}
          <TextField
            {...fieldConf}
            placeholder={`Choisir un fichier${required ? "*" : ""}`}
            fullWidth
            onClick={onTextFieldClick}
            value={fileName}
            error={!!error || !!parentError}
            helperText={error?.message || parentError?.message}
            required={required}
            slotProps={{
              htmlInput: { sx: { cursor: "pointer" }, readOnly: true },
              input: {
                sx: { paddingRight: "4px" },
                endAdornment: (
                  <InputAdornment position="end">
                    {!!fileName && (
                      <IconButton
                        onClick={handleClearValue}
                        color="primary"
                        sx={{ marginRight: "6px" }}
                      >
                        <X size="16px" />
                      </IconButton>
                    )}
                    <BrowseButton
                      variant="contained"
                      component="label"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        openFilePicker(e)
                      }}
                    >
                      Parcourir
                      <input
                        type="file"
                        {...otherProps}
                        ref={fileInput}
                        onClick={(e) => e.stopPropagation()}
                        onChange={handleChange}
                        value=""
                        style={{ display: "none" }}
                        required={required}
                      />
                    </BrowseButton>
                  </InputAdornment>
                ),
              },
            }}
          />

          {/* UPLOAD PROGRESS BAR */}
          {!!uploadState.started && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  color={
                    uploadState.done
                      ? "success"
                      : uploadState.error
                        ? "error"
                        : "primary"
                  }
                  value={uploadState.progress}
                />
              </Box>
              <Box>
                {uploadState.done ? (
                  <IconButton
                    color="success"
                    aria-label="cancel"
                    onClick={handleCancel}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="cancel"
                    onClick={handleCancel}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
            </Stack>
          )}
        </Stack>
      )}
    />
  )
}

const BrowseButton = styled(Button)({
  borderRadius: "4px",
}) as typeof Button

const getFileSizeInMo = (fileSizeInOctet: number) =>
  Math.round((fileSizeInOctet / 1024 / 1024) * 1000) / 1000

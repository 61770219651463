import { FilterSearchAndSelect } from "@pharmupp/p3-front-commons"
import type { SummaryModel } from "../../types"
import type { useAdherentOrdersReferential } from "../../useAdherentOrdersReferential"
import { OrderTable } from "../commons"
import { columns } from "../commons/columns"

type PastOrdersProps = {
  summary: SummaryModel
  year: number
} & ReturnType<typeof useAdherentOrdersReferential>

/** Admin only */
const endpoint = "/api/offers/orders"

export const LastYears = ({ year, ...props }: PastOrdersProps) => {
  const startDate = `${year}-01-01`
  const endDate = `${year}-12-31`
  return (
    <OrderTable
      queryConfig={{
        queryKey: ["offers", "orders", "all", year],
        resourceEndpoint: endpoint,
        queryParams: { startDate, endDate },
      }}
      columns={[
        { renderDefinition: columns.dateAndNumber, width: "12%" },
        { renderDefinition: columns.pharmacy, width: "15%" },
        { renderDefinition: columns.upp, width: "15%" },
        { renderDefinition: columns.divider, width: "3%" },
        { renderDefinition: columns.laboratory, width: "15%" },
        { renderDefinition: columns.offer, width: "30%" },
        { renderDefinition: columns.total, width: "10%" },
      ]}
      exportButtonProps={{
        endpoint: `${endpoint}?startDate=${startDate}&endDate=${endDate}`,
        name: "commandes",
      }}
      showDateYear
      emptyMessage="Aucune commande"
      extraFilterElements={
        !!props.referential && (
          <FilterSearchAndSelect
            name="uppIds"
            label="UPP"
            options={props.referential.upps}
          />
        )
      }
      {...props}
    />
  )
}

import type { OpeningDay } from "../../../../../../types"

export const DAYS = [
  { label: "Lundi", value: "MONDAY" },
  { label: "Mardi", value: "TUESDAY" },
  { label: "Mercredi", value: "WEDNESDAY" },
  { label: "Jeudi", value: "THURSDAY" },
  { label: "Vendredi", value: "FRIDAY" },
  { label: "Samedi", value: "SATURDAY" },
  { label: "Dimanche", value: "SUNDAY" },
] as const
type DayValue = (typeof DAYS)[number]["value"]
type DaysFields = Record<
  DayValue,
  Record<
    | "root"
    | "enabled"
    | "openingStart"
    | "openingEnd"
    | "hasBreak"
    | "breakStart"
    | "breakEnd",
    string
  >
>
export const openingHoursFields = {
  openingWeek: {
    root: "openingWeek",
    ...DAYS.reduce((output, { value: day }, index) => {
      output[day] = {
        root: `openingWeek.${index}`,
        enabled: `openingWeek.${index}.enable`,
        openingStart: `openingWeek.${index}.opening.start`,
        openingEnd: `openingWeek.${index}.opening.end`,
        hasBreak: `openingWeek.${index}.hasBreak`,
        breakStart: `openingWeek.${index}.workBreak.start`,
        breakEnd: `openingWeek.${index}.workBreak.end`,
      }
      return output
    }, {} as DaysFields),
  },
}

export const defaultValues = {
  openingWeek: DAYS.map<Partial<OpeningDay>>((day) => ({
    enable: false,
    day: day.value,
    hasBreak: false,
  })),
}

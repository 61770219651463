import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material"
import { Link, formatDateWithDots, formatPrice } from "@pharmupp/p3-front-commons"
import { ChevronRight, Clipboard } from "react-feather"

const Content = ({
  date,
  title,
  amount,
  id,
}: {
  date: Date
  title: string
  amount: number
  id: number
}) => (
  <ContentContainer as={Button} to={`${id}`}>
    <Box gridArea="A" display="flex" color="primary.main">
      <Clipboard />
    </Box>
    <Typography
      display="flex"
      gridArea="B"
      color="grey.light"
      sx={(theme) => ({
        fontSize: theme.typography.pxToRem(10),
      })}
    >
      Le {formatDateWithDots(date)}
    </Typography>

    <Typography
      display="flex"
      fontSize={(theme) => theme.typography.pxToRem(14)}
      gridArea="C"
    >
      {title}
    </Typography>
    <Typography
      fontWeight="bold"
      fontSize={(theme) => theme.typography.pxToRem(21)}
      gridArea="D"
    >
      {formatPrice(amount)}
    </Typography>
    <Typography gridArea="E">
      <ChevronRight />
    </Typography>
  </ContentContainer>
)

const ContentContainer = styled(Link)(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
  "A A A"
  "B B B"
  "C D E"`,
  width: "100%",
  borderRadius: 0,
  alignItems: "center",
  justifyContent: "space-between",
  rowGap: theme.spacing(1),
  color: theme.palette.common.darkBlue,
  [theme.breakpoints.down("md")]: {
    gridTemplateAreas: `
      "A B D E"
      "A C D E"`,
    gridTemplateColumns: "min-content 1fr 1fr min-content",
    columnGap: theme.spacing(1),
    rowGap: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

// @TODO Use this component when back-end expose 3 last commands
// @ts-ignore
const MockLastOrderWidget = () => {
  return (
    <Card>
      <Stack p={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography color="grey.dark" fontWeight="600" textTransform="uppercase">
            MES 3 dernières COMMANDES
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            component={MuiLink}
            underline="hover"
            href="http://pharm-upp.fr/pharmacien/offres/historique"
            target="_blank"
          >
            <Typography
              color="grey.dark"
              sx={(theme) => ({ fontSize: theme.typography.pxToRem(12) })}
            >
              Historique des commandes
            </Typography>
            <ChevronRight width={12} height={12} />
          </Stack>
        </Stack>
        <Stack
          width="100%"
          direction={["column", "column", "row", "row", "row"]}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  borderWidth: 0.5,
                },
              })}
            />
          }
        >
          <Content
            title="Défis Coalia"
            amount={Math.random() * 1000}
            date={new Date()}
            id={1}
          />
          <Content
            title="Défis Coalia"
            amount={Math.random() * 1000}
            date={new Date()}
            id={2}
          />
          <Content
            title="Défis Coalia"
            amount={Math.random() * 1000}
            date={new Date()}
            id={3}
          />
        </Stack>
      </Stack>
    </Card>
  )
}

// @TODO Remove this component when back-end expose 3 last commands
const PartialLastOrderWidget = ({
  orderHistoryLink,
}: {
  orderHistoryLink: string
}) => (
  <Card>
    <CardActionArea component={Link} to={orderHistoryLink} target="_self">
      <Stack
        p={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography color="grey.dark" fontWeight="600" textTransform="uppercase">
          MES COMMANDES
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography
            color="grey.dark"
            sx={(theme) => ({ fontSize: theme.typography.pxToRem(12) })}
          >
            Historique des commandes
          </Typography>
          <ChevronRight width={12} height={12} />
        </Stack>
      </Stack>
    </CardActionArea>
  </Card>
)

export const LastOrderWidget = PartialLastOrderWidget

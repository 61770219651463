import { Yup, type YupTypes } from "@pharmupp/p3-front-commons"

export const schema = Yup.object({
  adherent: Yup.object({
    code: Yup.number().required(),
  }),
  document: Yup.mixed<File>().required(),
})

export type FormValues = YupTypes.InferType<typeof schema>

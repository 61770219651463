import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import {
  Box,
  Button,
  Tooltip,
  Typography,
  buttonClasses,
  styled,
  useTheme,
} from "@mui/material"
import { Check, MinusCircle } from "react-feather"

const BaseListChip = styled(Button)(({ theme }) => ({
  minWidth: "auto",
  padding: theme.spacing(0.2, 0.6),
  textTransform: "uppercase",
  lineHeight: theme.typography.pxToRem(11),
  fontSize: theme.typography.pxToRem(9),
  fontWeight: 600,
  pointerEvents: "none",
  [`.${buttonClasses.startIcon}`]: {
    marginRight: "2px",
  },
}))

export const ListUltraChip = styled(BaseListChip)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  borderColor: theme.palette.common.electricBlue,
}))
ListUltraChip.defaultProps = {
  variant: "outlined",
  size: "small",
  startIcon: <Check size={11} />,
  children: "ULTRA",
}

export const ListNegativeSegmentationChip = styled(BaseListChip)(({ theme }) => ({
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  opacity: 0.7,
}))
ListNegativeSegmentationChip.defaultProps = {
  variant: "outlined",
  size: "small",
  startIcon: <MinusCircle size={11} />,
  children: "Segmentation Négative",
}

interface TablePharmacyNameProps {
  name: string
  negativeSegmentation?: boolean
}
export const TablePharmacyName = ({
  name,
  negativeSegmentation,
}: TablePharmacyNameProps) => {
  const { palette } = useTheme()
  const redColorLight = palette.negativeSegmentation.light
  const IconRedColor = palette.negativeSegmentation.dark4
  return (
    <>
      {negativeSegmentation ? (
        <Tooltip title="Segmentation négative">
          <Box display="flex" alignItems="center">
            <Box bgcolor={redColorLight}>
              <Typography fontSize="inherit" fontWeight="bold">
                {name}
              </Typography>
            </Box>
            <RemoveCircleIcon
              sx={{ color: IconRedColor, fontSize: 16, marginLeft: 0.5 }}
            />
          </Box>
        </Tooltip>
      ) : (
        <Typography fontSize="inherit" fontWeight="bold">
          {name}
        </Typography>
      )}
    </>
  )
}

import { api, useQuery } from "./http"

export const useUppsList = (args?: { enabled?: boolean }) => {
  const { data } = useQuery({
    queryKey: ["admin", "upps", "dataref"],
    queryFn: () => api.get<UppsDatarefApi[]>("/api/upps/dataref"),
    select: (rawData) => {
      return {
        list: rawData.map((upp) => ({ code: upp.id, label: upp.name })),
        nameById: rawData.reduce<Record<number, string>>((acc, upp) => {
          acc[upp.id] = upp.name
          return acc
        }, {}),
      }
    },
    enabled: args?.enabled ?? true,
  })
  return {
    uppsList: data?.list,
    uppsNameById: data?.nameById,
  }
}

interface UppsDatarefApi {
  id: number
  name: string
}

import {
  Card,
  CardActionArea,
  type CardProps,
  Stack,
  Typography,
  cardActionAreaClasses,
  styled,
  touchRippleClasses,
} from "@mui/material"
import { ChevronRight } from "react-feather"
import { Link } from "react-router-dom"
import { useMyUnsignedDocsApi } from "./useMyUnsignedDocsApi"

export const MyUnsignedDocsListBtn = ({
  to,
  sx,
}: { to: string } & Pick<CardProps, "sx">) => {
  const { unsignedDocs } = useMyUnsignedDocsApi()

  if (!unsignedDocs?.length) return null

  return (
    <Card
      component={Link}
      to={to}
      sx={{
        ...sx,
        border: "none",
        boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.32)",
        textDecoration: "none",
      }}
    >
      <ActionArea>
        <Stack direction="row" alignItems="center" gap={1} color="white">
          <img src="/icons/sign-textfile.svg" alt="Document à signer" />
          <Typography variant="16px" fontWeight={600} color="inherit">
            Documents à signer
          </Typography>
          <ChevronRight
            size={18}
            className={CHEVRON_CLASSNAME}
            style={{ transition: "transform ease-in-out 150ms" }}
          />
        </Stack>
      </ActionArea>
    </Card>
  )
}

const CHEVRON_CLASSNAME = "chevron"

const ActionArea = styled(CardActionArea)(({ theme }) => ({
  borderLeft: "8px solid #fb990f",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(3, 3, 3, 1.5),
  backgroundColor: "#feb726",
  [`& > .${cardActionAreaClasses.focusHighlight},.${touchRippleClasses.child}`]: {
    backgroundColor: theme.palette.common.orangeDark1,
  },
  [`&:hover .${CHEVRON_CLASSNAME}`]: {
    transform: theme.transitions.create("transform"),
  },
}))

import { FormPhoneField } from "@pharmupp/p3-front-commons"
import type { UserFormReferential } from "../../../../useUserFormApi"
import { fields } from "../../GeneralInfos.fields"
import { useConditionalPhoneField } from "../useConditionalFields"

interface Props {
  referential: UserFormReferential
}

export const ConditionalPhoneField = ({ referential }: Props) => {
  const { isHolder } = useConditionalPhoneField(referential)
  return (
    <FormPhoneField
      name={fields.phone}
      label="Téléphone portable"
      required={isHolder}
    />
  )
}

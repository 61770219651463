import {
  Box,
  type BoxProps,
  Link,
  type LinkProps,
  type Theme,
  Typography,
  type TypographyProps,
  styled,
} from "@mui/material"
import type { CustomColorPaletteNames } from "@pharmupp/p3-themes"
import type { ReactNode } from "react"

type Size = "small" | "medium"
type IconSize = "medium" | "large"
export type DashboardInfoBannerProps = BoxProps & {
  variant?: CustomColorPaletteNames
  size?: Size
  iconSize?: IconSize
  children: ReactNode
}
export const DashboardInfoBanner = ({
  variant,
  size = "medium",
  iconSize = "medium",
  children,
  ...props
}: DashboardInfoBannerProps) => (
  <Container {...props} variant={variant} size={size} iconSize={iconSize}>
    <Box display="flex" alignItems="center" gap={1}>
      {children}
    </Box>
  </Container>
)

const Container = styled(Box, {
  shouldForwardProp: (prop) =>
    !["size", "iconSize", "variant"].includes(prop as string),
})<Pick<DashboardInfoBannerProps, "variant" | "size" | "iconSize">>(
  ({ theme, variant, size, iconSize }) => {
    const { bg, svgColor } = getVariantStyle(theme, variant)

    return {
      width: "100%",
      height: "48px",
      padding: theme.spacing(1, 2),
      borderRadius: "5px",
      backgroundColor: bg,
      ...theme.typography[size === "medium" ? "14px" : "12px"],
      svg: {
        fontSize:
          iconSize === "medium" ? "inherit" : theme.typography["18px"].fontSize,
        height: "1.4em",
        width: "auto",
        color: svgColor,
      },
    }
  },
)

const getVariantStyle = ({ palette }: Theme, variant?: CustomColorPaletteNames) => {
  switch (true) {
    case variant !== undefined:
      return {
        bg: palette[variant].light,
        svgColor: palette[variant].main,
      }
    default:
      return {
        bg: "#ecf5ff",
        svgColor: palette.common.electricBlue,
      }
  }
}

export const DashboardInfoBannerText = ({ children, ...props }: TypographyProps) => (
  <Typography component="span" {...props} fontSize="inherit" lineHeight="inherit">
    {children}
  </Typography>
)

export const DashboardInfoBannerTitle = styled(DashboardInfoBannerText)({
  fontWeight: 600,
})

export const DashboardInfoBannerCaption = styled(DashboardInfoBannerText)(
  ({ theme: { palette } }) => ({
    color: palette.grey.dark,
  }),
)
export const DashboardInfoBannerLink = ({
  children,
  ...props
}: LinkProps<typeof Typography>) => (
  <Typography
    component={Link}
    role="link"
    {...props}
    underline="always"
    fontSize="inherit"
    lineHeight="inherit"
    sx={{ cursor: "pointer" }}
  >
    {children}
  </Typography>
)

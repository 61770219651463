import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material"
import {
  Chip,
  FilterSearchAndSelect,
  FilterToggle,
  PageHeader,
  PaperContent,
  ROLES,
  TableCard,
  getPublicFileUrl,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { indexBy } from "remeda"
import { CatalogTypeChip } from "../../common"
import { type CatalogType, catalogLabel } from "../../model"
import { PartnerChip } from "../common"
import {
  type ApiAdherentLaboratory,
  useAdhLabReferentialApi,
} from "./useAdhLabListApi"

export const AdherentLabList = () => {
  const isCDO = useRoleCheck(ROLES.ADHERENT_ADVISOR)
  const { referential } = useAdhLabReferentialApi()
  const categories = useMemo(
    () => indexBy(referential?.categories || [], (category) => category.code),
    [referential?.categories],
  )

  return (
    <>
      {/* HEADER */}
      <PageHeader title="Laboratoires" />

      {/* TABLE */}
      <PaperContent>
        <TableCard<ApiAdherentLaboratory, SelectedLab>
          queryConfig={{
            resourceEndpoint: "/api/laboratories/adherent",
            queryKey: ["laboratories", "list", "adherent"],
            rowsPerPage: 15,
            select: (labs) =>
              labs.map((lab) => ({
                ...lab,
                catalogTypes: Object.entries(lab.catalogType).flatMap(
                  ([label, value]) =>
                    value ? [catalogLabel[label as keyof CatalogType]] : [],
                ),
              })),
          }}
          FilterSectionElement={
            <>
              <FilterSearchAndSelect
                name="channels"
                label="Canal"
                options={referential?.channels || []}
                multiple
              />
              <FilterSearchAndSelect
                name="categories"
                label="Catégories"
                options={referential?.categories || []}
                multiple
              />
              <FilterToggle name="withOffer" label="Offres en cours" />
            </>
          }
          cardPerRow={3}
          entityMapper={({ data: lab }) => (
            <Card key={lab.id}>
              <CardActionArea
                component={Link}
                to={`./${lab.id}`}
                sx={{ height: "100%", pointerEvents: isCDO ? "none" : "auto" }}
              >
                <Stack height="100%" justifyContent="space-between">
                  <Stack p={1}>
                    {/* CATEGORIES */}
                    <Box display="flex" gap={1} height="24px">
                      {lab.categories?.map((category) => (
                        <Chip
                          label={categories[category]?.label}
                          size="small"
                          fontColor="success.main"
                          key={category}
                          sx={{ fontSize: "0.6rem" }}
                        />
                      ))}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2.5}
                      py={3}
                    >
                      <Avatar
                        variant="rounded"
                        src={lab.logo ? getPublicFileUrl(lab.logo) : undefined}
                        alt={lab.name}
                        sx={{ width: "200px", height: "60px", my: 2 }}
                        imgProps={{ sx: { objectFit: "contain" } }}
                      >
                        {lab.name.charAt(0)}
                      </Avatar>

                      <Name>{lab.name}</Name>

                      <Box height="24px">
                        {!!lab.partnershipType && (
                          <PartnerChip size="small" type={lab.partnershipType} />
                        )}
                      </Box>

                      <Divider
                        flexItem
                        sx={{ width: "32%", alignSelf: "center", mb: 3 }}
                      />

                      <Box
                        display="flex"
                        gap={1.5}
                        justifyContent="center"
                        flexWrap="wrap"
                        px={2}
                      >
                        {lab.catalogTypes.map((type) => (
                          <CatalogTypeChip
                            label={type}
                            key={type}
                            fontColor="primary.main"
                          />
                        ))}
                      </Box>
                    </Box>
                  </Stack>

                  {/* OFFERS COUNT */}
                  {lab.availableOffersCount > 0 && (
                    <Box
                      bgcolor="#e6f1fd"
                      display="flex"
                      justifyContent="center"
                      py={1.8}
                    >
                      <OffersCount>
                        {lab.availableOffersCount}{" "}
                        {lab.availableOffersCount === 1 ? "offre" : "offres"} en
                        cours
                      </OffersCount>
                    </Box>
                  )}
                </Stack>
              </CardActionArea>
            </Card>
          )}
          emptyLabel="Aucun laboratoire"
        />
      </PaperContent>
    </>
  )
}

type SelectedLab = ApiAdherentLaboratory & { catalogTypes: string[] }

const Name = styled(Typography)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(14),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: theme.palette.common.darkBlue,
  textTransform: "uppercase",
}))
const OffersCount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: theme.palette.common.electricBlue,
  textTransform: "uppercase",
}))

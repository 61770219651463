import { Box, type BoxProps, alpha, styled } from "@mui/material"
import { getPublicFileUrl } from "@pharmupp/p3-front-commons"
import { Check, RefreshCw, X } from "react-feather"
import { RefusalMessage } from "../../../ParticipantDetails/Document"
import type { DocumentValidationStatus, ProofDocument } from "../../../types"
import type { ApiMarketingOperationDisplay } from "../useAdhMarketingOPDisplayApi"

type DocumentsGridProps = Omit<DocumentPreviewProps, "proofDocument"> & {
  proofDocuments: ApiMarketingOperationDisplay["proofDocuments"]
}

export const DocumentsGrid = ({
  proofDocuments,
  aspectRatio = "1/1",
  showStatus = false,
  showRejectionReason = false,
}: DocumentsGridProps) => (
  <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1.5}>
    {proofDocuments?.map((proofDocument, index) => (
      <DocumentPreview
        key={`document-preview-${index}`}
        proofDocument={proofDocument}
        aspectRatio={aspectRatio}
        showStatus={showStatus}
        showRejectionReason={showRejectionReason}
      />
    ))}
  </Box>
)

interface DocumentPreviewProps {
  proofDocument: ProofDocument
  aspectRatio?: string
  showStatus?: boolean
  showRejectionReason?: boolean
}

export const DocumentPreview = ({
  proofDocument,
  aspectRatio,
  showStatus,
  showRejectionReason,
}: DocumentPreviewProps) => (
  <Box key={proofDocument.document.tempFileId} position="relative">
    <Box
      component="img"
      src={getPublicFileUrl(proofDocument.document)}
      border="1px solid #e0e4e9"
      borderRadius="6px"
      width="100%"
      sx={{ aspectRatio, objectFit: "cover" }}
    />
    {!!showStatus && (
      <DocumentStatusIcon
        status={proofDocument.validationStatus}
        position="absolute"
        top={0}
        right={0}
        sx={{ transform: "translate(30%, -30%)" }}
      />
    )}
    {!!showRejectionReason && !!proofDocument.rejectionReason && (
      <Box pt={2}>
        <RefusalMessage>{proofDocument.rejectionReason}</RefusalMessage>
      </Box>
    )}
  </Box>
)

const DocumentStatusIcon = ({
  status,
  ...props
}: { status: DocumentValidationStatus } & BoxProps) => {
  switch (status) {
    case "VALIDATED":
      return (
        <DocumentStatusBox
          {...props}
          color="white"
          bgcolor="success.main"
          borderColor="success.main"
        >
          <Check size="18px" />
        </DocumentStatusBox>
      )
    case "WAITING":
      return (
        <DocumentStatusBox
          {...props}
          color="success.main"
          bgcolor="white"
          borderColor={({ palette }) => alpha(palette.success.main, 0.2)}
        >
          <RefreshCw size="16px" />
        </DocumentStatusBox>
      )
    case "REFUSED":
      return (
        <DocumentStatusBox {...props} color="white" bgcolor="error.main">
          <X size="18px" />
        </DocumentStatusBox>
      )
    default:
      return null
  }
}
const DocumentStatusBox = styled(Box)({
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "26px",
  height: "26px",
})

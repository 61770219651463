import { ROLES } from "@pharmupp/p3-front-commons"
import { fields } from "./GeneralInfos.fields"

export const formRoleConf = {
  [ROLES.ADMIN]: {
    create: {
      statusSection: {
        hide: true,
      },
    },
  },
  [ROLES.ADHERENT_HOLDER]: {
    [fields.type]: {
      disabled: true,
    },
  },
}

export const readOnlyConf = Object.keys(fields).reduce<Record<string, any>>(
  (result, current) => {
    result[current] = { disabled: true }
    return result
  },
  {},
)

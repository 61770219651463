import { LoadingButton } from "@mui/lab"
import { Button, Link as MuiLink, Stack } from "@mui/material"
import * as React from "react"
import type { Icon as FeatherIcon } from "react-feather"
import { Link } from "react-router-dom"
import { PageHeader, type PageHeaderProps } from "../../../layout"
import { ActionButton } from "../../../navigation"

interface BaseHeaderAction {
  icon: FeatherIcon
  label: string
  disabled?: boolean
  hidden?: boolean
}
type ClickHeaderAction = { onClick: () => void } & BaseHeaderAction
type LinkHeaderAction = { to: string } & BaseHeaderAction
export type HeaderAction = ClickHeaderAction | LinkHeaderAction

const getActionsElements = (actions: HeaderAction[]) => {
  const visibleActions = actions.filter((action) => !action.hidden)

  if (visibleActions.length === 0) return null
  return (
    <ActionButton
      items={visibleActions.map((action) => ({
        ...("to" in action
          ? {
              to: action.to,
              component: Link,
            }
          : {
              onClick: action.onClick,
              component: MuiLink,
            }),
        Icon: action.icon,
        label: action.label,
        disabled: action.disabled,
      }))}
    />
  )
}

export type FormHeaderProps = Pick<PageHeaderProps, "title" | "level"> & {
  onCancel?: () => void
  submitLabel?: string
  submitLoading?: boolean
  onSubmit?: () => void
  otherActions?: HeaderAction[]
}

/**
 * Ensure consistent styles / business rules across forms
 */
export const FormHeader = ({
  title,
  level = 2,
  onCancel,
  submitLabel = "Enregistrer",
  submitLoading = false,
  onSubmit,
  otherActions = [],
}: FormHeaderProps) => {
  const actions = React.useMemo(
    () => getActionsElements(otherActions),
    [otherActions],
  )

  return (
    <PageHeader title={title} level={level}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {!!onCancel && <Button onClick={onCancel}>Annuler</Button>}
        {!!onSubmit && (
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            loading={submitLoading}
          >
            {submitLabel}
          </LoadingButton>
        )}
        {actions}
      </Stack>
    </PageHeader>
  )
}

import { Box, Container, Typography, useTheme } from "@mui/material"
import { type ReactNode, useRef } from "react"
import { createPortal } from "react-dom"
import { SUB_HEADER_PORTAL_ID } from "./AppHeader"

export const STICKY_CLASS = "isSticky"

export const AppSubHeader = ({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) => {
  const { typography } = useTheme()
  const subHeaderRef = useRef<HTMLDivElement>(null)
  const portalTarget = document.getElementById(SUB_HEADER_PORTAL_ID)

  return (
    <>
      {/* NOT STICKY */}
      <div ref={subHeaderRef}>{children}</div>

      {/* STICKY */}
      {!!portalTarget &&
        createPortal(
          <Box
            width="100%"
            bgcolor={(theme) => theme.palette.common.white}
            borderBottom="1px solid #eee"
            className={STICKY_CLASS}
          >
            <Container maxWidth="lg">
              <Box display="flex" alignItems="center" gap={5} height="48px">
                <Typography fontSize={typography.pxToRem(18)} fontWeight={600}>
                  {title}
                </Typography>
                {children}
              </Box>
            </Container>
          </Box>,
          portalTarget,
        )}
    </>
  )
}

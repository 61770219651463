import { Stack, styled } from "@mui/material"
import type { ControlProps } from "nuka-carousel"

export const Pagination = ({
  slideCount,
  currentSlide,
  goToSlide,
}: ControlProps) => (
  <Line>
    {[...Array(slideCount).keys()].map((_, i) => (
      <Dot key={i} active={i === currentSlide} onClick={() => goToSlide(i)} />
    ))}
  </Line>
)

const Line = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(-3),
}))

const Dot = styled("div")<{ active?: boolean }>(({ theme, active }) => ({
  minWidth: "10px",
  height: "10px",
  borderRadius: "50%",
  cursor: "pointer",
  backgroundColor: active
    ? theme.palette.secondary.main
    : theme.palette.common.blueGreen2,
}))

import {
  api,
  useMutation,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import type { ApiFormEventReceived } from "../model"

export const useCancelEventApi = () => {
  const queryClient = useQueryClient()
  const toaster = useToaster()
  const { id: eventId } = useParams()

  const { mutateAsync: cancelEvent } = useMutation({
    mutationFn: (formValues: ApiCancelEventBody) =>
      api.put<ApiFormEventReceived>(`/api/news/event/${eventId}/cancel`, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formValues),
      }),
    onSuccess(data) {
      queryClient.setQueryData(["news", "events", "form", eventId], data)
      toaster.success("Évènement annulé")
    },
    onError: () => toaster.error("Une erreur est survenue"),
  })

  return {
    cancelEvent,
  }
}

export interface ApiCancelEventBody {
  cancellationReason?: string
}

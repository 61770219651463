import {
  Button,
  type ExtendButtonBase,
  IconButton,
  type IconButtonTypeMap,
  buttonClasses,
  styled,
} from "@mui/material"

/* In px */
export const TOOLBAR_BTN_SIZE = 46

export const CircleIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !(prop === "active"),
})<{ active?: boolean }>(({ theme, active }) => ({
  backgroundColor: theme.palette.header.containedButton.backgroundColor,
  width: theme.typography.pxToRem(TOOLBAR_BTN_SIZE),
  height: theme.typography.pxToRem(TOOLBAR_BTN_SIZE),
  transition: theme.transitions.create("background-color"),
  "&:hover": {
    backgroundColor: theme.palette.header.containedButton.hover.backgroundColor,
  },
  ...(active && {
    backgroundColor: theme.palette.header.containedButton.hover.backgroundColor,
  }),
})) as ExtendButtonBase<IconButtonTypeMap<{ active?: boolean }>>

export const CircleButton = styled(Button, {
  shouldForwardProp: (prop) => !(prop === "active"),
})<{ active?: boolean }>(({ theme, active }) => ({
  backgroundColor: theme.palette.header.containedButton.backgroundColor,
  color: theme.palette.header.containedButton.primaryColor,
  fontSize: theme.typography.pxToRem(12),
  height: theme.typography.pxToRem(TOOLBAR_BTN_SIZE),
  transition: theme.transitions.create("background-color"),
  "&:hover": {
    backgroundColor: theme.palette.header.containedButton.hover.backgroundColor,
  },
  ...(active && {
    backgroundColor: theme.palette.header.containedButton.hover.backgroundColor,
  }),
  [`.${buttonClasses.startIcon}`]: {
    marginRight: theme.typography.pxToRem(12),
  },
}))

import { Box, CircularProgress, styled } from "@mui/material"
import { type ReactEventHandler, useCallback, useRef, useState } from "react"
import { type Crop, type PixelCrop, ReactCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import type { FieldValues } from "react-hook-form"
import type { UploadedFile } from "../../../../types"
import {
  Modal,
  ModalBasicActions,
  ModalContentText,
  ModalTitle,
} from "../../../feedback/Modal"
import { FormFileField, type FormFileFieldProps } from "../FormFileField"
import { canvasPreview } from "./canvasPreview"
import { downloadFile, getInitialCrop } from "./utils"

interface FormPictureFieldProps<TFieldValues extends FieldValues = FieldValues>
  extends FormFileFieldProps<TFieldValues> {
  ratio?: number
  cropLabel?: string
}
export const FormPictureField = <TFieldValues extends FieldValues = FieldValues>({
  ratio,
  cropLabel,
  ...props
}: FormPictureFieldProps<TFieldValues>) => {
  const [cropModalCb, setCropModalCb] = useState<((file: File) => void) | null>(null)
  const [picture, setPicture] = useState<File | null>(null)
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const [crop, setCrop] = useState<Crop | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgPreviewRef = useRef<HTMLImageElement>(null)

  /** Use a promisified modal pattern to let the file input wait until the crop is done */
  async function cropPicture(file: File) {
    return new Promise<File>((resolve) => {
      // Bypass for playwright tests :-(
      if (import.meta.env.VITE_MOCKED) {
        resolve(file)
        onClose()
        return
      }

      setPicture(file)
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || ""),
      )
      reader.readAsDataURL(file)
      setCropModalCb(() => resolve)
    })
  }

  async function loadPictureAndCrop(fileEntity: UploadedFile) {
    const file = await downloadFile(fileEntity)
    return cropPicture(file)
  }

  const onImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget
    const initialCrop = getInitialCrop({ width, height, ratio: ratio || 16 / 9 })
    setCrop(initialCrop)
  }

  const updateCanvasPreview = useCallback(() => {
    if (!imgPreviewRef.current || !previewCanvasRef.current || !crop) return
    canvasPreview(imgPreviewRef.current, previewCanvasRef.current, crop as PixelCrop)
  }, [crop])

  function onClose() {
    setCropModalCb(null)
    setPicture(null)
    setImgSrc(null)
    setCrop(null)
  }

  function onSubmit() {
    updateCanvasPreview()
    previewCanvasRef.current?.toBlob((blob) => {
      if (!blob || !picture) {
        throw new Error("Failed to create blob")
      }
      const file = new File([blob], picture.name, {
        type: picture.type,
        lastModified: new Date().getTime(),
      })
      cropModalCb?.(file)
    })
    onClose()
  }

  // Uncomment + set canvas display to visible to debug crop output
  // useEffect(() => {
  //   updateCanvasPreview()
  // }, [crop, updateCanvasPreview])

  return (
    <>
      {/* INPUT */}
      <FormFileField
        {...props}
        onFileInput={cropPicture}
        onFileClick={loadPictureAndCrop}
      />

      {/* CROP MODAL */}
      <Modal open={!!cropModalCb} onClose={onClose} fullWidth>
        <ModalTitle padding="0!important">Recadrer l&apos;image</ModalTitle>
        {!!cropLabel && <ModalContentText>{cropLabel}</ModalContentText>}

        <Box display="flex" justifyContent="center" my={4}>
          {!imgSrc ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <ReactCrop
              aspect={ratio}
              keepSelection
              crop={crop || undefined}
              onChange={(newCrop) => setCrop(newCrop)}
            >
              {!!imgSrc && (
                <ImagePreview
                  ref={imgPreviewRef}
                  src={imgSrc}
                  alt="Image recadrée"
                  onLoad={onImageLoad}
                />
              )}
            </ReactCrop>
          )}
        </Box>

        {/* HIDDEN CANVAS TO GENERATE FILE FROM CROP */}
        {!!crop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
              objectFit: "contain",
            }}
          />
        )}

        {/* SUBMIT */}
        <ModalBasicActions cancelAction={onClose} validateAction={onSubmit} />
      </Modal>
    </>
  )
}

const ImagePreview = styled("img")({})

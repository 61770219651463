import { useResourceListApi } from "@pharmupp/p3-front-commons"
import { type LatestNewsItemModel, useLatestNewsReferentialApi } from "../News"

const minPublicationDate = new Date()
minPublicationDate.setMonth(minPublicationDate.getMonth() - 9)
const minPublicationDateStr = minPublicationDate.toISOString().split("T")[0]

export const useLabNewsApi = ({ labId, size }: { labId: string; size?: number }) => {
  const {
    list: news,
    initialLoading: listLoading,
    contentLoading: listLoadingMore,
    paginationProps,
  } = useResourceListApi<LatestNewsItemModel>({
    queryKey: ["news", "laboratory", labId],
    resourceEndpoint: "/api/news",
    queryParams: {
      minPublicationDate: minPublicationDateStr,
      labId,
    },
    rowsPerPage: size,
  })

  const { referential, loading: referentialLoading } = useLatestNewsReferentialApi()

  return {
    news,
    isLoading: listLoading || listLoadingMore || referentialLoading,
    paginationProps,
    referential,
  }
}

import {
  Box,
  Card,
  CardActionArea,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from "@mui/material"
import { ActionButton } from "@pharmupp/p3-front-commons"
import { Suspense } from "react"
import { FileText, PlusCircle, Send } from "react-feather"
import { Link, useNavigate } from "react-router-dom"
import { InfoCount, Label, STATUS_COLOR } from "../../commons/components"
import { useTemplateSummaryApi } from "../useTemplateSummaryApi"
import type { DocumentTemplate } from "./TemplatesList"

interface TemplateCardProps {
  doc: DocumentTemplate
  setModalTemplateId: (id: DocumentTemplate["id"]) => void
}

export const TemplateCard = ({ doc, setModalTemplateId }: TemplateCardProps) => {
  // ROUTING
  const navigate = useNavigate()
  const signatureRequestsLink = `./${doc.id}`

  return (
    <Card>
      <CardActionArea component={Link} to={signatureRequestsLink}>
        <Stack p={1.5} gap={2}>
          {/* TITLE */}
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <DocIcon variant="small" />
              <Typography fontWeight="600" variant={"14px"} ml={1}>
                {doc.name}
              </Typography>
            </Stack>
            <ActionButton
              items={[
                {
                  onClick: () => navigate("./#"),
                  label: "Voir le document",
                  Icon: FileText,
                  disabled: true,
                },
                { divider: true },
                {
                  to: signatureRequestsLink,
                  label: "Liste des destinataires",
                  Icon: Send,
                },
                { divider: true },
                {
                  onClick: () => setModalTemplateId(doc.id),
                  label: "Ajouter un destinataire",
                  Icon: PlusCircle,
                },
              ]}
            />
          </Stack>

          <Suspense
            fallback={
              <>
                <Skeleton component={Card} variant="rectangular" height="84px" />
                <Skeleton variant="rectangular" height="22px" />
              </>
            }
          >
            <Stats templateId={doc.id} />
          </Suspense>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

const Stats = ({ templateId }: { templateId: string }) => {
  const { summary } = useTemplateSummaryApi({ templateId })
  const progress = summary?.signedCount
    ? Math.floor((summary.signedCount * 100) / summary.receiversCount)
    : 0

  const {
    palette: { grey },
  } = useTheme()

  return (
    <>
      {/* CONTENT */}
      <Card sx={{ bgcolor: grey[100], p: 2, border: "none" }}>
        <Stack direction="row" gap={1}>
          <InfoCount type="receivers" value={summary.receiversCount} />
          <InfoCount type="error" value={summary.errorCount} />
          <Divider
            orientation="vertical"
            sx={{ mt: "auto", mb: "2px", height: "1.5rem" }}
          />
          <InfoCount type="unsigned" value={summary.unsignedCount} />
        </Stack>
      </Card>

      {/* SIGNATURE TRACKING */}
      <Stack gap={0.5} mb={1.5}>
        <Label>
          Signatures :
          <TypoSigned component="span" color={STATUS_COLOR.SIGNED}>
            {summary.signedCount}
          </TypoSigned>
        </Label>
        <Stack gap={2} direction="row" alignItems="baseline">
          <Box position="relative" lineHeight={0} width="100%">
            <BorderLinearProgress
              variant="determinate"
              value={progress}
              sx={{
                [`& .${linearProgressClasses.bar}`]: {
                  bgcolor: STATUS_COLOR.SIGNED,
                },
              }}
            />
          </Box>
          <Typography fontWeight={500} lineHeight={0}>
            {progress}%
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}

export const DocIcon = ({ variant }: { variant?: "small" }) => {
  const {
    palette: {
      common: { electricBlue, white },
    },
  } = useTheme()
  return (
    <IconButton
      size="small"
      sx={{
        pointerEvents: "none",
        background: electricBlue,
        color: white,
      }}
      component="span"
      tabIndex={-1}
    >
      <FileText size={variant ? "14px" : "24px"} />
    </IconButton>
  )
}

const TypoSigned = styled(Typography)<{ component?: React.ElementType }>(
  ({ theme: { typography, palette } }) => ({
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(12),
    color: palette.secondary.main,
    fontWeight: 600,
    marginInline: typography.pxToRem(3),
  }),
)

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}))

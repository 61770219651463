import { type Tab, formatIsoDate } from "@pharmupp/p3-front-commons"
import { endOfYear, startOfYear, sub } from "date-fns"
import { NonValidatedOrders } from "../NonValidated"
import { SpecificTimeRange } from "./SpecificTimeRange"

const now = new Date()
const year = now.getFullYear()
const lastYear = sub(now, { years: 1 })
const twoYearsAgo = sub(now, { years: 2 })
const threeYearsAgo = sub(now, { years: 3 })

export const specificTimeRangeTabs: Tab[] = [
  {
    Element: (props) => <NonValidatedOrders {...props} />,
    label: "Commandes en cours",
    to: "en-cours",
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDate={formatIsoDate(sub(now, { months: 3 }))}
        endDate={formatIsoDate(now)}
      />
    ),
    label: "3 derniers mois",
    to: "latest",
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDate={formatIsoDate(startOfYear(now))}
        endDate={formatIsoDate(now)}
      />
    ),
    label: year,
    to: year.toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDate={formatIsoDate(startOfYear(lastYear))}
        endDate={formatIsoDate(endOfYear(lastYear))}
      />
    ),
    label: year - 1,
    to: (year - 1).toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDate={formatIsoDate(startOfYear(twoYearsAgo))}
        endDate={formatIsoDate(endOfYear(twoYearsAgo))}
      />
    ),
    label: year - 2,
    to: (year - 2).toString(),
  },
  {
    Element: (props) => (
      <SpecificTimeRange
        {...props}
        startDate={formatIsoDate(startOfYear(threeYearsAgo))}
        endDate={formatIsoDate(endOfYear(threeYearsAgo))}
      />
    ),
    label: year - 3,
    to: (year - 3).toString(),
  },
]

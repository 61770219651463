import type { UploadedFile } from "@pharmupp/p3-front-commons"
import type { LocationType } from "../../Events/model"
import type { PostRubric } from "./ApiFormPost"

export type ApiDisplayNews = {
  id: number
  postType: PostType
  title: string
  description: string
  publicationDate: string
  creatorType: "ADMINISTRATOR" | "PRESIDENT_UPP"
  image?: UploadedFile

  rubric: PostRubric
  /** Fetch from referential  */
  category?: string
  logo?: UploadedFile
  startDate: Date
  endDate: Date
  registrationDeadline: Date
  remainingSubscriptions?: number
  isRegistered: boolean
  canSubscribe: boolean
  canUnsubscribe: boolean
  author: string
  flash: boolean
  cancelled: boolean
  cancellationReason?: string
  views: number
} & (IrlFields | RemoteFields)

interface IrlFields {
  locationType: LocationType.IRL
  address: {
    line1: string
    line2?: string
    postalCode: number
    city: string
  }
}
interface RemoteFields {
  locationType: LocationType.REMOTE
  meetUrl: string
}

export enum PostType {
  NEWS_POST = "NEWS_POST",
  EVENT = "EVENT",
}

import { useResourceListApi } from "@pharmupp/p3-front-commons"
import { useApiOffersSelector } from "../common"
import type { ApiOffer, Offer } from "../types"

export type TimeType = "IN_PROGRESS" | "COMING_SOON"

export const useLabOffersApi = ({
  labId,
  size,
  timeType,
}: { labId: string; size: number; timeType?: TimeType }) => {
  const apiOffersSelector = useApiOffersSelector()
  const {
    list: offers,
    initialLoading,
    paginationProps,
  } = useResourceListApi<ApiOffer, Offer>({
    queryKey: ["laboratory", labId, "offers"],
    resourceEndpoint: "/api/offers",
    rowsPerPage: size,
    defaultColumnSort: timeType === "COMING_SOON" ? "startDate" : "endDate",
    defaultColumnDirection: "asc",
    queryParams: {
      labIds: labId,
      ...(timeType && { dateFilter: timeType }),
    },
    select: apiOffersSelector,
  })

  return {
    offers,
    isLoading: initialLoading,
    paginationProps,
    count: paginationProps.count,
  }
}

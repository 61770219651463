import {
  Box,
  type BoxProps,
  Divider,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material"
import { NumberFormat, PaperContainerSmallTitle } from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import {
  CardLabel,
  type Challenge,
  LoyaltyCategoryDisplay,
  RatingCardGrid,
  RatingCardLabel,
  RatingCardLayout,
  RatingCardQuantity,
  RatingCardValue,
  type SelectedSubCategory,
  TrackingRow,
  useLoyaltyCategoryApi,
} from "./commons"

const category = "QUALITATIVE"

export const QualitativeCategory = () => {
  const { year: yearStr = `${new Date().getFullYear()}`, adherentId } = useParams()
  const year = Number.parseInt(yearStr)
  const { loyaltyCategory } = useLoyaltyCategoryApi({
    categoryEnum: category,
    year,
    adherentId,
  })
  return (
    <LoyaltyCategoryDisplay loyaltyCategory={loyaltyCategory} currentYear={year}>
      {!!loyaltyCategory && (
        <>
          {loyaltyCategory.subCategories?.map((subCategory) => (
            <Box key={subCategory.name}>
              <PaperContainerSmallTitle mb={2}>
                {subCategory.name}
              </PaperContainerSmallTitle>
              {subCategory.hasBonus ? (
                <SubCategoryBonus key={subCategory.name} subCategory={subCategory} />
              ) : (
                <RatingCardGrid>
                  <ChallengesCardsList challenges={subCategory.challenges || []} />
                </RatingCardGrid>
              )}
            </Box>
          ))}

          {/* TRACKING */}
          <TrackingRow
            category={category}
            totalEarnPoints={loyaltyCategory.totalEarnPoints}
            totalExpectedPoints={loyaltyCategory.totalExpectedPoints}
            totalRealized={
              loyaltyCategory.subCategories
                ?.flatMap((sub) => sub?.challenges ?? [])
                .filter(({ unlocked }) => !!unlocked)?.length ?? 0
            }
            lastYearValue={loyaltyCategory.lastYearValue}
          />
        </>
      )}
    </LoyaltyCategoryDisplay>
  )
}

const SubCategoryBonus = ({ subCategory }: { subCategory: SelectedSubCategory }) => {
  const { palette } = useTheme()
  return (
    <RatingCardGrid>
      {/* NON BONUSES */}
      <Stack spacing={2}>
        <ChallengesCardsList challenges={subCategory.nonBonusChallenges} />
      </Stack>

      {/* BONUSES */}
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        justifyContent="center"
        position="relative"
        pl={4}
        width="90%"
      >
        {/* BRACKET DIVIDER */}
        <BracketRight />

        {/* TITLE */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <TripleStar />
          <PaperContainerSmallTitle sx={{ color: palette.loyalty.main }}>
            Bonus
          </PaperContainerSmallTitle>
        </Stack>

        {/* CARDS */}
        <Stack spacing={4}>
          {subCategory.bonusChallenges.map((challenge) => (
            <RatingCardLayout
              key={challenge.formattedLabel}
              active={challenge.unlocked}
              sx={{
                borderStyle: "dashed",
                overflow: "visible",
                position: "relative",
                pr: 12,
              }}
            >
              <RatingCardLabel>
                <RatingCardQuantity active={challenge.unlocked}>
                  {challenge.numberField}
                </RatingCardQuantity>
                <CardLabel label={challenge.formattedLabel} />
              </RatingCardLabel>
              <BadgeBonusPoints
                unlocked={challenge.unlocked}
                bonus={
                  challenge.unlocked && challenge.totalPointsUnlocked
                    ? challenge.totalPointsUnlocked
                    : challenge.expectedPoint
                }
                style={{ position: "absolute", right: "-22px" }}
              />
            </RatingCardLayout>
          ))}
        </Stack>
      </Box>
    </RatingCardGrid>
  )
}

const ChallengesCardsList = ({ challenges }: { challenges: Challenge[] }) => (
  <>
    {challenges.map((challenge) => (
      <QualitativeCard
        key={challenge.formattedLabel}
        quantity={challenge.numberField}
        caption={challenge.caption || undefined}
        label={challenge.formattedLabel}
        value={
          challenge.unlocked && challenge.totalPointsUnlocked
            ? challenge.totalPointsUnlocked
            : challenge.expectedPoint
        }
        unit={` pts${challenge.unitSuffix || ""}`}
        active={challenge.unlocked}
        tooltip={challenge.tooltip}
      />
    ))}
  </>
)

const QualitativeCard = ({
  active = false,
  quantity,
  caption,
  label,
  value,
  unit,
  tooltip,
}: {
  active?: boolean
  quantity?: string | null
  caption?: string | null
  label: string
  value: number
  unit?: string
  tooltip?: string | null
}) => (
  <RatingCardLayout active={active}>
    <RatingCardLabel>
      {/* QUANTITY */}
      {!!quantity && (
        <RatingCardQuantity active={active}>{quantity}</RatingCardQuantity>
      )}

      {/* LABEL */}
      <CardLabelContainer active={active}>
        {!!caption && <small>{caption}</small>}
        <CardLabel label={label} tooltip={tooltip} />
      </CardLabelContainer>
    </RatingCardLabel>
    <RatingCardValue active={active} value={value} unit={unit} />
  </RatingCardLayout>
)

const CardLabelContainer = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== "active"
  },
})<{ active: boolean }>(({ active, theme: { palette, typography } }) => ({
  color: active ? "white" : palette.common.darkBlue,
  "& small": {
    fontSize: typography.pxToRem(11),
  },
  "& p": {
    margin: 0,
    lineHeight: typography.pxToRem(17),
  },
}))

const BracketRight = styled("span")(({ theme: { palette, spacing } }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "-16px",
  border: `1px solid ${palette.loyalty.main}`,
  borderLeft: "0px",
  display: "block",
  width: spacing(2),
  height: "100%",
  "&:after": {
    content: "'▶'",
    display: "block",
    color: palette.loyalty.main,
    width: "5px",
    fontSize: "10px",
    position: "absolute",
    top: "20%",
    right: "-4px",
  },
}))

const TripleStar = () => {
  const { palette } = useTheme()
  return (
    <Box display="flex" alignItems="center">
      {Array.from({ length: 3 }).map((_, index) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          key={index}
          style={{ marginLeft: "-12px" }}
        >
          <path
            fill={palette.common.orange}
            fillRule="nonzero"
            stroke="#FFF"
            strokeWidth=".983"
            d="M20.362 7.31h-3.98a.68.68 0 0 1-.647-.487l-1.23-3.932C14.144 1.74 13.161 1 12 1s-2.145.742-2.504 1.89l-1.23 3.932a.68.68 0 0 1-.647.488h-3.98c-1.163 0-2.147.741-2.505 1.89-.358 1.148.017 2.348.956 3.058l3.221 2.43c.24.181.339.499.247.79l-1.23 3.932c-.36 1.148.016 2.348.956 3.058.47.355 1.009.532 1.548.532.539 0 1.078-.178 1.548-.532l3.22-2.43c.24-.18.561-.18.8 0l3.22 2.43c.94.71 2.155.71 3.096 0 .94-.71 1.315-1.91.956-3.058l-1.23-3.932a.718.718 0 0 1 .247-.79l3.22-2.43c.94-.709 1.316-1.91.957-3.058-.36-1.149-1.341-1.89-2.504-1.89Z"
          />
        </svg>
      ))}
    </Box>
  )
}

const BadgeBonusPoints = ({
  unlocked,
  bonus,
  ...props
}: BoxProps & { unlocked: boolean; bonus: number }) => {
  const { palette, typography } = useTheme()
  return (
    <Box width="94px" height="94px" {...props}>
      <Box
        width="94px"
        height="94px"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* SVG BACKGROUND */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="94"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path
              fill={palette.common.orange}
              d="m71.383 79.144 7.483-14.462 11.456 14.146-9.348 1.148 1.097 8.933z"
            />
            <circle
              cx="46"
              cy="46"
              r="46"
              fill="#FFF"
              stroke={palette.common.orange}
            />
            <circle
              cx="46"
              cy="46"
              r="42"
              fill={unlocked ? "#FDC947" : palette.common.white}
              stroke={alpha(palette.common.orange, 0.505)}
            />
          </g>
        </svg>

        {/* TEXT */}
        <Box
          zIndex={1}
          display="flex"
          flexDirection="column"
          gap={0.25}
          color={unlocked ? "white" : palette.loyalty.dark2}
        >
          <Typography
            fontSize={typography.pxToRem(15)}
            fontWeight={600}
            color="inherit"
          >
            <NumberFormat value={bonus} prefix="+ " suffix=" pts" />
          </Typography>
          {!unlocked && <Divider flexItem sx={{ color: palette.loyalty.dark2 }} />}
        </Box>
      </Box>
    </Box>
  )
}

import { Button, Divider, Stack, Typography } from "@mui/material"
import {
  Modal,
  ModalContent,
  ModalDialogActions,
  ModalFooter,
  type ModalProps,
  ModalTitle,
  Rating,
} from "@pharmupp/p3-front-commons"
import { ChevronRight, Edit3 } from "react-feather"
import { useNavigate } from "react-router-dom"
import { RATING_MAX } from "../rating.commons"
import { useRatingSummaryApi } from "../useRatingSummaryApi"
import { CategoryTitle, GlobalRating, ScoreLine, getScoreLabel } from "./commons"

export function ViewRatingModal({
  onClose,
  labId,
}: Omit<ModalProps, "open"> & { labId: string }) {
  // ROUTING
  const navigate = useNavigate()

  // API
  const { ratingSummary } = useRatingSummaryApi({ labId })

  return (
    <Modal open onClose={onClose} showCross sx={{ minWidth: "720px" }}>
      <ModalTitle>Avis des Adhérents</ModalTitle>

      {ratingSummary.GLOBAL.ratingsCount === 0 && (
        <Typography variant="14px" fontWeight={600} color="common.electricBlue">
          Soyez le premier à donner votre avis 🙂
        </Typography>
      )}

      <ModalContent>
        <Stack spacing={5.5} divider={<Divider />}>
          {/* GLOBAL SCORE */}
          <GlobalRating
            averageValue={ratingSummary.GLOBAL.averageRating}
            ratingsCount={ratingSummary.GLOBAL.ratingsCount}
          />

          {/* SCORE BY CATEGORY */}
          <Stack spacing={3}>
            <CategoryScore
              name="Qualité"
              score={ratingSummary.QUALITY.averageRating}
              max={RATING_MAX}
            />
            <CategoryScore
              name="Conditions commerciales "
              score={ratingSummary.COMMERCIAL_CONDITIONS.averageRating}
              max={RATING_MAX}
            />
            <CategoryScore
              name="Disponibilité produits"
              score={ratingSummary.PRODUCT_AVAILABILITY.averageRating}
              max={RATING_MAX}
            />
            <CategoryScore
              name="Accompagnement"
              score={ratingSummary.SUPPORT.averageRating}
              max={RATING_MAX}
            />
          </Stack>
        </Stack>
      </ModalContent>

      <ModalFooter>
        <ModalDialogActions>
          <Button
            variant="contained"
            startIcon={<Edit3 height={18} />}
            endIcon={<ChevronRight height={18} />}
            size="medium"
            onClick={() => navigate("../noter")}
            sx={{ bgcolor: "primary.light1" }}
          >
            Donner mon avis
          </Button>
        </ModalDialogActions>
      </ModalFooter>
    </Modal>
  )
}

const CategoryScore = ({
  name,
  score,
  max,
}: { name: string; score?: number | null; max: number }) => (
  <ScoreLine>
    <CategoryTitle>{name}</CategoryTitle>
    <Rating
      value={score}
      max={max}
      size="large"
      renderRatingLabel={(value) => (
        <Typography variant="14px" fontWeight={500} color="primary.dark1">
          {getScoreLabel(value)}
        </Typography>
      )}
    />
  </ScoreLine>
)

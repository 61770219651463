import { useClipBoard } from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { getDocSigningLink } from "./utils"

export const useSignatoryDocSigningLink = (
  templateId: string,
  adherentId: string,
) => {
  const clipboard = useClipBoard()
  const [isLoading, setIsLoading] = useState(false)

  return {
    async fetchToClipboard() {
      setIsLoading(true)
      const link = await getDocSigningLink(
        `/api/documents/templates/${templateId}/signatories/${adherentId}/signing-link`,
      )
      setIsLoading(false)
      clipboard.write({ label: "Lien de signature", value: link })
    },
    isLoading,
  }
}

import {
  Box,
  Divider,
  Link,
  Paper,
  type PaperProps,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import type { ReactNode } from "react"
import { Link2, Mail, Phone, Printer } from "react-feather"
import { ClipBoardButton } from "../../inputs"

export const SidebarCard = ({ children, ...props }: PaperProps<typeof Box>) => (
  <Paper {...props} component={Box} p={3}>
    {children}
  </Paper>
)

export const SidebarCardImage = styled("img")({
  width: "100%",
  aspectRatio: "16/9",
  objectFit: "cover",
})

export const SidebarCardLogo = styled("img")({
  maxWidth: "100%",
  height: "90px",
  objectFit: "contain",
})

export const SidebarLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.grey.light,
  fontSize: theme.typography.pxToRem(12),
}))

export const SidebarValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  fontWeight: 500,
}))

export const SidebarBigValue = styled("span")(({ theme }) => ({
  alignSelf: "flex-start",
  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  color: "white",
  backgroundColor: theme.palette.loyalty.main,
  borderRadius: "100px",
}))

export const SidebarCardDetails = ({
  phone,
  email,
  website,
  fax,
  label = "Plus de détails",
  details,
}: {
  phone?: string
  email?: string
  website?: string
  fax?: string
  label?: ReactNode
  details?: ReactNode
}) => (
  <Stack
    component={Box}
    mt={3}
    spacing={2}
    divider={<Divider />}
    fontSize={(theme) => theme.typography.pxToRem(14)}
  >
    {!!(phone || email) && (
      <>
        <SidebarLabel>{label}</SidebarLabel>
        {details}
        {!!phone && (
          <Stack
            direction="row"
            color="primary.main"
            spacing={1}
            alignItems="center"
          >
            <Phone />
            <Typography noWrap fontWeight={600} fontSize="inherit">
              {phone}
            </Typography>
          </Stack>
        )}
        {!!fax && (
          <Stack
            direction="row"
            color="primary.main"
            spacing={1}
            alignItems="center"
          >
            <Printer />
            <Typography noWrap fontWeight={600} fontSize="inherit">
              {fax}
            </Typography>
          </Stack>
        )}
        {!!email && (
          <Stack
            direction="row"
            color="primary.main"
            spacing={1}
            alignItems="center"
          >
            <Mail />
            <Tooltip title={email}>
              <Typography noWrap fontWeight={600} fontSize="inherit">
                {email}
              </Typography>
            </Tooltip>
            <ClipBoardButton label="Email" value={email} />
          </Stack>
        )}
      </>
    )}
    {!!website && (
      <Stack direction="row" color="primary.main" spacing={1} alignItems="center">
        <Link2 />
        <Typography
          noWrap
          component={Link}
          underline="hover"
          fontWeight={600}
          fontSize={(theme) => theme.typography.pxToRem(14)}
          href={website}
        >
          {website}
        </Typography>
      </Stack>
    )}
  </Stack>
)

import { Box, Divider, Typography, styled } from "@mui/material"
import { PaperContainerTitle } from "@pharmupp/p3-front-commons"
import type { PropsWithChildren } from "react"

export const LineBlock = ({
  children,
  divider,
  ...props
}: PropsWithChildren<
  ({ title: string; subtitle?: string } | { mainTitle: string }) & {
    divider?: boolean
  }
>) => (
  <Box
    display="grid"
    gridTemplateColumns="1fr 5fr"
    rowGap={[4, 4, 4, 8, 8]}
    columnGap={2}
  >
    <Box>
      {!!("mainTitle" in props) && (
        <PaperContainerTitle>{props.mainTitle}</PaperContainerTitle>
      )}
      {!!("title" in props) && (
        <>
          {!!props.subtitle && <LineSubTitle mb={2}>{props.subtitle}</LineSubTitle>}
          <LineTitle>{props.title}</LineTitle>
        </>
      )}
    </Box>
    <Box>{children}</Box>
    {!!divider && (
      <>
        <Box />
        <Divider />
      </>
    )}
  </Box>
)

const LineSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  textTransform: "uppercase",
  color: theme.palette.primary.main,
}))

export const LineTitle = styled(LineSubTitle)({
  fontWeight: 600,
})

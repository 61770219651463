import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import {
  Chip,
  PaperContainer,
  type PaperContainerProps,
  Rating,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { Suspense, useMemo } from "react"
import { indexBy } from "remeda"

import { Link, useNavigate } from "react-router-dom"
import { type CatalogName, catalogLabel } from "../../../../../../../model"
import { PartnerChip, RoundedChip } from "../../../../../../common"
import { useAdherentLabDetailsV2 } from "../../../../../useAdherentLabDetails"
import { GreyLabel } from "../commons"
import { RatingModal } from "./RatingModal/RatingModal"
import { RATING_MAX } from "./rating.commons"
import { useRatingSummaryApi } from "./useRatingSummaryApi"

type LabInfosBlockProps = PaperContainerProps & {
  labId: string
}

export const LabInfosBlock = ({ labId, ...props }: LabInfosBlockProps) => (
  <Suspense
    fallback={
      <Skeleton
        {...props}
        component={Box}
        variant="rectangular"
        width="100%"
        height="100%"
        borderRadius="6px"
      />
    }
  >
    <LabInfosBlockInner labId={labId} {...props} />
  </Suspense>
)

const LabInfosBlockInner = ({ labId, ...props }: LabInfosBlockProps) => {
  const navigate = useNavigate()

  // API
  const { laboratory, referential } = useAdherentLabDetailsV2({ labId })
  const { ratingSummary } = useRatingSummaryApi({ labId })

  const referentialCategories = useMemo(
    () => indexBy(referential?.categories, (category) => category.code),
    [referential?.categories],
  )

  return (
    <>
      <PaperContainer {...props}>
        <Stack gap={2.5}>
          {/* LOGO */}
          <LabLogo
            src={laboratory.logo ? getPublicFileUrl(laboratory.logo) : undefined}
          />

          {/* PARTNERSHIP TYPE */}
          {(laboratory.partnershipType || laboratory.ultraConditions) && (
            <Stack direction="row" justifyContent="center" gap={1}>
              {laboratory.partnershipType && (
                <PartnerChip type={laboratory.partnershipType} />
              )}
              {laboratory.ultraConditions && (
                <Tooltip title="Ce laboratoire propose des conditions spécifiques pour les adhérents Ultra">
                  <RoundedChip
                    label="ULTRA"
                    fontColor="#dbe7fd"
                    borderColor="#dbe7fd"
                    finalSize="small"
                  />
                </Tooltip>
              )}
            </Stack>
          )}

          {/* CATEGORIES */}
          <Stack gap={0.5}>
            <Divider />
            <Box display="flex" justifyContent={"center"} gap={1} flexWrap="wrap">
              {laboratory.categories?.length ? (
                laboratory.categories.map((code) => (
                  <Chip
                    key={code}
                    label={referentialCategories[code]?.label}
                    fontColor="primary.main"
                    size="small"
                  />
                ))
              ) : (
                <>-</>
              )}
            </Box>
            <Divider />
          </Stack>

          {/* CATALOGS */}
          {!!laboratory.catalogType && (
            <Stack alignItems="center" spacing={1}>
              <GreyLabel>Canal de distribution</GreyLabel>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                gap={1}
              >
                {(
                  Object.entries(laboratory.catalogType) as [CatalogName, boolean][]
                ).map(([code, enabled]) =>
                  enabled ? (
                    <Chip
                      key={code}
                      label={catalogLabel[code]}
                      variant="outlined"
                      fontColor="primary.main"
                      borderColor="#c9d0d6"
                      size="medium"
                    />
                  ) : null,
                )}
              </Stack>
            </Stack>
          )}

          {/* NEGOTIATION STATUS */}
          <Stack alignItems="center" gap={1}>
            <GreyLabel>Statut négociation</GreyLabel>
            <NegotiationStatus>En cours</NegotiationStatus>
          </Stack>

          {/* GLOBAL SCORE */}
          <>
            <Divider />

            <Stack
              component={Link}
              alignItems="center"
              gap={1}
              to="./notes"
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              <GreyLabel>Note globale</GreyLabel>
              <Stack direction="row" alignItems="center" gap={2}>
                <AverageRatingValue>
                  {ratingSummary.GLOBAL.averageRating}
                </AverageRatingValue>
                <Rating
                  value={ratingSummary.GLOBAL.averageRating}
                  max={RATING_MAX}
                />
                {!!ratingSummary.GLOBAL.ratingsCount && (
                  <RatingsCount>
                    ({ratingSummary.GLOBAL.ratingsCount} avis)
                  </RatingsCount>
                )}
              </Stack>
            </Stack>
          </>
        </Stack>
      </PaperContainer>

      <RatingModal labId={labId} onClose={() => navigate("./")} />
    </>
  )
}

export const LabLogo = styled("img")({
  width: "100%",
  aspectRatio: "16/9",
  objectFit: "contain",
  maxHeight: "80px",
})

const NegotiationStatus = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(18),
    fontWeight: 500,
    color: palette.primary.dark1,
  }),
)

const AverageRatingValue = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    lineHeight: typography.pxToRem(22),
    fontWeight: 500,
    color: palette.primary.dark1,
  }),
)

const RatingsCount = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  fontWeight: 500,
  color: "#91a2c3",
}))

import { Box, Card, Stack, Typography, useTheme } from "@mui/material"
import { groupBy } from "remeda"
import type { IterableElement } from "type-fest"
import {
  type ApiSignatoriesSummary,
  type ApiSignatory,
  ERRORS_UTILS,
} from "../types"

type ErrorNamesUtil = IterableElement<typeof ERRORS_UTILS>

const ErrorsUtilsEnum: Record<ErrorNamesUtil, string> = {
  MISSING_SIRET: "La pharmacie n’a pas de SIRET",
  MISSING_EMAIL: " Le destinataire de la pharmacie n'a pas d'email",
  MISSING_FIRST_NAME: "Le destinataire de la pharmacie n’a pas de prénom",
  MISSING_LAST_NAME: "Le destinataire de la pharmacie n’a pas de nom",
  MISSING_MOBILE_PHONE:
    "Le destinataire de la pharmacie n'a pas de numéro de téléphone",
}

const SPACE = 3

export const DialogInfoErrorTitle = ({
  documentName,
  pharmacy,
}: { documentName: ApiSignatoriesSummary["name"] | undefined } & Pick<
  ApiSignatory,
  "pharmacy"
>) => (
  <Stack gap={SPACE} mb={SPACE}>
    <Stack sx={{ "& > *": { fontSize: "28px", lineHeight: 1.3 } }}>
      <Typography fontWeight={600}>Envoi en erreur</Typography>
      <Typography fontWeight={400}>{documentName}</Typography>
    </Stack>
    <Stack direction="row" gap={0.5}>
      <Typography fontWeight={600}>Destinataire :</Typography>
      <Typography color="primary.dark" fontWeight={600}>
        {pharmacy.name}
      </Typography>
      <Typography color="primary.dark2">{`- ${pharmacy.primaryHolder?.lastName} ${pharmacy.primaryHolder?.firstName}`}</Typography>
    </Stack>
  </Stack>
)

export const DialogInfoErrorMessage = ({ errors }: Pick<ApiSignatory, "errors">) => {
  const errorsByType = groupBy(errors || [], (error) =>
    ERRORS_UTILS.has(error) ? "utils" : "unxpected",
  )

  const title =
    errors && errors?.length > 1
      ? "des erreurs ont été décelées"
      : "une erreur a été décelée"

  return (
    <Box>
      <Typography textAlign="center">
        L’envoi n’a pas pu se faire, {title} :
      </Typography>
      <Stack gap={2} mt={2}>
        {errorsByType.utils?.map((error) => (
          <CardNotification
            key={error}
            txt={ErrorsUtilsEnum[error as ErrorNamesUtil]}
          />
        ))}
        {errorsByType.unxpected && (
          <CardNotification txt="Une erreur non prévue est survenue" isUnexpected />
        )}
      </Stack>
    </Box>
  )
}

const CardNotification = ({
  txt,
  isUnexpected,
}: { txt: string; isUnexpected?: boolean }) => {
  const {
    common: { orange },
    error,
  } = useTheme().palette
  return (
    <Card sx={{ borderRadius: "8px" }}>
      <Stack
        minHeight="3rem"
        width={500}
        justifyContent="center"
        p={3}
        sx={{
          borderLeft: `5px solid ${isUnexpected ? error.main : orange}`,
        }}
      >
        <Typography variant="14px" fontWeight="bold">
          {txt}
        </Typography>
      </Stack>
    </Card>
  )
}

import type { ReferentialCode, UploadedFile } from "@pharmupp/p3-front-commons"

export interface ApiFormPost {
  title: string
  description: string
  active: boolean
  publicationDate?: string
  image?: UploadedFile | null
  rubric: PostRubric
  /** Fetched from referential  */
  category?: ReferentialCode<string> | null
  laboratoryId?: number
  solutionId?: number
  isFlash: boolean
  restrictedUppIds?: number[]
  creatorType: "ADMINISTRATOR" | "PRESIDENT_UPP"
}

export const MAX_DOCUMENT_LINKS = 4

export enum PostRubric {
  UPP_GROUP = "UPP_GROUP",
  LAB_EXTERN = "LAB_EXTERN",
  HEALTH_PHARMA = "HEALTH_PHARMA",
}
export const rubricOptions = [
  { code: PostRubric.UPP_GROUP, label: "Pharm-UPP vous informe" },
  { code: PostRubric.LAB_EXTERN, label: "Actualités Labos & Prestataires" },
  { code: PostRubric.HEALTH_PHARMA, label: "Actualités Santé & Pharma" },
]

export type ApiFormSent = Omit<ApiFormPost, "active" | "creatorType">

export interface ApiFormPostReceived extends Omit<ApiFormPost, "publicationDate"> {
  id: number
  status: string
  dbStatus: string
  publicationDate: string
  views: number
}

import { Box, Card, IconButton, Stack, Typography, styled } from "@mui/material"
import { type ReactNode, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import { File as FileIcon, X } from "react-feather"
import { type FieldPath, type FieldValues, useController } from "react-hook-form"
import { DropZone } from "./DropZone"
import { type ACCEPTED_FORMAT, getFormatProps, getLibErrorMessages } from "./utils"

export type FormDropZoneProps<
  TFieldValues extends FieldValues,
  TFile extends File,
> = {
  name: FieldPath<TFieldValues>
  label: string
  formats: ACCEPTED_FORMAT[]
  maxFilesCount?: number
  /** In Mo */
  maxFileSize?: number
  renderUploadedFiles?: UploadedFilesRenderer<TFile>
  addedFileMapper?: (file: File) => TFile
}

export const FormDropZone = <
  TFieldValues extends FieldValues = FieldValues,
  TFile extends File = File,
>({
  name,
  label,
  formats,
  maxFilesCount = 1,
  maxFileSize = Number.POSITIVE_INFINITY,
  renderUploadedFiles = defaultUploadedFilesRenderer as UploadedFilesRenderer<TFile>,
  addedFileMapper,
}: FormDropZoneProps<TFieldValues, TFile>) => {
  // FORMATS
  const { acceptedExtensions, mimeTypes } = useMemo(
    () => getFormatProps(formats),
    [formats],
  )

  // FORM CONTROL
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<Record<string, TFile[]>>({ name })

  // DROP ZONE
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: mimeTypes,
    multiple: true,
    maxFiles: maxFilesCount,
    maxSize: maxFileSize * 1000000,
    disabled: (field.value || []).length >= maxFilesCount,
    onDrop(addedFiles) {
      const mappedFiles = addedFileMapper
        ? addedFiles.map(addedFileMapper)
        : addedFiles
      field.onChange([...(field.value || []), ...mappedFiles])
    },
    useFsAccessApi: import.meta.env.VITE_MOCKED !== "true",
  })
  const libErrors = getLibErrorMessages(fileRejections)

  return (
    <Stack spacing={3}>
      {/* DROP ZONE */}
      <DropZone
        boxProps={getRootProps()}
        inputProps={getInputProps()}
        label={label}
        focused={isDragActive}
        hasError={!!fileRejections.length}
        acceptedExtensions={acceptedExtensions}
        maxFilesCount={maxFilesCount}
        maxFileSize={maxFileSize}
        error={libErrors.join(",")}
      />

      {/* UPLOADED FILES */}
      {renderUploadedFiles({
        files: field.value as TFile[],
        onRemove: (index) =>
          field.onChange(field.value.filter((_el, i) => i !== index)),
      })}

      {/* ERRORS */}
      <Typography color="error.main">
        {Array.isArray(fieldError) ? fieldError[0].message : fieldError?.message}
      </Typography>
    </Stack>
  )
}

type UploadedFilesRenderer<TFile extends File = File> = (params: {
  files: TFile[]
  onRemove: (index: number) => void
}) => ReactNode

const defaultUploadedFilesRenderer: UploadedFilesRenderer = ({
  files = [],
  onRemove,
}) => (
  <Stack gap={1}>
    {files.map((file, index) => (
      <FileCard key={file.name}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="42px"
          bgcolor="common.blue"
          color="white"
          sx={{ aspectRatio: "1/1" }}
        >
          <FileIcon />
        </Box>
        <Box
          px={2}
          width="100%"
          display="grid"
          gridTemplateColumns="1fr auto"
          alignItems="center"
          gap={2}
        >
          <Typography variant="14px" fontWeight={600} noWrap>
            {file.name}
          </Typography>
          <IconButton
            onClick={() => onRemove(index)}
            type="button"
            size="small"
            edge="end"
          >
            <X size="18px" />
          </IconButton>
        </Box>
      </FileCard>
    ))}
  </Stack>
)

const FileCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  minHeight: "44px",
  display: "flex",
  alignItems: "center",
}))

import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"
import type { Except } from "type-fest"
import { ActionDialog, type ActionDialogProps } from "./ActionDialog/ActionDialog"
import { ConfirmDialog, type ConfirmDialogProps } from "./ConfirmDialog"
import { InfoDialog, type InfoDialogProps } from "./InfoDialog/InfoDialog"
import {
  actionDialogConf,
  activateDialogConf,
  confirmDialogConf,
  deactivateDialogConf,
  defaultInfoDialogConf,
  deleteDialogConf,
} from "./dialogs.conf"

interface DialogContextValue {
  openActionDialog: <T, E>(conf: ActionDialogProps<T, E>) => void
  openConfirmDialog: (conf: ConfirmDialogProps) => void
  openInfoDialog: (conf: InfoDialogProps) => void
}
const DialogContext = createContext<DialogContextValue>(undefined!)

export const DialogProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(dialogsReducer, defaultDialogState)
  const openActionDialog = useCallback(
    (actionConf: ActionDialogProps) =>
      dispatch({ type: "OPEN_ACTION", payload: actionConf }),
    [],
  )
  const openConfirmDialog = useCallback(
    (confirmConf: ConfirmDialogProps) =>
      dispatch({ type: "OPEN_CONFIRM", payload: confirmConf }),
    [],
  )
  const openInfoDialog = useCallback(
    (infoConf: InfoDialogProps) =>
      dispatch({ type: "OPEN_INFO", payload: infoConf }),
    [],
  )

  const closeDialog = useCallback(() => dispatch({ type: "CLOSE" }), [])

  return (
    <DialogContext.Provider
      value={{
        // @ts-ignore
        openActionDialog,
        openConfirmDialog,
        openInfoDialog,
      }}
    >
      {children}
      {state.dialogOpened === "action" && (
        <ActionDialog {...state.dialogConf} open onClickClose={closeDialog} />
      )}
      {state.dialogOpened === "info" && (
        <InfoDialog {...state.dialogConf} open onClickClose={closeDialog} />
      )}
      {state.dialogOpened === "confirm" && (
        <ConfirmDialog {...state.dialogConf} open onClickClose={closeDialog} />
      )}
    </DialogContext.Provider>
  )
}

export type OpenActionDialogProps<T = unknown, E = unknown> = Pick<
  ActionDialogProps<T, E>,
  "onSuccess" | "onError"
> &
  (
    | {
        /** @deprecated use endpoint instead */
        url: string
      }
    | { endpoint: string }
  )

export type OpenGenericActionDialogProps = Omit<
  ActionDialogProps,
  "open" | "onClickClose"
>
export type OpenConfirmDialogProps = Omit<
  ConfirmDialogProps,
  "open" | "onClickClose"
>
export type OpenInfoDialogProps = Except<InfoDialogProps, "open" | "onClickClose">

export const useDialog = () => {
  const ctx = useContext(DialogContext)

  return useMemo(
    () => ({
      confirm: (openProps: OpenConfirmDialogProps) =>
        ctx.openConfirmDialog({ ...confirmDialogConf, ...openProps }),
      action: (openProps: OpenGenericActionDialogProps) =>
        ctx.openActionDialog({ ...actionDialogConf, ...openProps }),
      delete: <T, E>(openProps: OpenActionDialogProps<T, E>) =>
        ctx.openActionDialog({ ...deleteDialogConf, ...openProps }),
      activate: <T,>(openProps: OpenActionDialogProps<T>) =>
        ctx.openActionDialog({ ...activateDialogConf, ...openProps }),
      deactivate: <T,>(openProps: OpenActionDialogProps<T>) =>
        ctx.openActionDialog({ ...deactivateDialogConf, ...openProps }),
      info: (openProps: OpenInfoDialogProps) =>
        ctx.openInfoDialog(Object.assign(defaultInfoDialogConf, openProps)),
    }),
    [ctx],
  )
}

type DialogState =
  | { dialogOpened: "action"; dialogConf: ActionDialogProps }
  | { dialogOpened: "confirm"; dialogConf: ConfirmDialogProps }
  | { dialogOpened: "info"; dialogConf: InfoDialogProps }
  | { dialogOpened: null; dialogConf: null }

const defaultDialogState: DialogState = {
  dialogOpened: null,
  dialogConf: null,
}

type DialogAction =
  | { type: "OPEN_ACTION"; payload: ActionDialogProps }
  | { type: "OPEN_CONFIRM"; payload: ConfirmDialogProps }
  | { type: "OPEN_INFO"; payload: InfoDialogProps }
  | { type: "CLOSE"; payload?: undefined }

function dialogsReducer(_state: DialogState, action: DialogAction): DialogState {
  const { type, payload } = action
  switch (type) {
    case "OPEN_ACTION":
      return { dialogOpened: "action", dialogConf: payload }
    case "OPEN_INFO":
      return { dialogOpened: "info", dialogConf: payload }
    case "OPEN_CONFIRM":
      return { dialogOpened: "confirm", dialogConf: payload }
    case "CLOSE":
      return defaultDialogState
  }
}

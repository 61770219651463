import type { SummaryModel } from "../types"
import type { useAdherentOrdersReferential } from "../useAdherentOrdersReferential"
import { OrderTable } from "./commons"
import { columns } from "./commons/columns"

type NonValidatedOrdersProps = {
  summary: SummaryModel
} & ReturnType<typeof useAdherentOrdersReferential>

export const NonValidatedOrders = (referentialProps: NonValidatedOrdersProps) => (
  <OrderTable
    queryConfig={{
      queryKey: ["offers", "my-orders", "non-validated"],
      resourceEndpoint: "/api/offers/my-orders/non-validated",
    }}
    emptyMessage="Vous n'avez aucune commande en cours"
    alertText="Les bons de commandes ci-dessous restent modifiables jusqu’à validation de votre commande"
    columns={[
      { renderDefinition: columns.dateAndNumber, width: "15%" },
      { renderDefinition: columns.laboratory, width: "15%" },
      { renderDefinition: columns.offer, width: "45%" },
      { renderDefinition: columns.total, width: "10%" },
      { renderDefinition: columns.action, width: "15%" },
    ]}
    {...referentialProps}
  />
)

import { Box, type BoxProps } from "@mui/material"
import { SelectedOption } from "./SelectedOption"
import { useSelectedOptions } from "./SelectedOptionsContext"

export const SelectedOptions = (props: BoxProps) => {
  const { options, deleteOption } = useSelectedOptions()

  if (options.length === 0) return null

  return (
    <Box display="flex" flexWrap="wrap" gap={1} {...props}>
      {options.map((option) => (
        <SelectedOption
          key={option.fieldName + option.value}
          label={option.label}
          onDelete={() => deleteOption(option)}
        />
      ))}
    </Box>
  )
}
